import { SharedModule } from './shared/shared.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { HtmlTextEditorComponent } from './Editors/html-text-editor';
import { AllRolessModifyDialog } from './user-management/all-roles/all-roles-modify-dialog';
import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as Hammer from 'hammerjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthService } from './auth.service';

// Angular Matereal Component's

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ErrorMessageDialog } from './messages/error-message-dialog';
import { ConfirmMessageDialog } from './messages/confirm-message-dialog';
import { NotificationMessageDialog } from './messages/notification-message-dialog';
import { ImageViewMessageDialog } from './messages/image-view-message-dialog';
import { FullCalanderViewComponent } from './full-calander-view/full-calander-view.component';
import { SelectOutletComponent } from './select-outlet/select-outlet.component';
import { AllUsersModifyDialog } from './user-management/all-users/all-users-modify-dialog';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { ImageUploadDialogComponent } from './image-upload/image-upload-dialog';

import { ImageCropperModule } from 'ngx-image-cropper';
import { FileUploadDialogComponent } from './file-upload/file-upload-dialog';
import { UpdatenMessageDialog } from './messages/update-message-dialog';
import { ImageGridSection } from './file-upload/edit-sections/ImageGridSection';
import { imageDescSection } from './file-upload/edit-sections/imageDescSection';
import { ExportComponent } from './export/export.component';
import { PrintViewComponent } from './print-view/print-view.component';
import { SalesManagementSelectCustomerComponent } from './sales-management-select-customer/sales-management-select-customer.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { PrintViewDayEndComponent } from './print-view-day-end/print-view-day-end.component';
import { PrintViewInvoiceComponent } from './print-view-invoice/print-view-invoice.component';
import { NgxBarcodeComponent } from './ngx-barcode/ngx-barcode.component';
import { PrintViewMembersComponent } from './print-view-members/print-view-members.component';
import { PrintViewPaymentVoucherPettyCashComponent } from './print-view-payment-voucher-petty-cash/print-view-payment-voucher-petty-cash.component';
import { PrintViewPaymentVoucherComponent } from './print-view-payment-voucher/print-view-payment-voucher.component';
import { BrandListComponent } from './main/product-entey/brand-list/brand-list.component';
import { EnterOnBlurDirective } from './enter-on-blur.directive';
import { PrintViewReceiptComponent } from './print-view-receipt/print-view-receipt.component';
import { PrintViewServiceCreditorComponent } from './print-view-service-creditor/print-view-service-creditor.component';
import { LoyaltyCustomerImportComponent } from './customer-list/loyalty-customer-import/loyalty-customer-import.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrintViewInvoiceSmallComponent } from './print-view-invoice-small/print-view-invoice-small.component';
import { PrintViewPaymentVoucherBillComponent } from './print-view-payment-voucher-bill/print-view-payment-voucher-bill.component';
import { PrintViewPaymentVoucherBillsComponent } from './print-view-payment-voucher-bills/print-view-payment-voucher-bills.component';
import { PaymentVoucheBillDialogComponent } from './payment-vouche-bill-dialog/payment-vouche-bill-dialog.component';
import { PrintViewDispatchPrintComponent } from './print-view-dispatch-print/print-view-dispatch-print.component';
import { PrintViewDispatchPrint100x150S2Component } from './print-view-dispatch-print100x150-s2/print-view-dispatch-print100x150-s2.component';
import { PrintViewSupplierReturnItemWiseDialogComponent } from './print-view-supplier-return-item-wise-dialog/print-view-supplier-return-item-wise-dialog.component';
import { PrintViewSalesOrderComponent } from './print-view-sales-order/print-view-sales-order.component';
import { PrintViewBarcodePrintComponent } from './print-view-barcode-print/print-view-barcode-print.component';
import { SalesReportsSalesOrdersSummeryDialogComponent } from './sales-reports-sales-orders-summery-dialog/sales-reports-sales-orders-summery-dialog.component';
import { PrintViewShippedOrdersBarcodeComponent } from './print-view-shipped-orders-barcode/print-view-shipped-orders-barcode.component';

const base = () => {
  const c = localStorage.company;
  if (c === '127') {
    return "http://127.0.0.1:3032/"
  } else if (c === 'chenaradodge') {
    return '/';
  } else {
    return 'https://bsa.greencloudpos.com/';
  }
}

export class MyHammerConfig extends HammerGestureConfig {

  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };

  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'auto',
      inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
      recognizers: [
        [Hammer.Swipe, {
          direction: Hammer.DIRECTION_HORIZONTAL
        }]
      ]
    });
    return mc;
  }

}



const config: SocketIoConfig = { url: base(), options: {} };

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    LoginComponent,
    PageNotFoundComponent,
    ErrorMessageDialog,
    ConfirmMessageDialog,
    ImageViewMessageDialog,
    NotificationMessageDialog,
    UpdatenMessageDialog,
    FullCalanderViewComponent,
    SelectOutletComponent,
    AllUsersModifyDialog,
    AllRolessModifyDialog,
    ImageUploadDialogComponent,
    FileUploadDialogComponent,
    HtmlTextEditorComponent,
    ActivateAccountComponent,
    ImageGridSection,
    imageDescSection,
    ExportComponent,
    PrintViewComponent,
    SalesManagementSelectCustomerComponent,
    PrintViewDayEndComponent,
    PrintViewInvoiceComponent,
    NgxBarcodeComponent,
    PrintViewMembersComponent,
    PrintViewPaymentVoucherPettyCashComponent,
    PrintViewPaymentVoucherComponent,
    BrandListComponent,
    EnterOnBlurDirective,
    PrintViewReceiptComponent,
    PrintViewServiceCreditorComponent,
    LoyaltyCustomerImportComponent,
    DashboardComponent,
    PrintViewInvoiceSmallComponent,
    PrintViewPaymentVoucherBillComponent,
    PrintViewPaymentVoucherBillsComponent,
    PaymentVoucheBillDialogComponent,
    PrintViewDispatchPrintComponent,
    PrintViewDispatchPrint100x150S2Component,
    PrintViewSupplierReturnItemWiseDialogComponent,
    PrintViewSalesOrderComponent,
    PrintViewBarcodePrintComponent,
    SalesReportsSalesOrdersSummeryDialogComponent,
    PrintViewShippedOrdersBarcodeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    FlexLayoutModule,
    HammerModule,
    // import Materal Compononets
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTableModule,
    ImageCropperModule,
    CKEditorModule,
    SocketIoModule.forRoot(config),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  entryComponents: [
    ErrorMessageDialog,
    ConfirmMessageDialog,
    NotificationMessageDialog,
    UpdatenMessageDialog,
    ImageViewMessageDialog,
    SelectOutletComponent,
    AllUsersModifyDialog,
    AllRolessModifyDialog,
    ImageUploadDialogComponent,
    FileUploadDialogComponent,
    HtmlTextEditorComponent,
    ImageGridSection,
    imageDescSection,
    ExportComponent,
    PrintViewComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    { provide: HTTP_INTERCEPTORS, useClass: AuthService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
