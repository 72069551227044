import { GetServerDataServiceService } from './../../get-server-data-service.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder } from '@angular/forms';

import { Observable } from 'rxjs';

@Component({
  templateUrl: 'all-users-modify-dialog.html',
  styles: [
    '.mat-card { box-shadow: none; }'
  ]
})
export class AllUsersModifyDialog {
  constructor(
    public dialogRef: MatDialogRef<AllUsersModifyDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private server: GetServerDataServiceService,
    private toolTip: MatSnackBar) { }


  userUpdateForm = this.fb.group({
    usertype: [null, Validators.compose([Validators.required])],
    username: [null, Validators.required],
    password: [null, Validators.required],
    passwordRepeate: [null, Validators.required],
    email: [null, Validators.email],
  });

  types = this.server.permissions();

  poNoFilter: Observable<string[]>;
  poNoLoading = false;
  inserting = false;

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.userUpdateForm.get('usertype').setValue(this.data.PID);
    this.userUpdateForm.get('username').setValue(this.data['User ID']);
    this.userUpdateForm.get('password').setValue('');
    if (this.data.Email === 'N/A') {
      this.userUpdateForm.get('email').setValue('');
    } else {
      this.userUpdateForm.get('email').setValue(this.data.Email);
    }

  }

  checkPasswords() {
    return this.userUpdateForm.get('password').value === this.userUpdateForm.get('passwordRepeate').value;
  }

  update(): void {
    this.inserting = true;
    this.server.update_user(this.userUpdateForm.value, this.data.UID, this.userUpdateForm.get('usertype').value).subscribe(res => {
      this.inserting = false;

      if (res.error) {
        this.toolTip.open(this.userUpdateForm.get('username').value + ' already exists!', 'Ok', { duration: 3000 });
      } else {
        this.toolTip.open(this.data['User ID'] + ' Updated!', 'Ok', { duration: 3000 });
        this.dialogRef.close();
      }
    });
  }

}
