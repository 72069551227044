import { Component, OnInit, ElementRef, Input } from '@angular/core';
import {
  IChartistAnimationOptions
} from 'chartist';
import { ChartEvent } from 'ng-chartist';
import { WindowEmitorsService } from 'src/app/window-emitors.service';
import { GetServerDataServiceService } from 'src/app/get-server-data-service.service';
@Component({
  selector: 'app-charist-dount-card',
  templateUrl: './charist-dount-card.component.html',
  styleUrls: ['./charist-dount-card.component.css']
})
export class CharistDountCardComponent implements OnInit {

  constructor(
    private el: ElementRef,
    private windowEmitors: WindowEmitorsService,
    private server: GetServerDataServiceService
  ) {
    windowEmitors.sideBarToggled$.subscribe((e: number) => {
      this.setDefaultOptions(100);
      setTimeout(() => {
        this.setDefaultOptions(this.el.nativeElement.offsetWidth);
      }, 100);
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setDefaultOptions(this.el.nativeElement.offsetWidth);
    }, 100);
    this.loadData();
  }

  @Input() icon: string;
  @Input() iconColor: string;
  @Input() color: string;
  @Input() heading: string;
  @Input() subHeading: string;
  @Input() serverURL1: string;
  @Input() serverURL2: string;

  number1 = 0;
  number2 = 0;

  selected = "Today";

  type = 'Pie';
  data = {
    series: [this.number2, this.number1],
  };

  options = {
    donut: true,
    donutWidth: 4,
    donutSolid: true,
    showLabel: true
  };

  changeSelected(s: string) {
    this.selected = s;
    this.loadData();
  }

  setDefaultOptions(width: number) {
    this.options = {
      donut: true,
      donutWidth: 4,
      donutSolid: true,
      showLabel: false
    };
  }

  loadData() {
    this.server[this.serverURL1](this.selected).subscribe((num1: any) => {
      this.server[this.serverURL2](this.selected).subscribe((num2: any) => {
        if (num1.length > 0 && num2.length > 0) {
          this.number1 = num1[0].value;
          this.number2 = num2[0].value;
          this.data = {
            series: [this.number2, this.number1-this.number2 ],
          };
        }
      });
    });
  }

  events: ChartEvent = {
    draw: (data) => {
      if (data.type === 'slice' && data.index === 0) {
        data.element.attr({
          style: 'fill:' + this.color
        });
      }
    }
  };

}
