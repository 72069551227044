import { Component, OnInit, Inject, LOCALE_ID } from "@angular/core";
import { GetServerDataServiceService } from "../get-server-data-service.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-export",
  templateUrl: "./export.component.html",
  styleUrls: ["./export.component.css"]
})
export class ExportComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(LOCALE_ID) private locale: string,
    private server: GetServerDataServiceService,
    private toolTip: MatSnackBar
  ) {}

  ExportType = "xls";
  range = "selected";
  query = this.data.query;

  save(): void {
    console.log(this.query);

    this.server.export_to_file(this.data, this.data.path).subscribe(res => {
      this.downLoadFile(res, "application/ms-excel");
    });
  }

  setType(): void {
    this.data.ExportType = this.ExportType;
  }

  setLimit(): void {
    if (this.range === "all") {
      this.data.query.Originalpage = this.data.query.page;
      this.data.query.Originallimit = this.data.query.limit;
      this.data.query.page = 1;
      this.data.query.limit = this.data.query.length;
    }

    if (this.range === "selected") {
      if (this.data.query.Originalpage) {
        this.data.query.page = this.data.query.Originalpage;
      }

      if (this.data.query.Originallimit) {
        this.data.query.limit = this.data.query.Originallimit;
      }
    }
  }

  downLoadFile(data: any, type: string) {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    if (this.data.filePrifix) {
      anchor.download =
        this.data.filePrifix + " " + new Date().toLocaleDateString() + ".xlsx";
      anchor.href = url;
      anchor.click();
    } else {
      anchor.download =
        this.data.path + " " + new Date().toLocaleDateString() + ".xlsx";
      anchor.href = url;
      anchor.click();
    }
  }

  ngOnInit() {
    console.log(this.data);
  }
}
