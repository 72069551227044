import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowEmitorsService {

  public toggleTopMenu$: EventEmitter<any>;
  public toggleSideMenu$: EventEmitter<any>;
  public invoiceTable$: EventEmitter<any>;
  public toggleCashInput$: EventEmitter<any>;
  public toggleCashInputFull$: EventEmitter<any>;
  public reloadInvoiceData$: EventEmitter<any>;
  public newInvoice$: EventEmitter<any>;
  public saveInvoice$: EventEmitter<any>;
  public saveInvoiceSaving$: EventEmitter<any>;
  public cashInputSetSize$: EventEmitter<any>;
  public saveSupplier$: EventEmitter<any>;
  public saveCustomer$: EventEmitter<any>;
  public sideBarToggled$: EventEmitter<any>;
  public setnDatataToReturnNoteView$: EventEmitter<any>;
  public clearReturn$: EventEmitter<any>;
  public selectInvoiceProduct$: EventEmitter<any>;
  public selectInvoiceProductIndex$: EventEmitter<any>;
  constructor() {
    this.toggleTopMenu$ = new EventEmitter();
    this.toggleSideMenu$ = new EventEmitter();
    this.invoiceTable$ = new EventEmitter();
    this.toggleCashInput$ = new EventEmitter();
    this.toggleCashInputFull$ = new EventEmitter();
    this.reloadInvoiceData$ = new EventEmitter();
    this.newInvoice$ = new EventEmitter();
    this.saveInvoice$ = new EventEmitter();
    this.saveInvoiceSaving$ = new EventEmitter();
    this.cashInputSetSize$ = new EventEmitter();
    this.saveSupplier$ = new EventEmitter();
    this.saveCustomer$ = new EventEmitter();
    this.sideBarToggled$ = new EventEmitter();
    this.setnDatataToReturnNoteView$ = new EventEmitter();
    this.clearReturn$ = new EventEmitter();
    this.selectInvoiceProduct$ = new EventEmitter();
    this.selectInvoiceProductIndex$ = new EventEmitter();
  }

  toggleMenu(val: any): void {
    this.toggleTopMenu$.emit(val);
  }

  toggleSideMenu(val: any): void {
    this.toggleSideMenu$.emit(val);
  }

  emitInvoiceTable(val: any) {
    this.invoiceTable$.emit(val);
  }

  toggleCashInput(val: any) {
    this.toggleCashInput$.emit(val);
  }

  cashInputSetSize(val: any) {
    this.cashInputSetSize$.emit(val);
  }

  toggleCashInputFull(val: any) {
    this.toggleCashInputFull$.emit(val);
  }

  reloadInvoiceData(val: any) {
    this.reloadInvoiceData$.emit(val);
  }

  newInvoice(val: any) {
    this.newInvoice$.emit(val);
  }

  saveInvoice(val: any) {
    this.saveInvoice$.emit(val);
  }

  saveInvoiceSaving(val: any) {
    this.saveInvoiceSaving$.emit(val);
  }

  saveSupplier(val: any) {
    this.saveSupplier$.emit(val);
  }

  saveCustomer(val: any) {
    this.saveCustomer$.emit(val);
  }

  sideBarToggled(val: any) {
    this.sideBarToggled$.emit(val);
  }

  setnDatataToReturnNoteView(data: any) {
    this.setnDatataToReturnNoteView$.emit(data);
  }

  clearReturn(data: any) {
    this.clearReturn$.emit(data);
  }

  selectInvoiceProduct(data:any){
    this.selectInvoiceProduct$.emit(data);
  }

  selectInvoiceProductIndex(data:any){
    this.selectInvoiceProductIndex$.emit(data);
  }
}
