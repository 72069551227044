<form [formGroup]="userUpdateForm" autocomplete="off" fxFlex>
	<mat-card class="shipping-card">
		<mat-card-content>
			<div fxLayout="row">
				<mat-form-field fxFlex>
					<mat-select formControlName="usertype" placeholder="User Type">
						<mat-option *ngFor="let type of types | async" [value]="type.id">
							{{type.Role}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div fxLayout="row">
				<mat-form-field fxFlex>
					<input matInput formControlName="username" placeholder="User Name" type="text">
				</mat-form-field>
			</div>
			<div fxLayout="row">
				<mat-form-field fxFlex>
					<input matInput formControlName="password" placeholder="New Password" type="password">
				</mat-form-field>
			</div>
			<div fxLayout="row">
				<mat-form-field fxFlex>
					<input matInput formControlName="passwordRepeate" placeholder="Repeate Password" type="password">
				</mat-form-field>
			</div>
			<div fxLayout="row">
				<mat-form-field fxFlex>
					<input matInput formControlName="email" placeholder="Email Address" type="email">
				</mat-form-field>
			</div>
		</mat-card-content>
		<mat-card-actions>
			<button mat-raised-button color="primary" (click)="update()"
				[disabled]="!userUpdateForm.valid || !checkPasswords()" fxFlex>Save</button>
		</mat-card-actions>

		<mat-progress-bar mode="indeterminate" *ngIf="inserting"></mat-progress-bar>
	</mat-card>
</form>