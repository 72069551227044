import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { GetServerDataServiceService } from '../get-server-data-service.service';

@Component({
  selector: 'app-print-view-invoice-small',
  templateUrl: './print-view-invoice-small.component.html',
  styleUrls: ['./print-view-invoice-small.component.css'],
  providers: [CurrencyPipe, DatePipe]
})
export class PrintViewInvoiceSmallComponent implements OnInit {

  constructor(
    private router: Router,
    private currency: CurrencyPipe,
    private dateTrans: DatePipe,
    private server: GetServerDataServiceService
  ) { }

  title = '';
  returnURL = '/';
  stockHeader: any;
  grnHeader: any;
  tables = [];
  base = "";
  company = "";
  company_address = "";
  company_tel = "";
  company_fax = "";
  company_email = "";
  company_br_no = "";
  discount_or = false;
  developed_by = "";
  powered_by = "";
  discount_footer = "";
  warn_footer = "";
  thank_footer = "";

  footer: { [key: string]: string } = {
    'Created By': localStorage.username,
    'Printed On': this.dateTrans.transform(new Date())
  };

  ngOnInit() {
    this.setValues();
    this.base = this.server.base();
    this.server.get_print_basics().subscribe(res => {
      this.company = res.find((e: any) => e.type === 'company name').value;
      this.company_address = res.find((e: any) => e.type === 'company address').value;
      this.company_tel = res.find((e: any) => e.type === 'company tel').value;
      this.company_fax = res.find((e: any) => e.type === 'company fax').value;
      this.company_email = res.find((e: any) => e.type === 'company email').value;
      this.company_br_no = res.find((e: any) => e.type === 'company br no').value;
      this.developed_by = res.find((e: any) => e.type === 'developed by').value;
      this.powered_by = res.find((e: any) => e.type === 'powered by').value;
      this.discount_footer = res.find((e: any) => e.type === 'discount footer').value.replace('$', this.tables[0].table[0]['Total Discount(Value)']);
      this.warn_footer = res.find((e: any) => e.type === 'warn footer').value;
      this.thank_footer = res.find((e: any) => e.type === 'thank footer').value;

    });
    this.server.getSetting('invoice-print-discount-total').subscribe(res => {
      console.log(res)
      if (res.length !== 0) {
        this.discount_or = res[0].value;
      }
    })
  }

  setOptionalSettings() {
    this.server.getSetting('invoice-show-outstanding').subscribe(res => {
      if (res.length !== 0) {
        res[0].value;
      }
    });
  }

  goBack() {
    this.router.navigateByUrl(this.returnURL);
  }

  checkStatus(status: string) {
    if (status) {
      return status.toLocaleLowerCase() === 'canceled';
    } else {
      return false;
    }
  }

  colClass(col: string) {
    return 'narrow ' + col.replace(/ /g, '');
  }

  format(col: any, val: any) {
    if (col === 'sp' || col === 'amount' || col === 'Transfer Amount'
      || col === 'Cost Price' || col === 'Sales Amount' || col === 'Cost Amount') {
      return this.currency.transform(val, ' ');
    } else {
      return val;
    } 2
  }

  setValues() {
    if (localStorage.printView) {
      const printView = JSON.parse(localStorage.printView);
      this.title = printView.title;
      this.returnURL = printView.returnURL;
      this.stockHeader = printView.stockHeader;
      this.grnHeader = printView.grnHeader;
      this.tables = printView.tables.map((e: any, i: any) => {
        e.disply = true;
        return e;
      });
      if (printView.Category) {
        this.footer.Category = printView.Category.value;
      } else {
        this.footer.Category = '';
      }

    }
  }

  toggle(): void {
    this.tables = this.tables.map(e => {
      e.disply = !e.disply;
      return e;
    });
  }

  print(): void {
    window.print();
  }

  ngAfterViewInit(): void {
    // window.print();
    // window.onafterprint = () => {
    //   window.close();
    // }
  }

}
