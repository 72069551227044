<div class="bg">
	<div class="page">
		<div class="content" *ngFor="let table of tables">

			<div class="header" fxLayout="row">
				<div fxFlex>
					<img [src]="base + 'image/logo_sq.jpg' | authImage | async" style="width: 10em;">
				</div>
				<div fxFlex>
					<h1 style="text-align: center;">Dispatch Note</h1>
				</div>
				<div fxFlex>
					<div fxLayout="column" fxLayoutAlign="start end">
						<ngx-barcode style="float: right;" [bc-value]="table.table[0]['Bill No']" [bc-display-value]="false">
						</ngx-barcode>
						<div fxLayout="column" style="width: 100%;" fxLayoutAlign="start start">
							<p><span>From </span> : {{company || '-'}}</p>
							<p><span>Contact </span> : {{company_tel}}</p>
						</div>
					</div>
				</div>
			</div>

			<br>

			<div class="table">

				<table style="width:100%">
					<tr>
						<td>
							<div fxLayout="row" fxLayoutAlign="start start">
								<p style="width: 3em; line-height: 1;margin: 0;">To : </p>
								<div style="line-height: 1;margin: 0;" fxLayout="column">
									<p style="line-height: 1;margin: 0;">{{table.table[0]['Customer']}}</p>
									<p style="line-height: 1;margin: 0;">{{table.table[0]['Deliver to']}}</p>
									<p style="line-height: 1;margin: 0;">{{table.table[0]['CONTACT_NO']}}</p>
								</div>
							</div>
						</td>
						<td>
							<p><strong>Courier </strong> : {{table.table[0]['Courier']}}</p>
							<p><strong>Dispatch No </strong> : {{table.table[0]['Dispatch No']}}</p>
							<p><strong>Dispatch Date </strong> : {{table.table[0]['DISPATCH_DATE']}}</p>
							<p><strong>Tracking No </strong> : {{table.table[0]['Tracking No']}}</p>
						</td>
					</tr>
					<tr *ngFor="let col of table.table">
						<td class="n_border">{{col['Product Description']}} ({{col['Qty']}})</td>
						<td class="number n_border_2"> <span class="eq">=</span> {{col['Amount']}}</td>
					</tr>
					<tr class="total_amount">
						<td class="n_border_f">
						</td>
						<td class="number n_border_2_f">
							<p style="text-align: right;"> <span class="eq">TOTAL Amount =</span> {{total_amount | currency : ' '}}
							</p>
							<p style="text-align: right;"> <span class="eq">DELIVERY CHARGE =</span>
								{{table.table[0]['Delivery Charge']}}</p>
							<h2 style="text-align: right;"> <span class="eq">COD AMOUNT = </span> {{table.table[0]['Bill Amount']}}
							</h2>
						</td>
					</tr>
				</table>

			</div>


		</div>
	</div>

	<button mat-fab class="fabPrint" color="warn" (click)="print()">
		<mat-icon>print</mat-icon>
	</button>

</div>