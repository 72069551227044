<mat-form-field fxFlex class="description">
  <input (keydown.enter)="enterPressed()" type="text" [placeholder]="placeholder" aria-label="Number" matInput
    [matAutocomplete]="auto" [(ngModel)]="AutoValue" (ngModelChange)="this.autoUpdate.next($event)" #autoInput>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="valueChanged()" [displayWith]="displayFn">
    <mat-option *ngFor="let option of options | async" [value]="option">
      <p> ({{option.code}}){{option.description}}</p>
      <div fxLayout="row" style="height: 1em;">
        <p style="margin: 0;" fxFlex class="blue"><small>Marked Price: {{option.sp | currency : ' '}}</small>
        </p>
        <p style="margin: 0;" fxFlex class="green" fxShow fxHide.xs><small>Retail Price :
            {{option.sp | currency : ' '}}</small>
        </p>
        <p style="margin: 0;" fxFlex class="ash"><small>Available Quantity: {{option.sqty}}</small></p>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>