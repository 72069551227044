import { Component, OnInit, Inject } from '@angular/core';
import { GetGeoLocationService } from 'src/app/get-geo-location.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM'
import * as olProj from 'ol/proj';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.css']
})
export class SelectLocationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SelectLocationComponent>,
    private geoLocation: GetGeoLocationService,
    private toolTip: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.testMap();
  }

  ngAfterViewInit(): void {
    this.getCurrLocation();
  }

  setMapPosition(lon: number, lat: number) {
    var view = this.map.getView();

    function flyTo(location, done) {
      var duration = 2000;
      var parts = 2;
      var called = false;
      function callback(complete) {
        --parts;
        if (called) {
          return;
        }
        if (parts === 0 || !complete) {
          called = true;
          done(complete);
        }
      }
      view.animate(
        {
          center: location,
          duration: duration,
        },
        callback
      );
    }

    flyTo(olProj.fromLonLat([lon, lat]), function () { });

    this.addMarker(lon, lat);

  }

  locations = [];
  erroList = [];

  map: Map;

  addMarker(lon: number, lat: number) {
    var element = document.getElementById('marker');

    var marker = new Overlay({
      element: element
    });

    this.map.addOverlay(marker);
    marker.setPosition(olProj.fromLonLat([lon, lat]));

  }

  testMap() {
    this.map = new Map({
      view: new View({
        center: olProj.fromLonLat([0, 0]),
        zoom: 20,
      }),
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],
      target: 'map'
    });

    this.map.on('singleclick', (evt: any) => {
      const pos = olProj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
      this.data.location = pos[0] + "," + pos[1];
      this.addMarker(pos[0], pos[1]);
    });

    this.setMapPosition(0, 0);
  }

  getCurrLocation() {
    console.log(this.data.location);
    if (this.data.location && this.data.location !== '0,0' && this.data.location.split(',').length === 2) {
      this.setMapPosition(this.data.location.split(',')[0], this.data.location.split(',')[1]);
    } else {
      this.geoLocation.getPosition().then(pos => {
        console.log(pos);
        this.setMapPosition(pos.coords.longitude, pos.coords.latitude);
      }).catch(e => {
        console.log(e.message);
        this.toolTip.open(e.message, 'Ok', { duration: 3000 });
      });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
