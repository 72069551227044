<div class="page">
	<div>
		<div class="dragContainer" cdkDropList cdkDropListOrientation="vertical" (cdkDropListDropped)="drop($event)"
			ngClass.sm="tab" ngClass.xs="mobile">
			<mat-card *ngFor="let brand of brandList" cdkDrag>
				<div class="example-custom-placeholder" *cdkDragPlaceholder></div>
				<div fxLayout="row" class="cat" fxLayoutGap="1em">
					<div style="width: 3em">
						<mat-icon cdkDragHandle class="move1">apps</mat-icon>
					</div>
					<p><strong>{{brand.name}}</strong></p>
					<p>{{ brand.remarks }}</p>
					<div fxFlex></div>
					<img (click)="uploadImage(brand.name,$event,'brands')" class="wide_img sbuImage" [src]="brand.brand_img | authImage | async"
						[alt]="brand.name + ' Wide'">
					<mat-slide-toggle [(ngModel)]="brand.trends" (change)="updateCatogoryTrends(brand)">Trends</mat-slide-toggle>
					<button mat-icon-button class="edit_icons" color="primary" (click)="edit(brand)" aria-label="Edit Item">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button class="edit_icons" color="warn" (click)="delete(brand)" aria-label="Delete Item">
						<mat-icon>delete</mat-icon>
					</button>
				</div>

			</mat-card>
		</div>

		<button mat-fab class="fab" (click)="addNewbrand()">
			<mat-icon>add</mat-icon>
		</button>
		<button mat-fab class="fabSave" color="warn" (click)="save()">
			<mat-icon>save</mat-icon>
		</button>
	</div>