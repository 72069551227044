import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { GetServerDataServiceService } from '../get-server-data-service.service';

@Component({
  selector: 'app-print-view-invoice',
  templateUrl: './print-view-invoice.component.html',
  styleUrls: ['./print-view-invoice.component.css'],
  providers: [CurrencyPipe, DatePipe]
})
export class PrintViewInvoiceComponent implements OnInit {

  constructor(
    private router: Router,
    private currency: CurrencyPipe,
    private dateTrans: DatePipe,
    private server: GetServerDataServiceService
  ) { }

  title = '';
  returnURL = '/';
  stockHeader: any;
  grnHeader: any;
  tables = [];
  base = "";

  footer: { [key: string]: string } = {
    'Created By': localStorage.username,
    'Printed On': this.dateTrans.transform(new Date())
  };

  ngOnInit() {
    this.setValues();
    this.base = this.server.base();
  }

  goBack() {
    this.router.navigateByUrl(this.returnURL);
  }

  checkStatus(status: string) {
    if (status) {
      return status.toLocaleLowerCase() === 'canceled';
    } else {
      return false;
    }
  }

  colClass(col: string) {
    if (col === '#') {
      return '_id'
    } else {
      return col.replace(/ /g, '');
    }
  }

  format(col: any, val: any) {
    if (col === 'sp' || col === 'amount' || col === 'Transfer Amount'
      || col === 'Cost Price' || col === 'Sales Amount' || col === 'Cost Amount') {
      return this.currency.transform(val, ' ');
    } else {
      return val;
    } 2
  }

  reduceArray(obj: any, key: string) {
    if (obj.length > 0) {
      const r = obj.reduce((a: object, b: object) => {
        return { [key]: this.toNumber(a[key]) + this.toNumber(b[key]) }
      });
      return r[key];
    } else {
      return 0;
    }
  }

  toNumber(num: any) {
    if(typeof num === 'number'){
      return num;
    }else {
      return Number(num.replace(/,/g, ''));
    }
  }

  setValues() {
    if (localStorage.printView) {
      const printView = JSON.parse(localStorage.printView);
      this.title = printView.title;
      this.returnURL = printView.returnURL;
      this.stockHeader = printView.stockHeader;
      this.grnHeader = printView.grnHeader;
      this.tables = printView.tables.map((e: any, i: any) => {
        e.disply = true;
        return e;
      });
      if (printView.Category) {
        this.footer.Category = printView.Category.value;
      } else {
        this.footer.Category = '';
      }

    }
  }

  toggle(): void {
    this.tables = this.tables.map(e => {
      e.disply = !e.disply;
      return e;
    });
  }

  print(): void {
    window.print();
  }

  ngAfterViewInit(): void {
    // window.print();
    window.onafterprint = () => {
      window.close();
    }
  }

}
