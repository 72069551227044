<mat-form-field style="width: 100%;">
  <mat-chip-list #chipList aria-label="Fruit selection">
    <mat-chip *ngFor="let chip of chips" [selectable]="selectable" [removable]="removable" (removed)="remove(chip)">
      {{chip['Product Code']}}
      <input type="number" class="chiptext" min="1" max="999" [(ngModel)]="chip.qty">
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <input placeholder="Bundle Products" #input [formControl]="inputValue" [matAutocomplete]="auto"
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)" (ngModelChange)="this.autoUpdate.next($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [displayWith]="displayFn">
    <mat-option *ngFor="let option of options | async" [value]="option">
      {{option['Product Code'] }} - {{option['Product Title']}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
