<mat-form-field fxFlex>
  <input type="text" [placeholder]="placeholder" aria-label="Number" matInput [matAutocomplete]="auto"
    [(ngModel)]="AutoValue" (ngModelChange)="this.autoUpdate.next($event)" autocomplete="off">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="valueChanged()" [displayWith]="displayFn">
    <mat-option *ngFor="let option of options | async" [value]="option">
      <p>{{option.name.split(' - ')[1]}}</p>
      <div fxLayout="row" style="height: 1em;">
        <p style="margin: 0;" fxFlex class="blue"><small>{{option.name.split(' - ')[0]}}</small>
        </p>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
