<mat-dialog-content class="mat-typography">
    <mat-card class="shipping-card">
        <ckeditor 
        [editor]="Editor" 
        [(ngModel)]="data.row.lpdesc">
        </ckeditor>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="EditHtml()" fxFlex>Edit</button>
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>