import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  startWith,
  map,
  debounceTime,
  distinctUntilChanged
} from "rxjs/operators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
@Component({
  selector: "app-new-bundle-chip",
  templateUrl: "./new-bundle-chip.component.html",
  styleUrls: ["./new-bundle-chip.component.css"]
})
export class NewBundleChipComponent implements OnInit {
  constructor(
    private toolTip: MatSnackBar,
  ) {
    this.autoUpdate
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(value);
      });
  }

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputValue = new FormControl();
  autoUpdate = new Subject<string>();

  @ViewChild("input") productInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  @Input() options: any;
  @Input() placeholder: any;
  @Input() chips: any[];

  @Output() valueChange = new EventEmitter();
  @Output() finalValue = new EventEmitter();

  ngOnInit() {}

  valueChanged() {
    this.finalValue.emit(this.chips);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    console.log(this.chips);

    // Add our item
    if ((value || "").trim()) {
      // this.chips.push(value.trim());
      console.log(value);
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.inputValue.setValue(null);
  }

  remove(c: string): void {
    const index = this.chips.indexOf(c);

    if (index >= 0) {
      this.chips.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const index = this.chips.findIndex((e: any) => {
      return e.pid === event.option.value.pid;
    });

    if (index < 0) {
      this.chips.push(event.option.value);
      this.productInput.nativeElement.value = "";
      this.inputValue.setValue(null);
      this.finalValue.emit(this.chips);
    } else {
      this.toolTip.open(event.option.value['Product Title'] + ' Alredy exist on chips', 'Ok', { duration: 3000 });
    }
  }

  displayFn(id) {
    if (!id) {
      return "";
    }
    return id["Product Title"];
  }
}
