import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { WindowEmitorsService } from 'src/app/window-emitors.service';

@Component({
  selector: 'search-menu-auto',
  templateUrl: './search-menu-auto.component.html',
  styleUrls: ['./search-menu-auto.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchMenuAutoComponent implements OnInit {

  
  constructor(
    private windowEmitor: WindowEmitorsService
  ) {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
      });

    this.windowEmitor.selectInvoiceProduct$.subscribe((res: any) => {
      this.myInputField.nativeElement.select();
    });
  }

  @Input() options: any;
  @Input() placeholder: any;
  @Input() key: string;
  @Input() AutoValue: string;
  @ViewChild("autoInput") myInputField: ElementRef;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();
  @Output() finalValue = new EventEmitter();

  valueChanged() {
    this.myInputField.nativeElement.select()
    this.finalValue.emit(this.AutoValue);
  }

  r_color = "primary";

  displayFn(id) {
    if (!id) { return '' }
    return id.description;
  }

  clear() {
    this.AutoValue = '';
  }

  toggleR() {
    if (this.r_color === 'primary') {
      this.r_color = 'warn';
    } else {
      this.r_color = 'primary';
    }
  }

  enterPressed() {
    if (this.r_color === 'warn') {
      setTimeout(() => {
        this.options.subscribe((res: any) => {
          if (res.length !== 0) {
            this.finalValue.emit(res[0]);
          }
        })
      }, 100);
    }
  }

  ngOnInit() {

  }


}
