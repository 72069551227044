<div class="bg">
	<div class="page">
		<div class="content">

			<h3 style="text-align: center;">Proforma Invoice</h3>

			<div class="header" fxLayout="row" fxLayoutAlign="center center">
				<div style="width: 9em;"></div>
				<div fxFlex>
					<img style="width: 100%;" [src]="base + 'image/logo.jpg' | authImage | async">
				</div>
				<div style="width: 9em;"></div>
			</div>

			<p style="text-align: center;line-height: 1;margin: 0;width: 100%;">{{company_address}}</p>
			<p style="text-align: center;line-height: 1;margin: 0;width: 100%;">{{company_tel}}</p>

			<div fxLayout="row" fxLayoutAlign="end end">
				<p>{{date | date}}</p>
			</div>

			<div fxLayout="row" class="nolineheight">
				<p>M/s CASH</p>
				<p fxFlex></p>
				<p>Order No: {{data.OrderNo}}</p>
			</div>

			<div *ngFor="let table of tables" class="table">

				<table style="width:100%">
					<tr>
						<th class="number">Qty</th>
						<th style="text-align: left;">Description</th>
						<th class="number">Rate</th>
						<th class="number">Amount</th>
					</tr>
					<tr *ngFor="let col of table.table">
						<td class="number">{{col.oqty}}</td>
						<td>{{col.description}}</td>
						<td class="number">{{col.sp | currency: ' '}}</td>
						<td class="number">{{col.amount | currency: ' '}}</td>
					</tr>

				</table>

			</div>
			<br>
			<div fxLayout="row" class="nolineheight">
				<div fxLayout="column">
					<p>Name</p>
					<p>Address</p>
					<p>City</p>
					<p>TEL</p>
				</div>
				<div fxLayout="column" class="lefttext">
					<p>: {{data.CustomerName}}</p>
					<p>: {{data.Address}}</p>
					<p>: {{data.City}}</p>
					<p>: {{data.ContactNo}}</p>
				</div>
				<div fxFlex></div>
				<div fxLayout="column">
					<p>Sub Total :</p>
					<p>Discount :</p>
					<p>Delivery Fee :</p>
					<p>Net Total :</p>
					<p>Payment Mode :</p>
				</div>
				<div fxLayout="column" class="lefttext">
					<P style="text-align: right; width: 4em;"> {{data.GrossAmount | currency : ' '}}</P>
					<P style="text-align: right; width: 4em;"> {{data.discount | currency : ' '}}</P>
					<P style="text-align: right; width: 4em;"> {{data.delivery | currency : ' '}}</P>
					<P style="text-align: right; width: 4em;"> {{data.netAmount | currency : ' '}}</P>
					<P style="text-align: right; width: 4em;"> {{data.mode}}</P>
				</div>
			</div>

		</div>
	</div>

	<button mat-fab class="fabPrint" color="warn" (click)="print()">
		<mat-icon>print</mat-icon>
	</button>

</div>