import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-stock-transfer-auto',
  templateUrl: './stock-transfer-auto.component.html',
  styleUrls: ['./stock-transfer-auto.component.css']
})
export class StockTransferAutoComponent implements OnInit {

  constructor() {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
      });
  }

  @Input() options: any;
  @Input() placeholder: any;
  @Input() key: string;
  @Input() AutoValue: string;
  @ViewChild("autoInput") myInputField: ElementRef;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();
  @Output() finalValue = new EventEmitter();

  valueChanged() {
    this.myInputField.nativeElement.select()
    this.finalValue.emit(this.AutoValue);
  }

  displayFn(id) {
    if (!id) { return '' }
    return id.description;
  }

  clear() {
    this.AutoValue = '';
  }

  chooseFirstOption(): void {
    this.matAutocomplete.options.first.select();
  }

  ngOnInit() {
    console.log(this.options);
  }
}
