import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-card-top',
  templateUrl: './info-card-top.component.html',
  styleUrls: ['./info-card-top.component.css']
})
export class InfoCardTopComponent implements OnInit {

  constructor() { }

  @Input() icon: string;
  @Input() iconColor: string;
  @Input() subIcon: string;
  @Input() amount: string;
  @Input() heading: string;
  @Input() subHeading: string;

  ngOnInit() {

  }

}
