<div class="container">
    
    <div *ngFor="let item of sectionHtml" (click)="test(item)" class="sectionHolder">
        <img [src]="item.img + '.jpg?' + item.size | authImage | async" alt="item.disc" />
        <input matInput [placeholder]="item.disc" [(ngModel)]="item.disc" (click)="$event.target.select()">
        <p>Shop {{item.disc}}</p>
    </div>

</div>

<button mat-raised-button color="primary" class="save" (click)="save()">Save</button>