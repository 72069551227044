<div class="page">
  <form [formGroup]="newCustomer" autocomplete="off" fxFlex="60" fxFlex.xs="100" autocomplete="off" fxFlex>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Create a new Customer</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="newCustomer" fxFlex>
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <input matInput formControlName="Code" placeholder="Code">
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <input matInput formControlName="Name" placeholder="Name" />
            </mat-form-field>
            <div style="margin:0 0 0 1em">
              <button class="btn" mat-raised-button color="primary" (click)="uploadImage()">Image</button>
            </div>
          </div>
          <div fxLayout="row" appPrivileage="OWNERSNAME CUSTOMER">
            <mat-form-field fxFlex>
              <input matInput formControlName="OwnersName" placeholder="Owner's Name" />
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="NIC CUSTOMER">
            <mat-form-field fxFlex>
              <input matInput formControlName="NIC" placeholder="NIC" />
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="DOB CUSTOMER">
            <mat-form-field fxFlex>
              <mat-label>Date of Birth</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="dateofBirth">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="ADDRESS CUSTOMER">
            <mat-form-field fxFlex>
              <input matInput formControlName="Address" placeholder="Address" />
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="CITY CUSTOMER">
            <app-auto-city fxFlex key="city" [options]="citys" placeholder="Select City"
              (finalValue)="cityFinalValue($event)">
            </app-auto-city>
            <div style="margin:0 0 0 1em">
              <button class="btn" mat-raised-button color="primary" (click)="selectLocation()">Location</button>
            </div>
          </div>
          <div fxLayout="row" appPrivileage="COUNTRY CUSTOMER">
            <mat-form-field fxFlex>
              <mat-label>Country</mat-label>
              <mat-select formControlName="Country" (selectionChange)="loadCity()">
                <mat-option *ngFor="let c of countrys | async" [value]="c.Country">
                  {{c.Country}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="OCCUPATION CUSTOMER">
            <mat-form-field fxFlex>
              <input matInput formControlName="occupation" placeholder="Occupation" />
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="CONTACT1 CUSTOMER">
            <mat-form-field fxFlex>
              <input matInput type="number" formControlName="primary_Contact_No" placeholder="Primary Contact No" />
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="CONTACT2 CUSTOMER">
            <mat-form-field fxFlex>
              <input matInput type="number" formControlName="secondary_Contact_No" placeholder="Secondary Contact No" />
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="EMAIL CUSTOMER">
            <mat-form-field fxFlex>
              <input matInput formControlName="Email" placeholder="Email" />
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="LOYALTY CUSTOMER">
            <mat-form-field fxFlex>
              <input matInput formControlName="LoyaltyCardNo" placeholder="Loyalty Card No" />
            </mat-form-field>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button color="warn" [disabled]="!newCustomer.valid || inserting" (click)="add()" fxFlex>ADD</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>