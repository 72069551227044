import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'app-auto-city',
  templateUrl: './auto-city.component.html',
  styleUrls: ['./auto-city.component.css']
})
export class AutoCityComponent implements OnInit {

  constructor() {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
        if (typeof this.AutoValue === 'string') {
          this.optionsAuto = this.options.filter((e: any) => {
            const value = this.AutoValue.toLocaleLowerCase();
            const city = e.city.toLocaleLowerCase();
            return city.includes(value);
          }).slice(0, 20);
        }
      });
  }

  @Input() options: any;
  @Input() placeholder: any;
  @Input() key: string;
  @Input() AutoValue: string;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();
  @Output() finalValue = new EventEmitter();

  optionsAuto = [];

  valueChanged() {
    this.finalValue.emit(this.AutoValue);
  }

  displayFn(id:any) {
    if (!id) { return '' }
    return id.city;
  }


  ngOnInit() {
    console.log(this.optionsAuto);
  }

}
