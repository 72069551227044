import { Component, OnInit } from '@angular/core';
import { GetServerDataServiceService } from '../get-server-data-service.service';
import * as converter from 'number-to-words';

@Component({
  selector: 'app-print-view-receipt',
  templateUrl: './print-view-receipt.component.html',
  styleUrls: ['./print-view-receipt.component.css']
})
export class PrintViewReceiptComponent implements OnInit {

  
  constructor(
    private server: GetServerDataServiceService
  ) { }

  date = "";
  company_name = "company_name";
  amount = "0";
  VoucherNo = "VoucherNo";
  Description = "";
  Account = "";
  amountInWords = "";
  grnNos = "";
  username = "";
  paidMode = "";
  Supplier = "";
  SupplierAddress = "";
  SupplierCity = "";
  Customer = "";
  base = ""

  ngOnInit(): void {
    this.setValues();
  }

  print(): void {
    window.print();
  }

  setValues() {
    if (localStorage.printView) {
      const printView = JSON.parse(localStorage.printView);
      this.base = this.server.base();

      this.server.get_company_name().subscribe(res => {
        this.username = localStorage.username;
        this.company_name = res[0]['Company Name'];
        this.date = printView['Paid Date'];
        this.amount = printView['Paid Amount'];
        this.VoucherNo = printView['Voucher Code'];
        this.Description = printView.Description;
        this.Account = printView.Account;
        this.amountInWords = converter.toWords(this.amount).toLocaleUpperCase() + " RUPEES.";
        this.paidMode = printView['Paid By'];
        this.Supplier = printView.Supplier;
        this.SupplierAddress = printView['Supplier Address'];
        this.SupplierCity = printView['Supplier City'];
        this.Customer = printView['Customer'];

        this.server.payment_vouchers_deb_pop(printView.id, printView.id)
          .subscribe(res => {
            const grnNoList = [];
            res.map((e:any) => {
              grnNoList.push(e['Bill No']);
            });
            this.grnNos = grnNoList.join();
          });
      });

      console.log(printView);
    }
  }

  ngAfterViewInit(): void {
    window.print();
    window.onafterprint = () => {
      window.close();
    }
  }

}
