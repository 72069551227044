<div fxLayout="row">
  <mat-form-field fxFlex>
    <input matInput [(ngModel)]="name" placeholder="Category Name">
  </mat-form-field>
</div>
<div fxLayout="row">
  <mat-form-field fxFlex>
    <input matInput [(ngModel)]="remarks" placeholder="Remarks">
  </mat-form-field>
</div>
<div fxLayout="row">
  <button mat-raised-button color="primary" (click)="update()" [disabled]="!valid" fxFlex>Update Brand</button>
</div>