import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'auto-location',
  templateUrl: './auto-location.component.html',
  styleUrls: ['./auto-location.component.css']
})
export class AutoLocationComponent implements OnInit {


  constructor() {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
      });
  }

  @Input() options: any;
  @Input() placeholder: any;
  @Input() AutoValue: string;
  @Input() appearance: string;
  @Input() autoDisable: boolean;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();
  @Output() valueFinal = new EventEmitter();

  valueChanged() {
    this.valueFinal.emit(this.AutoValue);
  }

  displayFn(id: any) {
    if (!id) { return '' }
    return id.main_title;
  }

  ngOnInit() {
    if (!this.appearance) {
      this.appearance = 'standard';
    }
    // console.log(this.options);
  }

}
