import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-print-view',
  templateUrl: './print-view.component.html',
  styleUrls: ['./print-view.component.css'],
  providers: [CurrencyPipe, DatePipe]
})
export class PrintViewComponent implements OnInit {

  constructor(
    private router: Router,
    private currency: CurrencyPipe,
    private dateTrans: DatePipe
  ) { }

  title = '';
  returnURL = '/';
  stockHeader: any;
  grnHeader: any;
  tables = [];

  footer: { [key: string]: string } = {
    'Printed By': localStorage.username,
    'Printed On': this.dateTrans.transform(new Date())
  };

  ngOnInit() {
    this.setValues();
  }

  goBack() {
    this.router.navigateByUrl(this.returnURL);
  }

  checkStatus(status: string) {
    if (status) {
      return status.toLocaleLowerCase() === 'canceled';
    } else {
      return false;
    }
  }

  colClass(col: string) {
    return 'narrow ' + col.replace(/ /g, '');
  }

  format(col: any, val: any) {
    if (col === 'Sales Price' || col === 'Amount' || col === 'Transfer Amount'
      || col === 'Cost Price' || col === 'Sales Amount' || col === 'Cost Amount' ||  col === 'Sale Amount') {
      return this.currency.transform(val, ' ');
    } else {
      return val;
    }2
  }

  setValues() {
    if (localStorage.printView) {
      const printView = JSON.parse(localStorage.printView);
      this.title = printView.title;
      this.returnURL = printView.returnURL;
      this.stockHeader = printView.stockHeader;
      this.grnHeader = printView.grnHeader;
      this.tables = printView.tables.map((e, i) => {
        e.disply = true;
        return e;
      });
      if (printView.Category) {
        this.footer.Category = printView.Category.value;
      } else {
        this.footer.Category = '';
      }

      if (this.tables[0].table[0]['Batch ID']) {
        this.footer['Batch ID'] = this.tables[0].table[0]['Batch ID'];
      }

    }
  }

  toggle(): void {
    this.tables = this.tables.map(e => {
      e.disply = !e.disply;
      return e;
    });
  }

  print(): void {
    window.print();
  }

}
