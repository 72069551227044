<mat-form-field fxFlex class="description">
  <input (keydown.enter)="enterPressed()" type="text" [placeholder]="placeholder" aria-label="Number" matInput
    [matAutocomplete]="auto" [(ngModel)]="AutoValue" (ngModelChange)="this.autoUpdate.next($event)" #autoInput>
  <button matSuffix mat-icon-button color="warn" aria-label="Clear Product Text" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
  <button matSuffix mat-flat-button (document:keydown.alt.R)="toggleR()" [color]="r_color" (click)="toggleR()"
    aria-label="R" class="mini_btton">R</button>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="valueChanged()" [displayWith]="displayFn">
    <div *ngIf="r_color === 'primary'">
      <mat-option *ngFor="let option of options | async" [value]="option">
        <p> ({{option.code}}) {{option.description}}</p>
        <div fxLayout="row" style="height: 1em;">
          <p style="margin: 0;" fxFlex class="blue"><small>Stock Location: {{option.bin}}</small>
          </p>
          <p style="margin: 0;"fxFlex class="ash"><small>Sale Price: {{option.sp | currency : ' '}}</small></p>
          <p style="margin: 0;" fxFlex class="green"><small>Available Quantity: {{option.qty || option.sqty}}</small></p>
        </div>
      </mat-option>
    </div>
  </mat-autocomplete>
</mat-form-field>