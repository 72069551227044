import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-calander-view',
  templateUrl: './full-calander-view.component.html',
  styleUrls: ['./full-calander-view.component.css']
})
export class FullCalanderViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
