<table [style.width.px]="width">
    <tr>
        <th *ngFor="let colomn of colomns">{{colomn}}</th>
        <th></th>
    </tr>
    <tr *ngFor="let row of data">
        <td *ngFor="let colomn of colomns" (dblclick)="enable($event)" (keyup.enter)="edit($event)">
            <!-- <input *ngIf="colomTypes[colomn] != 'index'" type="text" [(ngModel)]="row[colomn]" disabled> -->
            <span>{{row[colomn]}}</span>
        </td>
        <td>
            <button mat-raised-button>
                <mat-icon (click)="delete(row)">delete</mat-icon>
            </button>
        </td>
    </tr>
    <tr>
        <td *ngFor="let colomn of colomns">
            <input *ngIf="colomTypes[colomn] === 'text'" class="inputElement" type="text" [(ngModel)]="emptyRow[colomn]"
                (keyup.enter)="keytab($event)">
            <input *ngIf="colomTypes[colomn] === 'number'" class="inputElement" type="number"
                [(ngModel)]="emptyRow[colomn]" (keyup.enter)="keytab($event)">
            <mat-form-field *ngIf="colomTypes[colomn] === 'date'">
                <input matInput [matDatepicker]="picker" class="inputElement" [(ngModel)]="emptyRow[colomn]"
                    (keyup.enter)=" keytab($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <span *ngIf="colomTypes[colomn] === 'index'">{{emptyRow[colomn] + 1}}</span>
        </td>
        <td>
            <button mat-raised-button (click)="add()">
                <mat-icon>add</mat-icon>
            </button>
        </td>
    </tr>
</table>