import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetServerDataServiceService } from 'src/app/get-server-data-service.service';


@Component({
  selector: 'app-sales-reports-sales-orders-summery-dialog',
  templateUrl: './sales-reports-sales-orders-summery-dialog.component.html',
  styleUrls: ['./sales-reports-sales-orders-summery-dialog.component.css']
})
export class SalesReportsSalesOrdersSummeryDialogComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<SalesReportsSalesOrdersSummeryDialogComponent>,
    private server: GetServerDataServiceService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  @Output() subItemSelected = new EventEmitter();

  tableList = [];

  selectedRowsByID = [];

  selectAllModel = false;

  displayedColumns = [
    '_id',
    'Product Code',
    'Product Description',
    'Sold Qty',
    'Discount',
    'Sale Price',
    'Amount'
  ];

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.server.sales_reports_sales_orders_summery_popup(this.data.id.oid)
      .subscribe(res => {
        console.log(res);
        this.tableList = res.map((e:any,i:any) => {
          e._id = i +1;
          return e;
        });
      });
  }

}
