import { Component, Input } from '@angular/core';
import { GetServerDataServiceService } from 'src/app/get-server-data-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
    selector: 'app-image-grid-section',
    templateUrl: './ImageGridSection.html',
    styleUrls: ['./ImageGridSection.css'],
})
export class ImageGridSection {

    constructor(
        private server: GetServerDataServiceService,
        private toolTip: MatSnackBar) { }

    @Input() sectionHtml: any;
    @Input() sectionType: any;

    test(item: any) {
        // alert(JSON.stringify(item));
    }

    save() {
        this.server.save_sections(this.sectionType,JSON.stringify(this.sectionHtml))
            .subscribe(res=>{
                console.log(res);
                this.toolTip.open('Successfully Saved!', 'Ok', { duration: 3000 });
            });
    }

}
