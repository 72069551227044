import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  template: `
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    <div fxLayout="row">
      <img src="/assets/images/{{data.src}}" alt="{{data.alt}}" onError="this.src='/assets/images/404.png'" #image>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="warn" [mat-dialog-close]="data" fxFlex>Cancel</button>
    <a href="/assets/images/{{data.src}}" target="_new" *ngIf="image.src.split('404').length == 1" fxFlex>
      <button mat-button color="warn" fxFlex>Full</button>
    </a>
  </div>
  `,
  styles: [
    'p {text-align: justify;min-height:100px}',
    'a {text-decoration: none}'
]
})
export class ImageViewMessageDialog {
  constructor(
    public dialogRef: MatDialogRef<ImageViewMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}