import { Directive, Input, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetServerDataServiceService } from './get-server-data-service.service';
import { ExportComponent } from './export/export.component';

@Directive({
  selector: '[appExportData]'
})
export class ExportDataDirective {

  @Input() appExportData: string;
  @Input() filePrifix: string;
  @Input() query: any;

  constructor(
    private dialog: MatDialog,
    private server: GetServerDataServiceService,
    private toolTip: MatSnackBar,
  ) { }

  @HostListener('click') onClick() {

    const exportDataRef = this.dialog.open(ExportComponent,
      {
        data: {
          path: this.appExportData,
          filePrifix: this.filePrifix,
          query: this.query
        },
        width: '300px',
      }
    );

  }

}
