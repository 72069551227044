<form [formGroup]="roleUpdateForm" autocomplete="off" fxFlex>
    <mat-card class="shipping-card">
        <mat-card-content>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input matInput formControlName="userrole" placeholder="User Role">
                </mat-form-field>
            </div>
            <div fxLayout="row">
                    <mat-form-field fxFlex>
                        <input matInput formControlName="description" placeholder="Description">
                    </mat-form-field>
                </div>
        </mat-card-content>
        <mat-card-actions>
            <button mat-raised-button color="primary" (click)="update()" [disabled]="!roleUpdateForm.valid"fxFlex>Save</button>
        </mat-card-actions>
        <mat-progress-bar mode="indeterminate" *ngIf="inserting"></mat-progress-bar>
    </mat-card>
</form>