<h1 mat-dialog-title>Already registered</h1>
<div mat-dialog-content>
    <mat-card *ngFor="let contact of this.data.res">
        <mat-card-header>
            <mat-card-title>{{contact.name}}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <p><strong>{{contact.contact}}</strong></p>
            <div fxLayout="row">
                <p>Code: {{contact.code}}</p>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button fxFlex mat-button color="primary" (click)="select(contact)" >Select</button>
        </mat-card-actions>
    </mat-card>
</div>
<div mat-dialog-actions>
    <button fxFlex mat-button (click)="onNoClick()">CLOSE</button>
</div>