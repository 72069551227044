import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderState } from './loader';
import { SpinnerServiceService } from 'src/app/spinner-service.service';

@Component({
  selector: 'app-load-spinner',
  template: `<mat-spinner class="insideSpinnser" *ngIf="show"></mat-spinner>`,
  styles: [`
  .insideSpinnser{
    position: fixed;
    top: 50%;
    left: 50%;
  }`]
})
export class LoadSpinnerComponent implements OnInit, OnDestroy {

  show = true;

  private subscription: Subscription;

  constructor(private loaderService: SpinnerServiceService) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
