<div fxLayoutAlign="center center" class="activateAcoount">
	<form [formGroup]="activateUser" fxFlex="30" fxFlex.sm="50" fxFlex.xs="80">
		<mat-card class="acivateAccountCard">
			<mat-card-header>
				<mat-card-title>Please Activate Your Account</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div fxLayout="row">
					<mat-form-field fxFlex>
						<input matInput placeholder="Email Address" formControlName="emailAddress" required>
						<mat-error *ngIf="activateUser.controls.code.hasError('required')">
							Enter A valid <strong>Email Address.</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div fxLayout="row">
					<mat-form-field fxFlex>
						<textarea matInput placeholder="Activation Code" formControlName="code" required></textarea>
						<mat-error *ngIf="activateUser.controls.emailAddress.hasError('required')">
							Please Check Your Email to <strong>Get Activation Code.</strong>
						</mat-error>
					</mat-form-field>
				</div>
			</mat-card-content>
			<mat-card-actions>
				<button mat-raised-button color="primary" (click)="goback()" fxFlex>Go Back</button>
				<button mat-raised-button color="primary" (click)="sendVerificationCode()" fxFlex>Resend Email</button>
				<button mat-raised-button color="primary" [disabled]="!activateUser.valid" (click)="activate()"
					fxFlex>Activate</button>
			</mat-card-actions>
		</mat-card>
	</form>
</div>