<mat-form-field fxFlex>
	<input type="text" class="autoProduct" [placeholder]="placeholder" aria-label="Number" matInput
		[matAutocomplete]="auto" [(ngModel)]="AutoValue" (ngModelChange)="this.autoUpdate.next($event)"
		[disabled]="disabled" (keyup.enter)="enter($event)" (click)='openPanel()' #inputAutoComplit>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="valueChanged()" [displayWith]="displayFn">
		<mat-option *ngFor="let option of options | async" [value]="option">
      <p>{{option.product}}</p>
			<div fxLayout="row" style="height: 1em;">
				<p style="margin: 0;" fxFlex class="blue"><small>Sale Price: {{option['Sale Price'] | currency : ' '}}</small>
				</p>
				<p style="margin: 0;" fxFlex class="green"><small>Cost Price: {{option['Cost Price'] | currency : ' '}}</small>
				</p>
				<p style="margin: 0;" fxFlex class="ash"><small>Stock Qty: {{option['Stock Qty']}}</small></p>
			</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>