<div class="page">
	<form [formGroup]="createBrand" fxFlex.xs="100">
		<mat-card class="shipping-card">
			<mat-card-header>
				<mat-card-title>New Brand</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div fxLayout="row">
					<mat-form-field fxFlex>
						<input matInput formControlName="name" placeholder="Brand Name">
					</mat-form-field>
				</div>
				<div fxLayout="row">
					<mat-form-field fxFlex>
						<input matInput formControlName="remarks" placeholder="Remarks">
					</mat-form-field>
				</div>
			</mat-card-content>
			<mat-card-actions>
				<button mat-raised-button color="primary" (click)="submit()" [disabled]="!createBrand.valid" fxFlex>Create
					Brand</button>
			</mat-card-actions>
		</mat-card>
	</form>
</div>