import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetGeoLocationService {

  getPosition(): Promise<any> {

    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        },
        { timeout: 60000 }
      );
    });

  }

  watchPosition(success: any, error: any) {

    const options = {
      enableHighAccuracy: false,
      timeout: 60000,
      maximumAge: 0
    };

    return navigator.geolocation.watchPosition(success, error, options);

  }
}
