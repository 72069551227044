import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { PrivileageDirective } from "../privileage.directive";
import { LoadSpinnerComponent } from "../load-spinner/load-spinner.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { EditableDataTableComponent } from "./editable-data-table/editable-data-table.component";
import { AutoCompleteComponent } from "./auto-complete/auto-complete.component";
import { ExportDataDirective } from "../export-data.directive";
import { ProductAutoComponent } from "./product-auto/product-auto.component";
import { InvoiceAutoComponent } from "./invoice-auto/invoice-auto.component";
import { SalesPersonAutoComponent } from "../sales-management/sales-person-auto/sales-person-auto.component";
import { InputCurrencyFormatterDirective } from '../input-currency-formatter.directive';
import { AutoCityComponent } from './auto-city/auto-city.component';
import { StockTransferAutoComponent } from '../stock-management/stock-transfer-auto/stock-transfer-auto.component';
import { ProductAttachmentAutoComponent } from './product-attachment-auto/product-attachment-auto.component';
import { NewBundleChipComponent } from './new-bundle-chip/new-bundle-chip.component';
import { ChipComponent } from './chip/chip.component';
import { MatRippleModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { FixImageBtnComponent } from './fix-image-btn/fix-image-btn.component';
import { AutoNeoComponent } from './auto-neo/auto-neo.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCurrencyFormatModule } from 'mat-currency-format';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SaleChartComponent } from './sale-chart/sale-chart.component';
import { InfoCardTopComponent } from './info-card-top/info-card-top.component';
import { MiniLineChartComponent } from './mini-line-chart/mini-line-chart.component';
import { ChartistModule } from 'ng-chartist';
import { CharistDemoChartComponent } from './charist-demo-chart/charist-demo-chart.component';
import { CharistDountCardComponent } from './charist-dount-card/charist-dount-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { InvoiceNoAutoComponent } from './invoice-no-auto/invoice-no-auto.component';
import { ProductAutoReturnComponent } from './product-auto-return/product-auto-return.component';
import { ShortNumberPipePipe } from '../short-number-pipe.pipe';
import { SelectLocationComponent } from './select-location/select-location.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AutoLocationComponent } from './auto-location/auto-location.component';
import { AuthImagePipe } from '../auth-image.pipe';
import { SearchMenuComponent } from './search-menu/search-menu.component';
import { SearchMenuAutoComponent } from './search-menu-auto/search-menu-auto.component';
import { CustomerAutoComponent } from './customer-auto/customer-auto.component';
import { BarcodeSearchAutoComponent } from './barcode-search-auto/barcode-search-auto.component';

@NgModule({
  declarations: [
    PrivileageDirective,
    LoadSpinnerComponent,
    EditableDataTableComponent,
    AutoCompleteComponent,
    ExportDataDirective,
    ProductAutoComponent,
    InvoiceAutoComponent,
    SalesPersonAutoComponent,
    CustomerAutoComponent,
    InputCurrencyFormatterDirective,
    AutoCityComponent,
    StockTransferAutoComponent,
    ProductAttachmentAutoComponent,
    NewBundleChipComponent,
    ChipComponent,
    FixImageBtnComponent,
    AutoNeoComponent,
    SaleChartComponent,
    InfoCardTopComponent,
    MiniLineChartComponent,
    CharistDemoChartComponent,
    CharistDountCardComponent,
    InvoiceNoAutoComponent,
    ProductAutoReturnComponent,
    ShortNumberPipePipe,
    AuthImagePipe,
    SelectLocationComponent,
    AutoLocationComponent,
    SearchMenuComponent,
    SearchMenuAutoComponent,
    BarcodeSearchAutoComponent
  ],
  imports: [
    CommonModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatSortModule,
    MatRippleModule,
    MatRadioModule,
    DragDropModule,
    MatSlideToggleModule,
    MatCurrencyFormatModule,
    NgxChartsModule,
    ChartistModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatDialogModule
  ],
  exports: [
    CommonModule,
    PrivileageDirective,
    ExportDataDirective,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    LoadSpinnerComponent,
    MatIconModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatDatepickerModule,
    EditableDataTableComponent,
    AutoCompleteComponent,
    MatCheckboxModule,
    MatSortModule,
    ProductAutoComponent,
    InvoiceAutoComponent,
    SalesPersonAutoComponent,
    CustomerAutoComponent,
    InputCurrencyFormatterDirective,
    AutoCityComponent,
    StockTransferAutoComponent,
    ProductAttachmentAutoComponent,
    NewBundleChipComponent,
    ChipComponent,
    MatAutocompleteModule,
    MatRadioModule,
    FixImageBtnComponent,
    AutoNeoComponent,
    DragDropModule,
    MatSlideToggleModule,
    MatCurrencyFormatModule,
    NgxChartsModule,
    SaleChartComponent,
    InfoCardTopComponent,
    MiniLineChartComponent,
    ChartistModule,
    CharistDemoChartComponent,
    CharistDountCardComponent,
    MatMenuModule,
    MatButtonToggleModule,
    MatDividerModule,
    ProductAutoReturnComponent,
    ShortNumberPipePipe,
    AuthImagePipe,
    MatDialogModule,
    AutoLocationComponent,
    InvoiceNoAutoComponent,
    MatRippleModule,
    BarcodeSearchAutoComponent
  ]
})
export class SharedModule { }
