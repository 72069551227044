import { Component, OnInit, Input, Output, EventEmitter, HostBinding, HostListener, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, timeout } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-product-auto',
  templateUrl: './product-auto.component.html',
  styleUrls: ['./product-auto.component.css']
})
export class ProductAutoComponent implements OnInit {

  constructor() {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
      });
  }

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplit: MatAutocompleteTrigger;

  @Input() options: any;
  @Input() placeholder: any;
  @Input() key: string;
  @Input() AutoValue: string;
  @Input() disabled: boolean;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();
  @Output() finalValue = new EventEmitter();
  @Output() enterPressed = new EventEmitter();

  @HostBinding('tabindex') tabindex = 0;

  @HostListener('focus')

  focusHandler() {
    const input: any = document.querySelectorAll('.autoProduct');
    setTimeout(() => {
      input[input.length - 1].focus();
    }, 100);
  }

  openPanel(): void {
    this.inputAutoComplit.openPanel();
  }

  valueChanged() {
    this.finalValue.emit(this.AutoValue);
  }

  displayFn(id: any) {
    if (!id) { return '' }
    return id.product;
  }

  enter(event: any): void {
    this.enterPressed.emit(event.path);
  }


  ngOnInit() {
    // console.log(this.options);
  }

}
