import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  template: `
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    <div fxLayout="row">
      <p>{{data.message}}</p>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button color="warn" [mat-dialog-close]="data" fxFlex>Reload And Update</button>
  </div>
  `,
  styles: [
    'p {text-align: justify;min-height:100px}'
]
})
export class NotificationMessageDialog {
  constructor(
    public dialogRef: MatDialogRef<NotificationMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}