<div class="bg">
  <div class="page">
    <div class="content">
      <div fxLayout="row" *ngFor="let item of barcodes; index as i;" class="pg_br">
        <div class="box" *ngIf="barcodes[(i*2)]">
          <div class="code">
            <p>{{barcodes[(i*2)].Product_Code}}</p>
          </div>
          <div class="product">
            <p>{{barcodes[(i*2)].Product_Description}}</p>
          </div>
          <ngx-barcode class="barcode" bc-width="1" bc-height="40" [bc-value]="barcodes[(i*2)].Barcode"
            [bc-display-value]="false">
          </ngx-barcode>
          <div class="cost">
            <p *ngIf="showCost">{{barcodes[(i*2)].cpCost}}</p>
          </div>
          <div class="curruncy">
            <p>{{barcodes[(i*2)].Sale_Price | currency:' '}} {{currency}}</p>
          </div>
          <div class="gno">
            <p>{{barcodes[(i*2)].GRN_No}}</p>
          </div>
        </div>
        <div style="width: 2mm;"></div>
        <div class="box" *ngIf="barcodes[(i*2+1)]">
          <div class="code">
            <p>{{barcodes[(i*2+1)].Product_Code}}</p>
          </div>
          <div class="product">
            <p>{{barcodes[(i*2+1)].Product_Description}}</p>
          </div>
          <ngx-barcode class="barcode" bc-width="1" bc-height="40" [bc-value]="barcodes[(i*2+1)].Barcode"
            [bc-display-value]="false">
          </ngx-barcode>
          <div class="cost">
            <p *ngIf="showCost">{{barcodes[(i*2+1)].cpCost}}</p>
          </div>
          <div class="curruncy">
            <p>{{barcodes[(i*2+1)].Sale_Price | currency:' '}} {{currency}}</p>
          </div>
          <div class="gno">
            <p>{{barcodes[(i*2+1)].GRN_No}}</p>
          </div>
        </div>
      </div>
    </div>

  </div>

  <button mat-fab class="fabPrint" color="warn" (click)="print()">
    <mat-icon>print</mat-icon>
  </button>