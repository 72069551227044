import { Component, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-print-view-members',
  templateUrl: './print-view-members.component.html',
  styleUrls: ['./print-view-members.component.css'],
  providers: [CurrencyPipe, DatePipe]
})
export class PrintViewMembersComponent implements OnInit {

  constructor(
    private router: Router,
    private currency: CurrencyPipe,
    private dateTrans: DatePipe
  ) { }

  title = '';
  returnURL = '/';
  stockHeader: any;
  grnHeader: any;
  tables = [];
  headCols = [];

  nestedTableColomns = [];

  footer: { [key: string]: string } = {
    'Created By': localStorage.username,
    'Printed On': this.dateTrans.transform(new Date())
  };

  ngOnInit() {
    this.setValues();
  }

  goBack() {
    this.router.navigateByUrl(this.returnURL);
  }

  colClass(col: string) {
    return 'narrow ' + col.replace(/ /g, '');
  }

  format(col: any, val: any) {
    if (col === 'Sales Price' || col === 'Amount' || col === 'Transfer Amount'
      || col === 'Cost Price' || col === 'Sales Amount' || col === 'Cost Amount') {
      return this.currency.transform(val, ' ');
    } else {
      return val;
    }2
  }

  setValues() {
    if (localStorage.printView) {
      const printView = JSON.parse(localStorage.printView);
      this.title = printView.title;
      this.returnURL = printView.returnURL;
      this.stockHeader = printView.stockHeader;
      this.grnHeader = printView.grnHeader;
      this.nestedTableColomns = printView.nestedTableColomns;
      this.grnHeader = printView.grnHeader;
      this.headCols = printView.headCols;
      this.tables = printView.tables.map((e:any) => {
        e.disply = true;
        return e;
      });
      if (printView.Category) {
        this.footer.Category = printView.Category.value;
      } else {
        this.footer.Category = '';
      }

    }
  }

  toggle(): void {
    this.tables = this.tables.map(e => {
      e.disply = !e.disply;
      return e;
    });
  }

  print(): void {
    window.print();
  }

}
