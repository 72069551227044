<div class="page" appPrivileage="VIEW DASHBOARD">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1em">
    <app-mini-line-chart fxFlex icon="home" iconColor="gold" subIcon="home" heading="Sale"
      subHeading="Sub heading" bgImage="linear-gradient(45deg,#b02940,#fcc173)" serverURL="hourly_sale_mini_stat"></app-mini-line-chart>
    <app-mini-line-chart fxFlex icon="home" iconColor="gold" subIcon="home" heading="Sale Return"
      subHeading="Sub heading" bgImage="linear-gradient(45deg,#226cc5,#6cd975)" serverURL="sale_return_mini_stat"></app-mini-line-chart>
    <app-mini-line-chart fxFlex icon="home" iconColor="gold" subIcon="home" heading="Delivery Charges"
      subHeading="Sub heading" bgImage=" linear-gradient(45deg,#7441db,#f9877c)" serverURL="delivery_charges_mini_stat"></app-mini-line-chart>
    <app-mini-line-chart fxFlex icon="home" iconColor="gold" subIcon="home" heading="Discount"
      subHeading="Sub heading" bgImage="linear-gradient(45deg,#7441db,#c89cff);" serverURL="today_discount_mini_stat"></app-mini-line-chart>
  </div>
  <br>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1em">
    <app-charist-demo-chart fxFlex></app-charist-demo-chart>
  </div>
  <br>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1em">
    <app-charist-dount-card serverURL1="doughnut_invoices" serverURL2="doughnut_invoice_cancelled" heading="Orders Cancelled" subHeading="Total Orders" icon="trending_up" color="#009da0" fxFlex></app-charist-dount-card>
    <app-charist-dount-card serverURL1="doughnut_deliveries" serverURL2="doughnut_to_be_dispatch_count" heading="Orders Delivered" subHeading="Total Deliveries" icon="local_shipping" color="#ff8d60" fxFlex></app-charist-dount-card>
    <app-charist-dount-card serverURL1="doughnut_deliveries" serverURL2="doughnut_dispatch_count" heading="To Be Delivered" subHeading="Total Deliveries" icon="query_builder" color="#ff586b" fxFlex></app-charist-dount-card>
  </div>
</div>

<!-- TODO add bar chart  https://i.imgur.com/gOzTExe.png-->
<!-- TODO add bar chart  https://i.imgur.com/jqxlJCQ.png-->
