import { GetServerDataServiceService } from './../../get-server-data-service.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  templateUrl: 'all-roles-modify-dialog.html',
  styles: [
    '.mat-card { box-shadow: none; }'
  ]
})
export class AllRolessModifyDialog {
  constructor(
    public dialogRef: MatDialogRef<AllRolessModifyDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private server: GetServerDataServiceService,
    private toolTip: MatSnackBar) { }

  roleUpdateForm = this.fb.group({
    userrole: [null, Validators.compose([Validators.required])],
    description: [null],
  });


  poNoFilter: Observable<string[]>;
  poNoLoading = false;
  inserting = false;

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log(this.data);
    this.roleUpdateForm.get('userrole').setValue(this.data.Role);
    this.roleUpdateForm.get('description').setValue(this.data.Description);
  }

  update(): void {
    this.inserting = true;
    this.server.update_role(this.roleUpdateForm.value, this.data.id).subscribe(res => {
      this.inserting = false;
      if (res.error) {
        this.toolTip.open(this.roleUpdateForm.get('userrole').value + ' already exists!', 'Ok', { duration: 3000 });
      } else {
        this.toolTip.open(this.data.id + ' Updated!', 'Ok', { duration: 3000 });
        this.dialogRef.close();
      }

    });
  }



}