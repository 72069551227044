<div class="paper">
	<div class="sticky">
		<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5em" class="borderSpacing">
			<button mat-button color="primary" (click)="goBack()" fxFlex *ngIf="false">Back</button>
			<p fxFlex style="text-align: center;"> Print View : {{title}}</p>
			<button mat-raised-button color="primary" (click)="print()" fxFlex>Print</button>
		</div>
	</div>

	<div class="header" *ngIf="stockHeader">
		<h3>{{stockHeader.main}}</h3>
		<p style="text-align: center;">{{stockHeader.sub}}
		<p>
	</div>

	<div class="header" *ngIf="grnHeader">
		<div fxLayout="row">
			<p><strong>Supplier : </strong>{{grnHeader.line_1['Supplier']}} </p>
		</div>
		<div fxLayout="row">
			<p style="width: 20em"><strong>GRN No : </strong>{{grnHeader.line_1['GRN No']}}</p>
			<p fxFlex style="width: 20em"><strong>GRN Date : </strong>{{grnHeader.line_1['GRN Date']}}</p>
			<p fxFlex><strong>Supplier Invoice : </strong>{{grnHeader.line_1['Supplier Invoice']}} </p>
		</div>
		<div fxLayout="row">
			<p fxFlex><strong>Note : </strong>{{grnHeader.line_2['Note']}} </p>
		</div>
	</div>

	<div *ngFor="let table of tables" class="table">

		<div fxLayout="row">
			<table mat-table [dataSource]="table.table" *ngIf="table.disply" fxFlex>

				<ng-container [matColumnDef]="col" *ngFor="let col of table.displayedColumns">
					<th mat-header-cell *matHeaderCellDef>{{col}}</th>
					<td mat-cell *matCellDef="let element" [class]="colClass(col)">
						{{format(col, element[col])}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="table.displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns:
                table.displayedColumns;" [ngClass]="{'grayBg': checkStatus(row['D Status'])}"></tr>
			</table>
		</div>

		<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em" class="subFooter">
			<p *ngFor="let i of table.footer | keyvalue"><strong>{{i.value.name}}</strong> :
				{{i.value.value | currency: " "}}</p>
		</div>

		<footer fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em">
			<p *ngFor="let i of footer | keyvalue"><strong *ngIf="i.value !== ''">{{i.key}} : </strong>{{i.value }}</p>
		</footer>
	</div>
	<div style="height: 5em;" class="print_hide">

	</div>
</div>