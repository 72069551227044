import { Component, OnInit, ElementRef } from '@angular/core';
import * as d3 from 'd3';
import { WindowEmitorsService } from 'src/app/window-emitors.service';

@Component({
  selector: 'app-sale-chart',
  templateUrl: './sale-chart.component.html',
  styleUrls: ['./sale-chart.component.css']
})
export class SaleChartComponent implements OnInit {

  multi = [
    {
      name: 'Sierra Leone',
      series: [
        {
          value: 0,
          name: new Date('2016-09-17T05:13:10.482Z')
        },
        {
          value: 3514,
          name: new Date('2017-09-19T08:21:54.569Z')
        },
        {
          value: 5809,
          name: new Date('2018-09-16T04:43:30.205Z')
        },
        {
          value: 0,
          name: new Date('2019-09-15T21:04:27.836Z')
        },
        {
          value: 6632,
          name: new Date('2020-09-19T04:33:37.925Z')
        }
      ]
    },
    {
      name: 'Armenia',
      series: [
        {
          value: 5421,
          name: new Date('2016-09-17T05:13:10.482Z')
        },
        {
          value: 3209,
          name: new Date('2017-09-19T08:21:54.569Z')
        },
        {
          value: 4704,
          name: new Date('2018-09-16T04:43:30.205Z')
        },
        {
          value: 4569,
          name: new Date('2019-09-15T21:04:27.836Z')
        },
        {
          value: 3551,
          name: new Date('2020-09-19T04:33:37.925Z')
        }
      ]
    }
  ];

  // options
  chartData = [];
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showGridLines = true;
  legendPosition = 'below';
  legendTitle = 'PRODUCT SALES';

  curve = d3.curveMonotoneX;
  timeline = false;
  autoScale = false;

  view: any[] = [180, 400];

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#AAAAFF']
  };

  constructor(
    private el: ElementRef,
    private windowEmitors: WindowEmitorsService
  ) {
    windowEmitors.sideBarToggled$.subscribe((e: number) => {
      this.view = [180, 400];
      setTimeout(() => {
        this.view = [this.el.nativeElement.offsetWidth - 20, 400];
      }, 100);
    });
  }

  // ngAfterViewInit() {
  //   this.chartData = this.multi;
  // }

  onSelect(event) {
    console.log(event);
  }

  ngOnInit() {
    setTimeout(() => {
      this.view = [this.el.nativeElement.offsetWidth - 20, 400];
    }, 100);
  }

}
