import { Component, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import { WindowEmitorsService } from 'src/app/window-emitors.service';
import { GetServerDataServiceService } from 'src/app/get-server-data-service.service';

@Component({
  selector: 'app-mini-line-chart',
  templateUrl: './mini-line-chart.component.html',
  styleUrls: ['./mini-line-chart.component.css']
})
export class MiniLineChartComponent implements OnInit {

  constructor(
    private render: Renderer2,
    private el: ElementRef,
    private server: GetServerDataServiceService,
    private windowEmitors: WindowEmitorsService
  ) {
    windowEmitors.sideBarToggled$.subscribe((e: number) => {
      this.view = [180, 70];
      setTimeout(() => {
        this.view = [this.el.nativeElement.offsetWidth - 40, 70];
      }, 100);
    });
  }

  @Input() icon: string;
  @Input() iconColor: string;
  @Input() subIcon: string;
  @Input() heading: string;
  @Input() subHeading: string;
  @Input() bgImage: string;
  @Input() serverURL: string;

  amount = 0;
  selected = "Today";
  showAmount = false;

  toggleAmount() {
    this.showAmount = !this.showAmount;
  }

  ngOnInit(): void {
    this.updateChartWidth();
    this.getData();
  }

  changeSelected(s: string) {
    this.selected = s;
    this.updateChartWidth();
    this.getData();
  }

  updateChartWidth() {
    setTimeout(() => {
      this.view = [this.el.nativeElement.offsetWidth - 40, 70];
    }, 100);
  }

  getData() {
    this.server[this.serverURL](this.selected).subscribe((res: any) => {
      this.amount = 0;
      if (res.length > 0) {
        const series = res.map((e: any) => {
          this.amount += e.value;
          return { value: e.value, name: e.x }
        });

        this.multi = [
          {
            name: '',
            series: series
          }
        ];

      } else {
        this.amount = 0;
        this.multi = [
          {
            name: '',
            series: [{ value: 0, name: 1 }, { value: 0, name: 2 }]
          }
        ];
      }

    });
  }

  multi = [
    {
      name: '',
      series: []
    }
  ];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = false;
  yAxis: boolean = false;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = false;

  view: any[] = [175, 70];

  colorScheme = {
    domain: ['#FFF']
  };

}
