<div class="bg">
  <div class="page">
    <div class="content">
      <div class="header" fxLayout="column" fxLayoutAlign="center center">
        <div fxFlex>
          <img [src]="base + 'image/logo.jpg' | authImage | async" style="width: 10em;">
        </div>
        <p fxFlex *ngIf="company_address !== ''" fxFlex>{{company_address}}</p>
        <p fxFlex *ngIf="company_tel !== ''">{{company_tel}}</p>
        <p fxFlex *ngIf="company_fax !== ''">{{company_fax}}</p>
        <p fxFlex *ngIf="company_email !== ''">{{company_email}}</p>
        <p fxFlex *ngIf="company_br_no !== ''">{{company_br_no}}</p>
        <p flFlex class="dotted"><span>BILL OPEND</span></p>
        <div style="width: 100%;" fxLayout="row">
          <p><strong>Bill No:</strong> {{tables[0].table[0]['Bill No']}}</p>
          <div fxFlex></div>
          <p style="width: 50%;text-align: left;"><strong>Customer:</strong> {{tables[0].table[0].Customer}}</p>
        </div>
        <div style="width: 100%;" fxLayout="row">
          <p><strong>Cashier:</strong> {{tables[0].table[0]['Cashier']}}</p>
          <div fxFlex></div>
          <p style="width: 50%;text-align: left;"><strong>Address:</strong> {{tables[0].table[0]['Deliver to']}}</p>
        </div>
      </div>

      <div *ngFor="let table of tables" class="table">

        <table style="width:100%">
          <tr class="dotted">
            <th style="text-align: center;">Description</th>
            <th class="number">Qty</th>
            <th class="number">Sale Price</th>
            <th class="number">Amount</th>
          </tr>
          <tr *ngFor="let col of table.table">
            <td colspan="4">
              <div class="card">
                <div fxFlex style="text-align: justify;">
                  <strong>{{col['#']}}.</strong> {{col['Product Description']}}
                </div>
                <div fxFlex fxFlex="row">
                  <div fxFlex>{{col['Discount']}}</div>
                  <div fxFlex style="text-align: center;">{{col['Qty']}}</div>
                  <div fxFlex style="text-align: right;">{{col['Unit Price']}}</div>
                  <div fxFlex class="number">{{col['Amount']}}</div>
                </div>
              </div>
            </td>
          </tr>

          <tr class="total_amount dotted">
            <td colspan="3"></td>
            <td colspan="1" class="number"></td>
          </tr>

          <tr class="total_amount large" *ngIf="tables[0].table[0]['Gross Amount(Value)'] !== ''">
            <td colspan="3">{{tables[0].table[0]['Gross Amount(Label)']}}</td>
            <td colspan="1" class="number">{{tables[0].table[0]['Gross Amount(Value)']}}</td>
          </tr>
          <tr class="total_amount large" *ngIf="(tables[0].table[0]['Total Discount(Value)'] !== '') && discount_or">
            <td colspan="3">{{tables[0].table[0]['Total Discount(Label)']}}</td>
            <td colspan="1" class="number">{{tables[0].table[0]['Total Discount(Value)']}}</td>
          </tr>
          <tr class="total_amount large" *ngIf="tables[0].table[0]['Return Amount(Value)'] !== ''">
            <td colspan="3">{{tables[0].tfable[0]['Return Amount(Label)']}}</td>
            <td colspan="1" class="number">{{tables[0].table[0]['Return Amount(Value)']}}</td>
          </tr>
          <tr class="total_amount large" *ngIf="tables[0].table[0]['Bill Amount'] !== ''">
            <td colspan="3">Net Amount</td>
            <td colspan="1" class="number">{{tables[0].table[0]['Bill Amount']}}</td>
          </tr>
          <tr class="total_amount large" *ngIf="tables[0].table[0]['Paid Amount(Value)'] !== ''">
            <td colspan="3">{{tables[0].table[0]['Paid Amount(Label)']}}</td>
            <td colspan="1" class="number">{{tables[0].table[0]['Paid Amount(Value)']}}</td>
          </tr>

          <tr class="total_amount op_pay" *ngIf="tables[0].table[0]['Card Paid(Value)'] !== ''">
            <td colspan="3">{{tables[0].table[0]['Card Paid(Label)']}}</td>
            <td colspan="1" class="number">{{tables[0].table[0]['Card Paid(Value)']}}</td>
          </tr>
          <tr class="total_amount op_pay" *ngIf="tables[0].table[0]['Voucher Paid(Value)'] !== ''">
            <td colspan="3">{{tables[0].table[0]['Voucher Paid(Label)']}}</td>
            <td colspan="1" class="number">{{tables[0].table[0]['Voucher Paid(Value)']}}</td>
          </tr>
          <tr class="total_amount op_pay" *ngIf="tables[0].table[0]['Online Paid(Value)'] !== ''">
            <td colspan="3">{{tables[0].table[0]['Online Paid(Label)']}}</td>
            <td colspan="1" class="number">{{tables[0].table[0]['Online Paid(Value)']}}</td>
          </tr>
          <tr class="total_amount op_pay" *ngIf="tables[0].table[0]['Cheque Paid(Value)'] !== ''">
            <td colspan="3">{{tables[0].table[0]['Cheque Paid(Label)']}}</td>
            <td colspan="1" class="number">{{tables[0].table[0]['Cheque Paid(Value)']}}</td>
          </tr>

          <tr class="total_amount" *ngIf="tables[0].table[0]['Balance(Label)'] !== ''">
            <td colspan="3">{{tables[0].table[0]['Balance(Label)']}}</td>
            <td colspan="1" class="number">{{tables[0].table[0]['Balance(Value)']}}</td>
          </tr>
        </table>

      </div>

      <div class="header" fxLayout="column" fxLayoutAlign="center center">
        <p flFlex class="dotted"><span>BILL Closed</span></p>
        <p>{{tables[0].table[0]['INVOICE_DATE']}} {{tables[0].table[0]['INVOICE_TIME']}}</p>
        <h3 *ngIf="(tables[0].table[0]['Total Discount(Value)'] !== '') && !discount_or">{{discount_footer}}</h3>
        <h3 *ngIf="tables[0].table[0]['Outstanding Amount'] !== ''">Total Outstanding Amount
          ({{tables[0].table[0]['Outstanding Amount'] | currency: ' '}})</h3>
        <p>{{warn_footer}}</p>
        <p>{{thank_footer}}</p>
      </div>

      <div fxLayout="column" style="margin:0 auto;">
        <p class="copy">Software &copy; {{developed_by}} www.greencodings.com</p>
        <p class="copy">Powerd By {{powered_by}}</p>
        <ngx-barcode class="barcode" [bc-value]="tables[0].table[0]['Bill No']" [bc-display-value]="false">
        </ngx-barcode>
      </div>

    </div>
  </div>

  <button mat-fab class="fabPrint" color="warn" (click)="print()">
    <mat-icon>print</mat-icon>
  </button>

</div>