import { Component, OnInit } from '@angular/core';
import { GetServerDataServiceService } from '../get-server-data-service.service';
import * as converter from 'number-to-words';

@Component({
  selector: 'app-print-view-payment-voucher-petty-cash',
  templateUrl: './print-view-payment-voucher-petty-cash.component.html',
  styleUrls: ['./print-view-payment-voucher-petty-cash.component.css']
})
export class PrintViewPaymentVoucherPettyCashComponent implements OnInit {

  constructor(
    private server: GetServerDataServiceService
  ) { }

  date = "";
  company_name = "company_name";
  amount = "0";
  VoucherNo = "VoucherNo";
  Description = "";
  Account = "";
  amountInWords = "";

  ngOnInit(): void {
    this.setValues();
  }

  print():void {
    window.print();
  }

  setValues() {
    if (localStorage.printView) {
      const printView = JSON.parse(localStorage.printView);

      this.server.get_company_name().subscribe(res => {
        this.company_name = res[0]['Company Name'];
        this.date = printView.Date;
        this.amount = printView.Amount;
        this.VoucherNo = printView.Code;
        this.Description = printView.Description;
        this.Account = printView.Account;
        this.amountInWords = converter.toWords(this.amount).toLocaleUpperCase() + " RUPEES."; 
      });
      
      console.log(printView);
    }
  }
}
