import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.css']
})
export class AutoCompleteComponent implements OnInit {

  constructor() {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
      });
  }

  @Input() options: any;
  @Input() placeholder: any;
  @Input() key: string;
  @Input() AutoValue: string;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();

  valueChanged() {
    this.valueChange.emit(this.AutoValue);
  }

  displayFn(id: any) {
    if (!id) { return '' }
    console.log(id);
    return id.type;
  }


  ngOnInit() {
    console.log(this.options);
  }

}
