import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GetServerDataServiceService } from '../get-server-data-service.service';
import { Router } from '@angular/router';
import { CookieService } from '../cookie.service';

@Component({
  selector: 'app-select-outlet',
  templateUrl: './select-outlet.component.html',
  styleUrls: ['./select-outlet.component.css']
})
export class SelectOutletComponent {

  constructor(
    public dialogRef: MatDialogRef<SelectOutletComponent>,
    private server: GetServerDataServiceService,
    private router: Router,
    private cookie: CookieService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  favoriteSeason: string;
  outlets = this.server.getOutlets();
  loading = false;


  login(outlet: string) {
    console.log(this.data);
    this.loading = true;
    this.server.login(this.data.username, this.data.password, this.data.location, outlet)
      .subscribe(
        res => {
          // console.log(res);
          localStorage.setItem('jwt', res.jwt);
          this.cookie.setCookie('jwt', res.jwt, 30);
          localStorage.setItem('uid', res.uid);
          localStorage.setItem('access', JSON.stringify(res.access));
          localStorage.setItem('username', res.username);
          localStorage.setItem('branch', outlet);
          localStorage.setItem('verified', res.verified);

        },
        err => {
          setTimeout(() => {
            this.loading = false;
            console.log(err.error);
            this.dialogRef.close();
          }, 2000);

        },
        () => {
          this.loading = false;
          this.dialogRef.close();
          if (localStorage.branch) {
            if (Number(localStorage.getItem('verified')) === 0) {
              this.router.navigate(['activate']);
            } else {
              this.router.navigate(['main/dashboard']);
            }
          }
        }
      );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
