export enum Module {
  SUPPLIER = 0,
  CUSTOMER = 1,
  PRODUCT = 2,
  BILL = 3,
  GRN = 4,
  STOCK = 5,
  ADJUSTMENT = 6,
  RETURN = 7,
  PAYMENT = 8,
  SETTINGS = 9,
  CANCELLATION = 10,
  PRINT = 11,
  DASHBOARD = 12,
  CASHIER = 13,
  WITHDRAWAL = 14,
  EXPENSES = 15,
  CASHFLOAT = 16,
  SERVICE = 17,
  USER = 18,
  PERMISSION_ROLES = 19,
  PNL = 20,
  COSTS = 21,
  SYSTEM = 22,
  PO = 23,
  BARCODE = 24,
  PRICE_TAG = 25,
  EMPLOYEE = 26,
  UTILITIES = 27,
  BRANCH_SWITCH = 28,
  USER_SWITCH = 29,
  QTY_ADJ = 30,
  PRICE_ADJ = 31,
  COST_ADJ = 32,
  STOCK_TRANSFER = 33,
  PRIVILEDGE_CUSTOMER = 34,
  RICEMILL_PRODUCTION = 35,
  STOCK_TAKE = 36,
  BIN_CARD = 37,
  VEHICLE = 38,
  CUSTOM = 39,
  DISPATCH = 40,
  COURIER = 41,
  RENT = 42,
  CHEQUE = 43,
  ORDERS = 44,
  ADVANCE_PAYMENT = 45,
  CASHIER_BALANCE = 46,
  CATEGORY = 47,
  RECEIPT = 48,
  WEB_MANAGEMENT = 49,
  PRODUCT_MANAGEMENT = 50,
  STOCK_MANAGEMENT = 51,
  DELIVERY_MANAGEMENT = 52,
  USER_MANAGEMENT = 53,
  RENT_MANAGEMENT = 54,
  SALES_MANAGEMENT = 55,
  REPORTS_MANAGEMENT = 56,
  PROMOTION_MANAGEMENT = 57,
  CUSTOMER_MANAGEMENT = 58,
  TAYLOR_MANAGEMENT = 59,
  MEMEBERSHIP_MANAGEMENT = 60,
  ACCOUNT_MANAGEMENT = 61,
  BUNDLE = 62,
  BRAND = 63,
  OPENING_STOCK = 64,
  STOCK_RECEIVE = 65,
  DAY_END = 66,
  WEB_ORDERS = 67,
  TRIP_HISTORY = 68,
  OPENING_BALANCE = 69,
  PETTY_CASH = 70,
  CASH_BOOK = 71,
  CHEQUE_DETAILS = 72,
  BILL_PAYABLE = 73,
  RECEIPTS = 74,
  ROLE_CAPABILITIES = 75,
  USER_SETTINGS = 76,
  STOCK_LOCATION = 77,
  DARAZ = 78,
  SALES_ORDER = 79,
}
