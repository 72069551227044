import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class GetServerDataServiceService {
  constructor(private http: HttpClient) { }

  getCompanyFromSubdomain() {
    if (localStorage.company) {
      return localStorage.company;
    } else {
      if (
        document.location.href.match(
          /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/
        )
      ) {
        return document.location.href.match(
          /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/
        )[1];
      } else {
        return null;
      }
    }
  }

  getDiviceName() {
    return navigator.userAgent;
  }

  base() {
    const c = localStorage.company;
    const h = window.location.host;
    if (c === '127') {
      return "http://127.0.0.1:3032/"
    } else if (c === 'chenaradodge') {
      return '/';
    } else {
      return 'https://bsa.greencloudpos.com/';
    }
  }

  login(username: string, password: string, location: string, outlet: string) {
    const company = this.getCompanyFromSubdomain();
    const device = this.getDiviceName();
    return this.http.post<any>(this.base() + "login", {
      username,
      password,
      company,
      device,
      location,
      outlet
    });
  }

  getOutlets() {
    const company = this.getCompanyFromSubdomain();
    return this.http.post<any>(this.base() + "get_outlets", { company });
  }

  remove_user(id: string) {
    return this.http.post<any>(this.base() + "remove_user", { id });
  }

  remove_role(id: string) {
    return this.http.post<any>(this.base() + "remove_role", { id });
  }

  all_users() {
    return this.http.post<any>(this.base() + "all_users", { jwt: "jwt" });
  }

  getBranches() {
    return this.http.post<any>(this.base() + "get_branches", { jwt: "jwt" });
  }

  update_user(val: any, UID: any, PID: any) {
    return this.http.post<any>(this.base() + "update_user", { val, UID, PID });
  }

  update_role(val: any, rid: any) {
    return this.http.post<any>(this.base() + "update_role", { val, rid });
  }

  update_user_neo(val: any) {
    return this.http.post<any>(this.base() + "update_user_neo", { val });
  }

  get_capabilities() {
    return this.http.post<any>(this.base() + "get_capabilities", { jwt: "jwt" });
  }

  save_capabilities(val: any) {
    return this.http.post<any>(this.base() + "save_capabilities", { val });
  }

  send_verification_email() {
    return this.http.post<any>(this.base() + "send_verification_email", { jwt: "jwt" });
  }

  activate_user(email: string, code: string) {
    return this.http.post<any>(this.base() + "activate_user", { email, code });
  }

  permissions() {
    return this.http.post<any>(this.base() + "permissions", { jwt: "jwt" });
  }

  new_user(val: any) {
    return this.http.put<any>(this.base() + "new_user", { val });
  }

  new_role(val: any) {
    return this.http.put<any>(this.base() + "new_role", { val });
  }

  get_product_table(query: any) {
    return this.http.post<any>(this.base() + "get_product_table", { query });
  }

  upload_image(fd: any) {
    return this.http.post(this.base() + "image_upload", fd, {
      reportProgress: true,
      observe: "events"
    });
  }

  upload_image_file(fd: any) {
    return this.http.post<any>(this.base() + "upload_image_file", fd, {
      reportProgress: true,
      observe: "events"
    });
  }

  get_splash_screens() {
    return this.http.post<any>(this.base() + "get_splash_screens", { jwt: "jwt" });
  }

  get_image_files(path: string) {
    return this.http.post<any>(this.base() + "get_image_files", { path });
  }

  update_web_show(value: string, id: string) {
    return this.http.post<any>(this.base() + 'update_web_show', { value, id });
  }

  update_hot_offer(value: any, id: string) {
    return this.http.post<any>(this.base() + 'update_hot_offer', { value, id });
  }

  update_trends(value: any, id: string) {
    return this.http.post<any>(this.base() + 'update_trends', { value, id });
  }

  update_brand_trends(value: any, id: string) {
    return this.http.post<any>(this.base() + 'update_brand_trends', { value, id });
  }

  delete_image(row: any) {
    return this.http.post<any>(this.base() + "delete_image", { row });
  }

  update_product_html(row: any) {
    return this.http.post<any>(this.base() + "update_product_html", { row });
  }

  get_settings(type: string) {
    return this.http.post<any>(this.base() + "get_settings", { type });
  }

  get_section_types(type: string) {
    return this.http.post<any>(this.base() + "get_section_types", { type });
  }

  save_sections(type: any, value: any) {
    return this.http.post<any>(this.base() + "save_sections", { type, value });
  }

  get_sync_time() {
    return this.http.post<any>(this.base() + "get_sync_time", { jwt: "jwt" });
  }

  get_product_code() {
    return this.http.post<any>(this.base() + "get_product_code", { jwt: "jwt" });
  }

  get_order_count() {
    return this.http.post<any>(this.base() + "get_order_count", { jwt: "jwt" });
  }

  get_product_type(search: string) {
    return this.http.post<any>(this.base() + "get_product_type", { search });
  }

  get_Product_Description(search: string) {
    return this.http.post<any>(this.base() + "get_Product_Description", { search });
  }

  put_product(submitData: any, others: any) {
    return this.http.put<any>(this.base() + "new_product", { submitData, others });
  }

  put_order(submitData: any, outlet: any) {
    return this.http.put<any>(this.base() + "put_order", { submitData, outlet });
  }

  get_order(query: any) {
    return this.http.post<any>(this.base() + "get_order", { query });
  }

  get_order_pending(query: any) {
    return this.http.post<any>(this.base() + "get_order_pending", { query });
  }

  get_orders_pending_new_order(query: any) {
    return this.http.post<any>(this.base() + "get_orders_pending_new_order", { query });
  }

  get_order_to_be_packed(query: any) {
    return this.http.post<any>(this.base() + "get_order_to_be_packed", { query });
  }

  get_product_list(query: any) {
    return this.http.post<any>(this.base() + "get_product_list", { query });
  }

  get_orders_sub(tpoid: string) {
    return this.http.post<any>(this.base() + "get_orders_sub", { tpoid });
  }

  export_to_file(data: any, type: string) {
    return this.http.post(
      this.base() + "export_to_file",
      { data, type },
      {
        responseType: "blob",
        headers: new HttpHeaders().append("Content-Type", "application/json")
      }
    );
  }

  get_orders_set_status_sub(status: string, id: string) {
    return this.http.post<any>(this.base() + "get_orders_set_status_sub", { status, id });
  }

  orders_main_table(status: string, id: string) {
    return this.http.post<any>(this.base() + "orders_main_table", { status, id });
  }

  orders_main_table_batch(status: string, ids: any) {
    return this.http.post<any>(this.base() + "orders_main_table_batch", { status, ids });
  }

  orders_main_table_batch_ready_to_ship(status: string, ids: any) {
    return this.http.post<any>(this.base() + "orders_main_table_batch_ready_to_ship", {
      status,
      ids
    });
  }

  orders_main_table_batch_ready_to_shiped(status: string, ids: any) {
    return this.http.post<any>(this.base() + "orders_main_table_batch_ready_to_shiped", {
      status,
      ids
    });
  }

  get_batch_id_list() {
    return this.http.post<any>(this.base() + "get_batch_id_list", { jwt: "jwt" });
  }

  get_batch_id_list_by_category(category) {
    return this.http.post<any>(this.base() + "get_batch_id_list_by_category", { category });
  }

  get_orders_reports_status_by_category_batch(category, batchID) {
    return this.http.post<any>(this.base() + "get_orders_reports_status_by_category_batch", {
      category,
      batchID
    });
  }

  get_orders_reports_reports_status_by_category_batch(category, batchID) {
    return this.http.post<any>(
      "get_orders_reports_reports_status_by_category_batch",
      { category, batchID }
    );
  }

  get_orders_status() {
    return this.http.post<any>(this.base() + "get_orders_status", { jwt: "jwt" });
  }

  get_orders_reports_status() {
    return this.http.post<any>(this.base() + "get_orders_reports_status", { jwt: "jwt" });
  }

  get_shpped_orders_by_id(id: string) {
    return this.http.post<any>(this.base() + "get_shpped_orders_by_id", { id });
  }

  rent_item_entry_code() {
    return this.http.post<any>(this.base() + "rent_item_entry_code", { jwt: "jwt" });
  }

  rent_item_entry_ItemCategory(search: string) {
    return this.http.post<any>(this.base() + "rent_item_entry_ItemCategory", { search });
  }

  rent_item_entry_insert(submitData: any) {
    return this.http.put<any>(this.base() + "rent_item_entry_insert", { submitData });
  }

  get_stock_receive_view(batchID: string) {
    return this.http.post<any>(this.base() + "get_stock_receive_view", { batchID });
  }

  stock_receive_insert(array: any) {
    return this.http.put<any>(this.base() + "stock_receive_insert", { array });
  }

  select_batch_ids_qty_receive() {
    return this.http.post<any>(this.base() + "select_batch_ids_qty_receive", { jwt: "jwt" });
  }

  get_grn_entry_grn_code(id: string) {
    return this.http.post<any>(this.base() + "get_grn_entry_grn_code", { id });
  }

  get_grn_entry_grn_supplier() {
    return this.http.post<any>(this.base() + "get_grn_entry_grn_supplier", { jwt: "jwt" });
  }

  get_grn_entry_product(search: string, sid: string) {
    return this.http.post<any>(this.base() + "get_grn_entry_product", { search, sid });
  }

  stock_view(query: any) {
    return this.http.post<any>(this.base() + "stock_view", { query });
  }

  payment_vouchers(query: any) {
    return this.http.post<any>(this.base() + "payment_vouchers", { query });
  }

  payment_vouchers_bills(query: any) {
    return this.http.post<any>(this.base() + "payment_vouchers_bills", { query });
  }

  payment_vouchers_deb(query: any) {
    return this.http.post<any>(this.base() + "payment_vouchers_deb", { query });
  }

  payment_vouchers_pop(id: number, id2: number) {
    return this.http.post<any>(this.base() + "payment_vouchers_pop", { id, id2 });
  }

  payment_vouchers_bill_print(id: number, id2: number) {
    return this.http.post<any>(this.base() + "payment_vouchers_bill_print", { id, id2 });
  }

  payment_vouchers_deb_pop(id: number, id2: number) {
    return this.http.post<any>(this.base() + "payment_vouchers_deb_pop", { id, id2 });
  }

  sales_reports_bill_wise(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_bill_wise", { query });
  }

  service_reports_bill_wise(query: any) {
    return this.http.post<any>(this.base() + "service_reports_bill_wise", { query });
  }

  service_reports_bill_wise_details(query: any) {
    return this.http.post<any>(this.base() + "service_reports_bill_wise_details", { query });
  }

  sales_reports_bill_wise_popup(id: number) {
    return this.http.post<any>(this.base() + "sales_reports_bill_wise_popup", { id });
  }

  service_reports_bill_wise_summary_popup_cost_amount(id: number) {
    return this.http.post<any>(
      "service_reports_bill_wise_summary_popup_cost_amount",
      { id }
    );
  }

  service_reports_bill_wise_summary_popup_paid_amount(id: number) {
    return this.http.post<any>(
      "service_reports_bill_wise_summary_popup_paid_amount",
      { id }
    );
  }

  service_reports_bill_wise_summary_popup_sale_amount(id: number) {
    return this.http.post<any>(
      "service_reports_bill_wise_summary_popup_sale_amount",
      { id }
    );
  }

  sales_reports_bill_wise_Details(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_bill_wise_Details", { query });
  }

  sales_reports_supplier_wise(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_supplier_wise", { query });
  }

  sales_reports_supplier_wise_detail(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_supplier_wise_detail", { query });
  }

  supplier_list() {
    return this.http.post<any>(this.base() + "supplier_list", { jwt: "" });
  }

  sales_reports_supplier_wise_popup(id: number, to: string, from: string) {
    return this.http.post<any>(this.base() + "sales_reports_supplier_wise_popup", {
      id,
      to,
      from
    });
  }

  sales_reports_item_wise(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_item_wise", { query });
  }

  sales_reports_item_wise_popup(id: number) {
    return this.http.post<any>(this.base() + "sales_reports_item_wise_popup", { id });
  }

  sales_reports_date_wise(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_date_wise", { query });
  }

  stock_transfer(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_item_wise", { query });
  }

  report_management_stock_transfer(query: any) {
    return this.http.post<any>(this.base() + "report_management_stock_transfer", { query });
  }

  report_management_stock_transfer_popup(id: number, to: string, from: string) {
    return this.http.post<any>(this.base() + "report_management_stock_transfer_popup", {
      id,
      to,
      from
    });
  }

  report_management_stock_receive(query: any) {
    return this.http.post<any>(this.base() + "report_management_stock_receive", { query });
  }

  report_management_stock_receive_popup(id: number, to: string, from: string) {
    return this.http.post<any>(this.base() + "report_management_stock_receive_popup", {
      id,
      to,
      from
    });
  }

  report_management_grn_reports(query: any) {
    return this.http.post<any>(this.base() + "report_management_grn_reports", { query });
  }

  report_management_grn_reports_popup(id: number, to: string, from: string) {
    return this.http.post<any>(this.base() + "report_management_grn_reports_popup", {
      id,
      to,
      from
    });
  }

  get_invoice_product_list(search: string, id: string) {
    return this.http.post<any>(this.base() + "get_invoice_product_list", { search, id });
  }

  payment_reports_Supplier(query: any) {
    return this.http.post<any>(this.base() + "payment_reports_Supplier", { query });
  }

  payment_reports_Supplier_popup(id: number, to: string, from: string) {
    return this.http.post<any>(this.base() + "payment_reports_Supplier_popup", {
      id,
      to,
      from
    });
  }

  payment_reports_Supplier_popup_popup(type: string, id: number, to: string, from: string) {
    return this.http.post<any>(this.base() + "payment_reports_Supplier_popup_popup", {
      type,
      id,
      to,
      from
    });
  }

  payment_reports_Supplier_popup_popup_2(id: number, to: string, from: string) {
    return this.http.post<any>(this.base() + "payment_reports_Supplier_popup_popup_2", {
      id,
      to,
      from
    });
  }

  payment_reports_customer(query: any) {
    return this.http.post<any>(this.base() + "payment_reports_customer", { query });
  }

  payment_reports_customer_popup(id: number, to: string, from: string) {
    return this.http.post<any>(this.base() + "payment_reports_customer_popup", {
      id,
      to,
      from
    });
  }

  payment_reports_customer_popup_popup(type: string, id: number, to: string, from: string) {
    return this.http.post<any>(this.base() + "payment_reports_customer_popup_popup", {
      type,
      id,
      to,
      from
    });
  }

  payment_reports_customer_popup_popup_2(id: number, to: string, from: string) {
    return this.http.post<any>(this.base() + "payment_reports_customer_popup_popup_2", {
      id,
      to,
      from
    });
  }

  invoice_customer_list(search: string) {
    return this.http.post<any>(this.base() + "invoice_customer_list", { search });
  }

  invoice_sales_person_list(search: string) {
    return this.http.post<any>(this.base() + "invoice_sales_person_list", { search });
  }

  invoice_get_type() {
    return this.http.post<any>(this.base() + "invoice_get_type", { jwt: "jwt" });
  }

  invoice_get_deposit_list() {
    return this.http.post<any>(this.base() + "invoice_get_deposit_list", { jwt: "jwt" });
  }

  invoice_get_card_list() {
    return this.http.post<any>(this.base() + "invoice_get_card_list", { jwt: "jwt" });
  }

  invoice_get_online_list() {
    return this.http.post<any>(this.base() + "invoice_get_online_list", { jwt: "jwt" });
  }

  get_customer_code() {
    return this.http.post<any>(this.base() + "get_customer_code", { jwt: "jwt" });
  }

  get_sales_person() {
    return this.http.post<any>(this.base() + "get_sales_person", { jwt: "jwt" });
  }

  check_customer_phone_1(no1: string, no2: string) {
    return this.http.post<any>(this.base() + "check_customer_phone_1", { no1, no2 });
  }

  check_customer_phone_2(no1: string, no2: string) {
    return this.http.post<any>(this.base() + "check_customer_phone_2", { no1, no2 });
  }

  check_customer_phone_2_edit(no1: string, no2: string, id: string) {
    return this.http.post<any>(this.base() + "check_customer_phone_2", { no1, no2, id });
  }

  add_customer(data: any) {
    return this.http.put<any>(this.base() + "add_customer", { data });
  }

  update_customer(data: any) {
    return this.http.post<any>(this.base() + "update_customer", { data });
  }

  get_invoice_bill_no() {
    return this.http.post<any>(this.base() + "get_invoice_bill_no", { jwt: "jwt" });
  }

  get_invoice_bill_no_advanced(val: string) {
    return this.http.post<any>(this.base() + "get_invoice_bill_no_advanced", { val });
  }

  ///////////////////////////////////////////////

  day_end_opening_balance(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_opening_balance", {
      to,
      from
    });
  }

  day_end_exchanges(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_exchanges", {
      to,
      from
    });
  }
  day_end_cancellations(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_cancellations", {
      to,
      from
    });
  }
  day_end_expense(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_expense", {
      to,
      from
    });
  }
  day_end_discounts(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_discounts", {
      to,
      from
    });
  }
  day_end_discount_crn(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_discount_crn", {
      to,
      from
    });
  }
  day_end_discount_drn(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_discount_drn", {
      to,
      from
    });
  }
  day_end_return_crn(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_return_crn", {
      to,
      from
    });
  }
  day_end_return_drn(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_return_drn", {
      to,
      from
    });
  }
  day_end_direct_sales(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_direct_sales", {
      to,
      from
    });
  }
  day_end_delivery_sales(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_delivery_sales", {
      to,
      from
    });
  }
  day_end_delivery_charges(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_delivery_charges", {
      to,
      from
    });
  }
  day_end_repairs(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_repairs", {
      to,
      from
    });
  }

  /////////////////////////////////////////////

  day_end_cash_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_cash_sale", {
      to,
      from
    });
  }
  day_end_amex_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_amex_sale", {
      to,
      from
    });
  }
  day_end_visa_master_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_visa_master_sale", {
      to,
      from
    });
  }
  day_end_frimi_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_frimi_sale", {
      to,
      from
    });
  }
  day_end_genie_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_genie_sale", {
      to,
      from
    });
  }
  day_end_webxpay_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_webxpay_sale", {
      to,
      from
    });
  }
  day_end_ac01_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac01_sale", {
      to,
      from
    });
  }
  day_end_ac02_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac02_sale", {
      to,
      from
    });
  }
  day_end_ac03_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac03_sale", {
      to,
      from
    });
  }
  day_end_ac04_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac04_sale", {
      to,
      from
    });
  }
  day_end_credit_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_credit_sale", {
      to,
      from
    });
  }
  day_end_cheque_sale(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_cheque_sale", {
      to,
      from
    });
  }

  /////////////////////////////////////////////

  day_end_cash_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_cash_payment", {
      to,
      from
    });
  }
  day_end_amex_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_amex_payment", {
      to,
      from
    });
  }
  day_end_visa_master_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_visa_master_payment", {
      to,
      from
    });
  }
  day_end_frimi_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_frimi_payment", {
      to,
      from
    });
  }
  day_end_genie_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_genie_payment", {
      to,
      from
    });
  }
  day_end_webxpay_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_webxpay_payment", {
      to,
      from
    });
  }
  day_end_ac01_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac01_payment", {
      to,
      from
    });
  }
  day_end_ac02_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac02_payment", {
      to,
      from
    });
  }
  day_end_ac03_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac03_payment", {
      to,
      from
    });
  }
  day_end_ac04_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac04_payment", {
      to,
      from
    });
  }
  day_end_credit_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_credit_payment", {
      to,
      from
    });
  }
  day_end_cheque_payment(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_cheque_payment", {
      to,
      from
    });
  }

  /////////////////////////////////////////////

  day_end_cash_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_cash_delivery", {
      to,
      from
    });
  }
  day_end_amex_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_amex_delivery", {
      to,
      from
    });
  }
  day_end_visa_master_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_visa_master_delivery", {
      to,
      from
    });
  }
  day_end_frimi_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_frimi_delivery", {
      to,
      from
    });
  }
  day_end_genie_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_genie_delivery", {
      to,
      from
    });
  }
  day_end_webxpay_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_webxpay_delivery", {
      to,
      from
    });
  }
  day_end_ac01_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac01_delivery", {
      to,
      from
    });
  }
  day_end_ac02_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac02_delivery", {
      to,
      from
    });
  }
  day_end_ac03_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac03_delivery", {
      to,
      from
    });
  }
  day_end_ac04_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac04_delivery", {
      to,
      from
    });
  }
  day_end_credit_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_credit_delivery", {
      to,
      from
    });
  }
  day_end_cheque_delivery(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_cheque_delivery", {
      to,
      from
    });
  }

  /////////////////////////////////////////////

  day_end_cash_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_cash_return", {
      to,
      from
    });
  }
  day_end_amex_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_amex_return", {
      to,
      from
    });
  }
  day_end_visa_master_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_visa_master_return", {
      to,
      from
    });
  }
  day_end_frimi_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_frimi_return", {
      to,
      from
    });
  }
  day_end_genie_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_genie_return", {
      to,
      from
    });
  }
  day_end_webxpay_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_webxpay_return", {
      to,
      from
    });
  }
  day_end_ac01_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac01_return", {
      to,
      from
    });
  }
  day_end_ac02_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac02_return", {
      to,
      from
    });
  }
  day_end_ac03_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac03_return", {
      to,
      from
    });
  }
  day_end_ac04_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_ac04_return", {
      to,
      from
    });
  }
  day_end_credit_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_credit_return", {
      to,
      from
    });
  }
  day_end_cheque_return(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_cheque_return", {
      to,
      from
    });
  }

  day_end_5000(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_5000", {
      to,
      from
    });
  }
  day_end_1000(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_1000", {
      to,
      from
    });
  }
  day_end_500(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_500", {
      to,
      from
    });
  }
  day_end_100(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_100", {
      to,
      from
    });
  }
  day_end_50(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_50", {
      to,
      from
    });
  }
  day_end_20(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_20", {
      to,
      from
    });
  }
  day_end_10(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_10", {
      to,
      from
    });
  }
  day_end_coins(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_coins", {
      to,
      from
    });
  }

  day_end_account_balance(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_account_balance", {
      to,
      from
    });
  }

  day_end_total_cost(to: string, from: string) {
    return this.http.post<any>(this.base() + "day_end_total_cost", {
      to,
      from
    });
  }

  day_end_se(query: any) {
    return this.http.post<any>(this.base() + "day_end_se", { query });
  }

  transfer_product_list(search: string, type: string) {
    return this.http.post<any>(this.base() + "transfer_product_list", { search, type });
  }

  transfer_product_list_reduce(search: string, type: string) {
    return this.http.post<any>(this.base() + "transfer_product_list_reduce", {
      search,
      type
    });
  }

  transfer_select_list() {
    return this.http.post<any>(this.base() + "transfer_select_list", { jwt: "jwt" });
  }

  transfer_batch_id() {
    return this.http.post<any>(this.base() + "transfer_batch_id", { jwt: "jwt" });
  }

  transfer_get_internal_list() {
    return this.http.post<any>(this.base() + "transfer_get_internal_list", { jwt: "jwt" });
  }

  insert_stock_tranfer(data: any) {
    return this.http.put<any>(this.base() + "insert_stock_tranfer", { data });
  }

  insert_stock_tranfer_reduce(data: any) {
    return this.http.put<any>(this.base() + "insert_stock_tranfer_reduce", { data });
  }

  transfer_status_list() {
    return this.http.post<any>(this.base() + "transfer_status_list", { jwt: "jwt" });
  }

  check_transfer_list(data: any) {
    return this.http.post<any>(this.base() + "check_transfer_list", { data });
  }

  transfer_transit_get_grn_no_list() {
    return this.http.post<any>(this.base() + "transfer_transit_get_grn_no_list", {
      jwt: "jwt"
    });
  }

  transfer_transit_get_serial_list(grn) {
    return this.http.post<any>(this.base() + "transfer_transit_get_serial_list", { grn });
  }

  transfer_transit_update_barcode(grn) {
    return this.http.post<any>(this.base() + "transfer_transit_update_barcode", { grn });
  }

  insert_grn_tranfer(data: any) {
    return this.http.put<any>(this.base() + "insert_grn_tranfer", { data });
  }

  inter_outlet_transfer_get_item(barcode: string) {
    return this.http.post<any>(this.base() + "inter_outlet_transfer_get_item", { barcode });
  }

  insert_inter_outlet_tranfer(data: any) {
    return this.http.put<any>(this.base() + "insert_inter_outlet_tranfer", { data });
  }

  product_attachment_list(query: any) {
    return this.http.post<any>(this.base() + "product_attachment_list", { query });
  }

  product_attachment_auto_correct_list(search: string) {
    return this.http.post<any>(this.base() + "product_attachment_auto_correct_list", {
      search
    });
  }

  product_attachment_insert(array: any) {
    return this.http.put<any>(this.base() + "product_attachment_insert", { array });
  }

  get_contact_no_list(search: string, page: number, limit: number) {
    return this.http.post<any>(this.base() + "get_contact_no_list", {
      search,
      page,
      limit
    });
  }

  get_invoicing_list(query: any) {
    return this.http.post<any>(this.base() + "get_invoicing_list", { query });
  }

  get_invoicing_list_by_group(query: any) {
    return this.http.post<any>(this.base() + "get_invoicing_list_by_group", { query });
  }

  get_return_list_by_group(query: any) {
    return this.http.post<any>(this.base() + "get_return_list_by_group", { query });
  }

  get_bundle_code() {
    return this.http.post<any>(this.base() + "get_bundle_code", { jwt: "" });
  }

  bundle_get_auto_correct(search: string) {
    return this.http.post<any>(this.base() + "bundle_get_auto_correct", {
      search
    });
  }

  bundle_inset(submitData: any) {
    return this.http.put<any>(this.base() + "bundle_inset", { submitData });
  }

  bundle_list(query: any) {
    return this.http.post<any>(this.base() + "bundle_list", { query });
  }

  invoicing_insert(data: any, outlet: string) {
    return this.http.put<any>(this.base() + "invoicing_insert", { data, outlet });
  }

  return_insert(data: any, outlet: string) {
    return this.http.put<any>(this.base() + "return_insert", { data, outlet });
  }

  shipped_orders_insert(data: any, outlet: string) {
    return this.http.put<any>(this.base() + "shipped_orders_insert", { data, outlet });
  }

  get_bundle_chip(bid: string) {
    return this.http.post<any>(this.base() + "get_bundle_chip", { bid });
  }

  edit_bundle(data: any, id: string) {
    return this.http.post<any>(this.base() + "edit_bundle", { data, id });
  }

  delete_bundle(id: string) {
    return this.http.post<any>(this.base() + "delete_bundle", { id });
  }

  invoicing_get_bundle_products_bundle(bid: string) {
    return this.http.post<any>(this.base() + "invoicing_get_bundle_products_bundle", { bid });
  }

  invoiced_get_list_group(query: any) {
    return this.http.post<any>(this.base() + "invoiced_get_list_group", { query });
  }

  customer_list(query: any) {
    return this.http.post<any>(this.base() + "customer_list", { query });
  }

  edit_customer(data: any, id: string) {
    return this.http.post<any>(this.base() + "edit_customer", { data, id });
  }

  delete_customer(id: string) {
    return this.http.post<any>(this.base() + "delete_customer", { id });
  }

  get_batch_id_list_stock_receive() {
    return this.http.post<any>(this.base() + "get_batch_id_list_stock_receive", {
      jwt: "jwt"
    });
  }

  get_stock_receive_serial_list(query: any) {
    return this.http.post<any>(this.base() + "get_stock_receive_serial_list", { query });
  }

  stock_receive_insert_g(data: any) {
    return this.http.put<any>(this.base() + "stock_receive_insert_g", { data });
  }

  reports_inter_stock_transfer(query: any) {
    return this.http.post<any>(this.base() + "reports_inter_stock_transfer", { query });
  }

  reports_inter_stock_transfer_popup(
    from: string,
    to: string,
    search: string,
    search2: string,
    batch_id: string
  ) {
    return this.http.post<any>(this.base() + "reports_inter_stock_transfer_popup", {
      from,
      to,
      search,
      search2,
      batch_id
    });
  }

  reports_inter_outlet_transfer(query: any) {
    return this.http.post<any>(this.base() + "reports_inter_outlet_transfer", { query });
  }

  reports_inter_outlet_transfer_popup(
    from: string,
    to: string,
    search: string,
    search2: string,
    batch_id: string
  ) {
    return this.http.post<any>(this.base() + "reports_inter_outlet_transfer_popup", {
      from,
      to,
      search,
      search2,
      batch_id
    });
  }

  tailor_management_get_list(search: string, category: string) {
    return this.http.post<any>(this.base() + "tailor_management_get_list", {
      search,
      category
    });
  }

  tailor_management_get_fabric() {
    return this.http.post<any>(this.base() + "tailor_management_get_fabric", {
      jwt: "jwt"
    });
  }

  invoice_inset(sendData: any) {
    return this.http.put<any>(this.base() + "invoice_inset", {
      sendData
    });
  }

  web_orders(query: any) {
    return this.http.post<any>(this.base() + "web_orders", { query });
  }

  web_order_get_popup(id: string) {
    return this.http.post<any>(this.base() + "web_order_get_popup", { id });
  }

  reports_sales_sales_person(query: any) {
    return this.http.post<any>(this.base() + "reports_sales_sales_person", { query });
  }

  web_orders_verify(row: any) {
    return this.http.put<any>(this.base() + "web_orders_verify", { row });
  }

  web_orders_delete(order_no: string) {
    return this.http.post<any>(this.base() + "web_orders_delete", { order_no });
  }

  cancellations_auto(search: string) {
    return this.http.post<any>(this.base() + "cancellations_auto", { search });
  }

  get_cancellations_code() {
    return this.http.post<any>(this.base() + "get_cancellations_code", { jst: "jwt" });
  }

  cancelation_inset(data: any) {
    return this.http.put<any>(this.base() + "cancelation_inset", { data });
  }

  get_shpped_orders(query: any) {
    return this.http.post<any>(this.base() + "get_shpped_orders", { query });
  }

  get_orders_to_be_packed_by_id(id: string) {
    return this.http.post<any>(this.base() + "get_orders_to_be_packed_by_id", { id });
  }

  get_shpped_orders_return(query: any) {
    return this.http.post<any>(this.base() + "get_shpped_orders_return", { query });
  }

  get_shpped_orders_return_by_id(id: string) {
    return this.http.post<any>(this.base() + "get_shpped_orders_return_by_id", { id });
  }

  add_member(val: any) {
    return this.http.put<any>(this.base() + "add_member", { val });
  }

  get_member_code() {
    return this.http.post<any>(this.base() + "get_member_code", { jwt: "jwt" });
  }

  membership_list_get(query: any) {
    return this.http.post<any>(this.base() + "membership_list_get", { query });
  }

  membership_list_get_popup(code: string) {
    return this.http.post<any>(this.base() + "membership_list_get_popup", { code });
  }

  membership_list_get_details(query: any) {
    return this.http.post<any>(this.base() + "membership_list_get_details", { query });
  }

  membership_list_get_nested(query: any) {
    return this.http.post<any>(this.base() + "membership_list_get_nested", { query });
  }

  sales_reports_person_wise_ToBeDispatch(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_person_wise_ToBeDispatch", { query });
  }

  sales_reports_person_wise_Dispatched(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_person_wise_Dispatched", { query });
  }

  sales_reports_person_wise_ToBeDispatch_popup(from: string, to: string, eid: number) {
    return this.http.post<any>(this.base() + "sales_reports_person_wise_ToBeDispatch_popup", {
      from,
      to,
      eid
    });
  }

  sales_reports_person_wise_Dispatched_popup(from: string, to: string, eid: number) {
    return this.http.post<any>(this.base() + "sales_reports_person_wise_Dispatched_popup", {
      from,
      to,
      eid
    });
  }

  get_restorant_new_item_category() {
    return this.http.post<any>(this.base() + 'get_restorant_new_item_category', { jwt: 'jwt' });
  }

  restorant_new_item_category_sub(id: string) {
    return this.http.post<any>(this.base() + 'restorant_new_item_category_sub', { id });
  }

  restorant_category_delete(id: string) {
    return this.http.post<any>(this.base() + 'restorant_category_delete', { id });
  }

  restorant_category_delete_sub(id: string) {
    return this.http.post<any>(this.base() + 'restorant_category_delete_sub', { id });
  }

  get_restorant_new_category_check(category: string) {
    return this.http.post<any>(this.base() + 'get_restorant_new_category_check', { category });
  }

  put_restorant_new_category(category: string) {
    return this.http.put<any>(this.base() + 'put_restorant_new_category', { category });
  }

  new_raw_item_check_main_category(name: string) {
    return this.http.post<any>(this.base() + 'new_raw_item_check_main_category', { name });
  }

  new_raw_item_check_sub_category(name: string, main: string) {
    return this.http.post<any>(this.base() + 'new_raw_item_check_sub_category', { name, main });
  }

  new_production_item_insert_sub_category(CategoryName: string, MainCategorySelectedItem: any) {
    return this.http.put<any>(this.base() + 'new_production_item_insert_sub_category', { CategoryName, MainCategorySelectedItem });
  }

  restorant_category_update(updateData: any) {
    return this.http.put<any>(this.base() + 'restorant_category_update', { updateData });
  }

  restorant_category_update_sub(updateData: any) {
    return this.http.put<any>(this.base() + 'restorant_category_update_sub', { updateData });
  }

  delete_product(id: string) {
    return this.http.post<any>(this.base() + 'delete_product', { id });
  }

  master_auto(search: string) {
    return this.http.post<any>(this.base() + "master_auto", { search });
  }

  get_master_by_id(id: number) {
    return this.http.post<any>(this.base() + "get_master_by_id", { id });
  }

  edit_product(data: any, id: string) {
    return this.http.post<any>(this.base() + "edit_product", { data, id });
  }

  insert_supplier(data: any, mCode: boolean) {
    return this.http.put<any>(this.base() + 'insert_supplier', { data, mCode });
  }

  get_supplier_code() {
    return this.http.post<any>(this.base() + 'get_supplier_code', { jwt: 'jwt' });
  }

  inset_grn(data: any, main: any) {
    return this.http.put<any>(this.base() + 'inset_grn', { data, main });
  }

  get_new_product_variation() {
    return this.http.post<any>(this.base() + 'get_new_product_variation', { jwt: 'jwt' });
  }

  get_petty_code(outlet: string) {
    return this.http.post<any>(this.base() + 'get_petty_code', { outlet });
  }

  get_petty_code_reimburse(outlet: string) {
    return this.http.post<any>(this.base() + 'get_petty_code_reimburse', { outlet });
  }

  new_product_variation_label(search: string) {
    return this.http.post<any>(this.base() + 'new_product_variation_label', { search });
  }

  new_product_variation_name(search: string) {
    return this.http.post<any>(this.base() + 'new_product_variation_name', { search });
  }

  put_new_product_variation(lable: string, name: string) {
    return this.http.put<any>(this.base() + 'put_new_product_variation', { lable, name });
  }

  getSetting(type: string) {
    return this.http.post<any>(this.base() + 'getSetting', { type });
  }

  add_petty_cash_account_check(acc: string) {
    return this.http.post<any>(this.base() + 'add_petty_cash_account_check', { acc });
  }

  petty_cash_add_account(account: string) {
    return this.http.put<any>(this.base() + 'petty_cash_add_account', { account });
  }

  petty_cash_get_account() {
    return this.http.post<any>(this.base() + 'petty_cash_get_account', {});
  }

  insert_petty_cash(data: any) {
    return this.http.put<any>(this.base() + 'insert_petty_cash', { data });
  }

  get_account_list() {
    return this.http.post<any>(this.base() + 'get_account_list', {});
  }

  update_account(updateData: any) {
    return this.http.put<any>(this.base() + 'update_account', { updateData });
  }

  delete_account(id: string) {
    return this.http.post<any>(this.base() + 'delete_account', { id });
  }

  insert_petty_cash_reimburse(data: any) {
    return this.http.put<any>(this.base() + 'insert_petty_cash_reimburse', { data });
  }

  account_petty_cash_reports(query: any) {
    return this.http.post<any>(this.base() + "account_petty_cash_reports", { query });
  }

  account_reimburse_reports(query: any) {
    return this.http.post<any>(this.base() + "account_reimburse_reports", { query });
  }

  account_petty_cash_statement(query: any) {
    return this.http.post<any>(this.base() + "account_petty_cash_statement", { query });
  }

  account_check_list(query: any) {
    return this.http.post<any>(this.base() + "account_check_list", { query });
  }

  account_check_list_update_status(status: string, id: string) {
    return this.http.post<any>(this.base() + "account_check_list_update_status", { status, id });
  }

  get_add_bills_code(outlet: string) {
    return this.http.post<any>(this.base() + 'get_add_bills_code', { outlet });
  }

  get_ap_service_supplier() {
    return this.http.post<any>(this.base() + 'get_ap_service_supplier', {});
  }

  insert_service_supplier(data: any) {
    return this.http.put<any>(this.base() + 'insert_service_supplier', { data });
  }

  get_service_supplier_code() {
    return this.http.post<any>(this.base() + 'get_service_supplier_code', {});
  }

  account_management_add_bills(data: any) {
    return this.http.put<any>(this.base() + 'account_management_add_bills', { data });
  }

  ap_get_bills_list(query: any) {
    return this.http.post<any>(this.base() + "ap_get_bills_list", { query });
  }

  ap_service_suppliers(query: any) {
    return this.http.post<any>(this.base() + "ap_service_suppliers", { query });
  }

  sales_reports_payment_wise(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_payment_wise", { query });
  }

  sales_reports_payment_wise_details(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_payment_wise_details", { query });
  }

  update_main_cat_pos(main_cat: any) {
    return this.http.post<any>(this.base() + 'update_main_cat_pos', { main_cat });
  }

  update_sub_cat_pos(sub_cat: any) {
    return this.http.post<any>(this.base() + 'update_sub_cat_pos', { sub_cat });
  }

  update_catogory_trends(value: string, id: string) {
    return this.http.post<any>(this.base() + 'update_catogory_trends', { value, id });
  }

  check_catogory_trends(cat: string, type: string) {
    return this.http.post<any>(this.base() + 'check_catogory_trends', { cat, type });
  }

  get_company_name() {
    return this.http.post<any>(this.base() + 'get_company_name', {});
  }

  settings_customer_mandatory() {
    return this.http.post<any>(this.base() + 'settings_customer_mandatory', {});
  }

  get_po_code(id: string) {
    return this.http.post<any>(this.base() + 'get_po_code', { id });
  }

  get_po_entry_product(search: string, id: string) {
    return this.http.post<any>(this.base() + "get_po_entry_product", { search, id });
  }

  inset_po(data: any, main: any) {
    return this.http.put<any>(this.base() + 'inset_po', { data, main });
  }

  get_poNo() {
    return this.http.post<any>(this.base() + 'get_poNo', {});
  }

  get_poNo_product(poNo: string) {
    return this.http.post<any>(this.base() + 'get_poNo_product', { poNo });
  }

  check_po(poNo: string) {
    return this.http.post<any>(this.base() + 'check_po', { poNo });
  }

  get_payment_voucher_code(id: string) {
    return this.http.post<any>(this.base() + 'get_payment_voucher_code', { id });
  }

  get_creditor(search: string) {
    return this.http.post<any>(this.base() + 'get_creditor', { search });
  }

  get_debtor(search: string) {
    return this.http.post<any>(this.base() + 'get_debtor', { search });
  }

  get_creditor_product(creditor: string) {
    return this.http.post<any>(this.base() + 'get_creditor_product', { creditor });
  }

  get_banks() {
    return this.http.post<any>(this.base() + 'get_banks', {});
  }

  insert_payment_voucher(data: any, main: any) {
    return this.http.put<any>(this.base() + 'insert_payment_voucher', { data, main });
  }

  getCountries() {
    return this.http.post<any>(this.base() + 'getCountries', {});
  }

  city_auto_list(search: string, country: string) {
    return this.http.post<any>(this.base() + "city_auto_list", { search, country });
  }

  get_sales_person_description() {
    return this.http.post<any>(this.base() + "get_sales_person_description", {});
  }

  get_product_code_advance() {
    return this.http.post<any>(this.base() + "get_product_code_advance", {});
  }

  get_variation1_number(code: string, id: string) {
    return this.http.post<any>(this.base() + "get_variation1_number", { code, id });
  }

  get_variation2_number(code: string, id: string) {
    return this.http.post<any>(this.base() + "get_variation2_number", { code, id });
  }

  get_variation3_number(code: string, id: string) {
    return this.http.post<any>(this.base() + "get_variation3_number", { code, id });
  }

  get_variation1_count(code: string) {
    return this.http.post<any>(this.base() + "get_variation1_count", { code });
  }

  get_variation2_count(code: string) {
    return this.http.post<any>(this.base() + "get_variation2_count", { code });
  }

  get_variation3_count(code: string) {
    return this.http.post<any>(this.base() + "get_variation3_count", { code });
  }

  get_head_office_products() {
    return this.http.post<any>(this.base() + "get_head_office_products", {});
  }

  get_head_office_products_edit() {
    return this.http.post<any>(this.base() + "get_head_office_products_edit", {});
  }

  head_office_products() {
    return this.http.put<any>(this.base() + "head_office_products", {});
  }

  head_office_products_edit() {
    return this.http.put<any>(this.base() + "head_office_products_edit", {});
  }

  add_sales_person(data: any) {
    return this.http.put<any>(this.base() + "add_sales_person", { data });
  }

  get_employee_from_uid(uid: any) {
    return this.http.post<any>(this.base() + "get_employee_from_uid", { uid });
  }

  get_invoice_return_no_list() {
    return this.http.post<any>(this.base() + "get_invoice_return_no_list", {});
  }

  supplier_list_get(query: any) {
    return this.http.post<any>(this.base() + "supplier_list_get", { query });
  }

  delete_supplier(id: string) {
    return this.http.post<any>(this.base() + "delete_supplier", { id });
  }

  update_supplier(data: any) {
    return this.http.post<any>(this.base() + "update_supplier", { data });
  }

  service_creditor(query: any) {
    return this.http.post<any>(this.base() + "service_creditor", { query });
  }

  service_creditor_pop(id: number) {
    return this.http.post<any>(this.base() + "service_creditor_pop", { id });
  }

  payment_voucher_supplier(sendData: any) {
    return this.http.put<any>(this.base() + "payment_voucher_supplier", { sendData });
  }

  brand_list() {
    return this.http.post<any>(this.base() + "brand_list", {});
  }

  brand_insert(data: any) {
    return this.http.put<any>(this.base() + "brand_insert", { data });
  }

  update_brand_pos(brand: any) {
    return this.http.post<any>(this.base() + 'update_brand_pos', { brand });
  }

  delete_brand(id: string) {
    return this.http.post<any>(this.base() + 'delete_brand', { id });
  }

  brand_update(updateData: any) {
    return this.http.put<any>(this.base() + 'brand_update', { updateData });
  }

  get_debtor_product(debtor: string) {
    return this.http.post<any>(this.base() + 'get_debtor_product', { debtor });
  }

  receipt_voucher_customer(sendData: any) {
    return this.http.put<any>(this.base() + "receipt_voucher_customer", { sendData });
  }

  get_non_loyalty_customer_list(search: string, page: number, limit: number) {
    return this.http.post<any>(this.base() + "get_non_loyalty_customer_list", {
      search,
      page,
      limit
    });
  }

  customer_type_list() {
    return this.http.post<any>(this.base() + "customer_type_list", {});
  }

  customer_type_update(ct: string, c: string) {
    return this.http.put<any>(this.base() + "customer_type_update", { updateData: { ct, c } });
  }

  get_cashbook_account_list() {
    return this.http.post<any>(this.base() + "get_cashbook_account_list", {});
  }

  cashbook_add_account(account: string, subaccount: string) {
    return this.http.put<any>(this.base() + 'cashbook_add_account', { account, subaccount });
  }

  cash_book_get_account() {
    return this.http.post<any>(this.base() + "cash_book_get_account", {});
  }

  get_cashbook_statement(query: any) {
    return this.http.post<any>(this.base() + "get_cashbook_statement", { query });
  }

  cashbook_insert(data: any) {
    return this.http.put<any>(this.base() + 'cashbook_insert', { data });
  }

  aged_analysis_creditor(query: any) {
    return this.http.post<any>(this.base() + "aged_analysis_creditor", { query });
  }

  aged_analysis_debtor(query: any) {
    return this.http.post<any>(this.base() + "aged_analysis_debtor", { query });
  }

  hourly_sale_mini_stat(type: any) {
    return this.http.post<any>(this.base() + "hourly_sale_mini_stat", { type });
  }

  today_discount_mini_stat(type: any) {
    return this.http.post<any>(this.base() + "today_discount_mini_stat", { type });
  }

  delivery_charges_mini_stat(type: any) {
    return this.http.post<any>(this.base() + "delivery_charges_mini_stat", { type });
  }

  product_sale_main_chart(type: any) {
    return this.http.post<any>(this.base() + "product_sale_main_chart", { type });
  }

  sale_return_mini_stat(type: any) {
    return this.http.post<any>(this.base() + "sale_return_mini_stat", { type });
  }

  barcode_print(query: any) {
    return this.http.post<any>(this.base() + "barcode_print", { query });
  }

  customer_barcode_get_list(query: any) {
    return this.http.post<any>(this.base() + "customer_barcode_get_list", { query });
  }

  get_print_supplier() {
    return this.http.post<any>(this.base() + "get_print_supplier", {});
  }

  mark_for_barcode_grn_print(rows: any) {
    return this.http.put<any>(this.base() + "mark_for_barcode_grn_print", { rows });
  }

  sales_reports_item_wise_tobedispatch(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_item_wise_tobedispatch", { query });
  }

  sales_reports_item_wise_dispatched(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_item_wise_dispatched", { query });
  }

  get_opening_stock_code(id: string) {
    return this.http.post<any>(this.base() + "get_opening_stock_code", { id });
  }

  insert_opening_stock(data: any, main: any) {
    return this.http.put<any>(this.base() + 'insert_opening_stock', { data, main });
  }

  get_opening_balance_cr_code(id: string) {
    return this.http.post<any>(this.base() + "get_opening_balance_cr_code", { id });
  }

  opening_balance_creditors() {
    return this.http.post<any>(this.base() + "opening_balance_creditors", {});
  }

  get_return_note_code(id: string) {
    return this.http.post<any>(this.base() + "get_return_note_code", { id });
  }

  return_note_invoice_no(search: string) {
    return this.http.post<any>(this.base() + 'return_note_invoice_no', { search });
  }

  return_note_get_table_from_invoice_no(invoice_no: string) {
    return this.http.post<any>(this.base() + 'return_note_get_table_from_invoice_no', { invoice_no });
  }

  return_note_customer_name(search: string) {
    return this.http.post<any>(this.base() + 'return_note_customer_name', { search });
  }

  return_note_invoice_wise(data: any, main: any) {
    return this.http.put<any>(this.base() + 'return_note_invoice_wise', { data, main });
  }

  return_note_item_wise(data: any, main: any) {
    return this.http.put<any>(this.base() + 'return_note_item_wise', { data, main });
  }

  get_return_customer_product(search: string) {
    return this.http.post<any>(this.base() + 'get_return_customer_product', { search });
  }

  return_supplier_grn(data: any, main: any) {
    return this.http.put<any>(this.base() + 'return_supplier_grn', { data, main });
  }

  return_note_grn_no(search: string) {
    return this.http.post<any>(this.base() + 'return_note_grn_no', { search });
  }

  return_note_get_table_from_grn_no(invoice_no: string) {
    return this.http.post<any>(this.base() + 'return_note_get_table_from_grn_no', { invoice_no });
  }

  return_supplier_item(data: any, main: any) {
    return this.http.put<any>(this.base() + 'return_supplier_item', { data, main });
  }

  return_note_supplier_name(search: string) {
    return this.http.post<any>(this.base() + 'return_note_supplier_name', { search });
  }

  insert_opening_balance_cr(data: any, main: any) {
    return this.http.put<any>(this.base() + 'insert_opening_balance_cr', { data, main });
  }

  opening_balance_Debtors() {
    return this.http.post<any>(this.base() + "opening_balance_Debtors", {});
  }

  insert_opening_balance_dr(data: any, main: any) {
    return this.http.put<any>(this.base() + 'insert_opening_balance_dr', { data, main });
  }

  return_check_product(qty: number, barcode: string) {
    return this.http.post<any>(this.base() + "return_check_product", { qty, barcode });
  }

  get_invoice_print_details(invoice_no: string) {
    return this.http.post<any>(this.base() + "get_invoice_print_details", { invoice_no });
  }

  report_management_grn_reports_details(query: any) {
    return this.http.post<any>(this.base() + "report_management_grn_reports_details", { query });
  }

  report_management_stock_transfer_details(query: any) {
    return this.http.post<any>(this.base() + "report_management_stock_transfer_details", { query });
  }

  report_management_stock_receive_details(query: any) {
    return this.http.post<any>(this.base() + "report_management_stock_receive_details", { query });
  }

  doughnut_invoices(type: string) {
    return this.http.post<any>(this.base() + "doughnut_invoices", { type });
  }

  doughnut_invoice_cancelled(type: string) {
    return this.http.post<any>(this.base() + "doughnut_invoice_cancelled", { type });
  }

  doughnut_customers(type: string) {
    return this.http.post<any>(this.base() + "doughnut_customers", { type });
  }

  doughnut_products(type: string) {
    return this.http.post<any>(this.base() + "doughnut_products", { type });
  }

  doughnut_to_be_dispatch_count(type: string) {
    return this.http.post<any>(this.base() + "doughnut_to_be_dispatch_count", { type });
  }

  doughnut_dispatch_count(type: string) {
    return this.http.post<any>(this.base() + "doughnut_dispatch_count", { type });
  }

  doughnut_deliveries(type: string) {
    return this.http.post<any>(this.base() + "doughnut_deliveries", { type });
  }

  get_service_creditor(search: any) {
    return this.http.post<any>(this.base() + "get_service_creditor", { search });
  }

  get_service_creditor_product(id: string) {
    return this.http.post<any>(this.base() + "get_service_creditor_product", { id });
  }

  payment_voucher_bill(sendData: any) {
    return this.http.put<any>(this.base() + "payment_voucher_bill", { sendData });
  }

  payment_vouchers_bills_print_row(code: string) {
    return this.http.put<any>(this.base() + "payment_vouchers_bills_print_row", { code });
  }

  payment_vouchers_bills_pop(id: number) {
    return this.http.post<any>(this.base() + "payment_vouchers_bills_pop", { id });
  }

  invoice_insert_trip_location_batch(locations: any) {
    return this.http.put<any>(this.base() + "invoice_insert_trip_location_batch", { locations });
  }

  stock_location_warehouse_cards(query: any) {
    return this.http.post<any>(this.base() + "stock_location_warehouse_cards", { query });
  }

  stock_location_warehouse_insert(data: any) {
    return this.http.put<any>(this.base() + 'stock_location_warehouse_insert', { data });
  }

  stock_location_warehouse_update(data: any, id: number) {
    return this.http.post<any>(this.base() + "stock_location_warehouse_update", { data, id });
  }

  stock_location_delete(id: string) {
    return this.http.post<any>(this.base() + 'stock_location_delete', { id });
  }

  stock_location_bin_cards_warehouse_list() {
    return this.http.post<any>(this.base() + 'stock_location_bin_cards_warehouse_list', {});
  }

  stock_location_bin_cards(query: any) {
    return this.http.post<any>(this.base() + "stock_location_bin_cards", { query });
  }

  stock_location_bin_insert(data: any) {
    return this.http.put<any>(this.base() + 'stock_location_bin_insert', { data });
  }

  stock_location_bin_update(data: any, id: number) {
    return this.http.post<any>(this.base() + "stock_location_bin_update", { data, id });
  }

  stock_location_bin_delete(id: string) {
    return this.http.post<any>(this.base() + 'stock_location_bin_delete', { id });
  }

  get_print_basics() {
    return this.http.post<any>(this.base() + 'get_print_basics', {});
  }

  grn_location_get(search: string, id: string) {
    return this.http.post<any>(this.base() + "grn_location_get", { search, id });
  }

  return_reports_customer_bill_wise_summary(query: any) {
    return this.http.post<any>(this.base() + "return_reports_customer_bill_wise_summary", { query });
  }

  return_reports_customer_bill_wise_details(query: any) {
    return this.http.post<any>(this.base() + "return_reports_customer_bill_wise_details", { query });
  }

  return_reports_customer_item_wise_summary(query: any) {
    return this.http.post<any>(this.base() + "return_reports_customer_item_wise_summary", { query });
  }

  return_reports_customer_item_wise_details(query: any) {
    return this.http.post<any>(this.base() + "return_reports_customer_item_wise_details", { query });
  }

  return_reports_customer_bill_wise_summary_popup(id: number) {
    return this.http.post<any>(this.base() + "return_reports_customer_bill_wise_summary_popup", { id });
  }

  return_reports_customer_item_wise_popup(id: number) {
    return this.http.post<any>(this.base() + "return_reports_customer_item_wise_popup", { id });
  }

  product_main_cat() {
    return this.http.post<any>(this.base() + "product_main_cat", {});
  }

  product_sub_cat(cat: string) {
    return this.http.post<any>(this.base() + "product_sub_cat", { cat });
  }

  sales_reports_category_wise(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_category_wise", { query });
  }

  sales_reports_category_wise_popup(to: any, from: any, id: number) {
    return this.http.post<any>(this.base() + "sales_reports_category_wise_popup", { to, from, id });
  }

  return_reports_supplier_grn_wise_summary(query: any) {
    return this.http.post<any>(this.base() + "return_reports_supplier_grn_wise_summary", { query });
  }

  return_reports_supplier_grn_wise_details(query: any) {
    return this.http.post<any>(this.base() + "return_reports_supplier_grn_wise_details", { query });
  }

  return_reports_supplier_item_wise_summary(query: any) {
    return this.http.post<any>(this.base() + "return_reports_supplier_item_wise_summary", { query });
  }

  return_reports_supplier_item_wise_details(query: any) {
    return this.http.post<any>(this.base() + "return_reports_supplier_item_wise_details", { query });
  }

  return_reports_supplier_grn_wise_summary_popup(id: number) {
    return this.http.post<any>(this.base() + "return_reports_supplier_grn_wise_summary_popup", { id });
  }

  return_reports_supplier_item_wise_popup(id: number) {
    return this.http.post<any>(this.base() + "return_reports_supplier_item_wise_popup", { id });
  }

  dispatch_note_courier_name(search: string) {
    return this.http.post<any>(this.base() + 'dispatch_note_courier_name', { search });
  }

  new_dispatch_get_invoice(search: string) {
    return this.http.post<any>(this.base() + "new_dispatch_get_invoice", { search });
  }

  new_dispatch_insert(data: any, main: any) {
    return this.http.put<any>(this.base() + 'new_dispatch_insert', { data, main });
  }

  get_new_dispatch_code(id: string) {
    return this.http.post<any>(this.base() + "get_new_dispatch_code", { id });
  }

  insert_courier(data: any) {
    return this.http.put<any>(this.base() + 'insert_courier', { data });
  }

  get_new_courier_code(id: string) {
    return this.http.post<any>(this.base() + "get_new_courier_code", { id });
  }

  courier_list_get(query: any) {
    return this.http.post<any>(this.base() + "courier_list_get", { query });
  }

  delete_courier(id: string) {
    return this.http.post<any>(this.base() + 'delete_courier', { id });
  }

  update_courier(data: any) {
    return this.http.post<any>(this.base() + "update_courier", { data });
  }

  dispatch_report_bill_wise_summary(query: any) {
    return this.http.post<any>(this.base() + "dispatch_report_bill_wise_summary", { query });
  }

  dispatch_report_bill_wise_summary_popup(id: number) {
    return this.http.post<any>(this.base() + "dispatch_report_bill_wise_summary_popup", { id });
  }

  get_dispatch_print_details(invoice_no: string) {
    return this.http.post<any>(this.base() + 'get_dispatch_print_details', { invoice_no });
  }

  cancellation_report_summary(query: any) {
    return this.http.post<any>(this.base() + "cancellation_report_summary", { query });
  }

  stock_view_Product_Code_popup(id: number) {
    return this.http.post<any>(this.base() + "stock_view_Product_Code_popup", { id });
  }

  stock_view_GRN_Qty_popup(query: any) {
    return this.http.post<any>(this.base() + "stock_view_GRN_Qty_popup", { query });
  }

  aged_analysis_grn(query: any) {
    return this.http.post<any>(this.base() + "aged_analysis_grn", { query });
  }

  get_dispatch_print_details_multi(id: string) {
    return this.http.post<any>(this.base() + "get_dispatch_print_details_multi", { id });
  }

  get_receipt_return_no_list(id: string) {
    return this.http.post<any>(this.base() + "get_receipt_return_no_list", { id });
  }

  price_tag_get_products(search: string, type: any) {
    return this.http.post<any>(this.base() + "price_tag_get_products", {
      search,
      type
    });
  }

  get_warehouse() {
    return this.http.post<any>(this.base() + "get_warehouse", {});
  }

  get_bin(id: any) {
    return this.http.post<any>(this.base() + "get_bin", { id });
  }

  price_tag_insert(data: any) {
    return this.http.put<any>(this.base() + "price_tag_insert", { data });
  }

  price_tag_list(query: any) {
    return this.http.post<any>(this.base() + "price_tag_list", { query });
  }

  price_tag_delete(id: string) {
    return this.http.post<any>(this.base() + 'price_tag_delete', { id });
  }

  price_tag_update(data: string) {
    return this.http.post<any>(this.base() + 'price_tag_update', { data });
  }

  product_price_tag(sid: number) {
    return this.http.post<any>(this.base() + 'product_price_tag', { sid });
  }

  current_stock_get_bin(id: string) {
    return this.http.post<any>(this.base() + 'current_stock_get_bin', { id });
  }

  current_stock_get_warehouse() {
    return this.http.post<any>(this.base() + 'current_stock_get_warehouse', {});
  }

  invoice_get_warehouse() {
    return this.http.post<any>(this.base() + 'invoice_get_warehouse', {});
  }

  bill_wise_payment_type() {
    return this.http.post<any>(this.base() + 'bill_wise_payment_type', {});
  }

  get_sales_order_no(val: string) {
    return this.http.post<any>(this.base() + 'get_sales_order_no', { val });
  }

  sales_order_customer_list_auto(search: string) {
    return this.http.post<any>(this.base() + 'sales_order_customer_list_auto', { search });
  }

  sales_order_get_product_list(search: string) {
    return this.http.post<any>(this.base() + 'sales_order_get_product_list', { search });
  }

  insert_sales_order(data: any, info: any) {
    return this.http.put<any>(this.base() + "insert_sales_order", { data, info });
  }

  get_stock_take_code(id: string) {
    return this.http.post<any>(this.base() + 'get_stock_take_code', { id });
  }

  new_stock_take_barcode(serial: string, id: string) {
    return this.http.post<any>(this.base() + 'new_stock_take_barcode', { id, serial });
  }

  insert_new_stock_take(data: any, main: any) {
    return this.http.put<any>(this.base() + 'insert_new_stock_take', { data, main });
  }

  get_new_stock_take_product(search: string, sid: string, wid: string) {
    return this.http.post<any>(this.base() + "get_new_stock_take_product", { search, sid, wid });
  }

  inter_location_transfer_from() {
    return this.http.post<any>(this.base() + 'inter_location_transfer_from', {});
  }

  inter_location_transfer_get_item(search: string, id: string) {
    return this.http.post<any>(this.base() + "inter_location_transfer_get_item", { search, id });
  }

  check_product_barcode(code: number) {
    return this.http.post<any>(this.base() + "check_product_barcode", { code });
  }

  get_inter_location_transfer_outlets() {
    return this.http.post<any>(this.base() + "get_inter_location_transfer_outlets", {});
  }

  sales_reports_sales_orders_summery(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_sales_orders_summery", { query });
  }

  sales_reports_sales_orders_summery_popup(id: number) {
    return this.http.post<any>(this.base() + "sales_reports_sales_orders_summery_popup", { id });
  }

  sales_reports_sales_orders_details(query: any) {
    return this.http.post<any>(this.base() + "sales_reports_sales_orders_details", { query });
  }

  sales_reports_sales_orders_print(oid: any) {
    return this.http.post<any>(this.base() + "sales_reports_sales_orders_print", { oid });
  }

  check_new_supplier_code(code: any) {
    return this.http.post<any>(this.base() + "check_new_supplier_code", { code });
  }

  stock_adjustment_sale_price_get_products(search: string, warehouse: any) {
    return this.http.post<any>(this.base() + 'stock_adjustment_sale_price_get_products', { search, warehouse });
  }

  stock_adjustment_sale_price_get_warehouse() {
    return this.http.post<any>(this.base() + 'stock_adjustment_sale_price_get_warehouse', {});
  }

  put_stock_management_sale_price(data: any) {
    return this.http.put<any>(this.base() + "put_stock_management_sale_price", { data });
  }

}