import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentVoucheDialogComponent } from '../report-management/payment-vouche-dialog/payment-vouche-dialog.component';
import { GetServerDataServiceService } from '../get-server-data-service.service';

@Component({
  selector: 'app-payment-vouche-bill-dialog',
  templateUrl: './payment-vouche-bill-dialog.component.html',
  styleUrls: ['./payment-vouche-bill-dialog.component.css']
})
export class PaymentVoucheBillDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PaymentVoucheDialogComponent>,
    private server: GetServerDataServiceService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  @Output() subItemSelected = new EventEmitter();

  tableList = [];

  selectedRowsByID = [];

  selectAllModel = false;

  displayedColumns = [
    'Code',
    'Date',
    'Net Amount',
    'Paid Amount',
    'Credit Amount',
    'Remarks'
  ];

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.server.payment_vouchers_bills_pop(this.data.id)
      .subscribe(res => {
        console.log(res);
        this.tableList = res;
      });
  }

}
