<h1>{{sectionType.replace('html','')}}</h1>

<div class="container">

    <div *ngFor="let item of sectionHtml" (click)="test(item)" class="sectionHolder">
        <div *ngIf="item.img">
            <img [src]="item.img + '.jpg?' + item.size | authImage | async" alt="item.disc" />
        </div>
        <div class="textContainer">
            <input matInput [placeholder]="item.h1" [(ngModel)]="item.h1" (click)="$event.target.select()">
            <textarea [(ngModel)]="item.disc" (click)="$event.target.select()"></textarea>
        </div>
    </div>

</div>

<button mat-raised-button color="primary" class="save" (click)="save()">Save</button>