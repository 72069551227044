import { Component, OnInit, ElementRef, Input } from '@angular/core';
import {
  IChartistAnimationOptions,
  IChartistData,
  ILineChartOptions
} from 'chartist';
import { ChartEvent, ChartType } from 'ng-chartist';
import { WindowEmitorsService } from 'src/app/window-emitors.service';
import * as ChartistTooltip from 'chartist-plugin-tooltips-updated';
import { GetServerDataServiceService } from 'src/app/get-server-data-service.service';

@Component({
  selector: 'app-charist-demo-chart',
  templateUrl: './charist-demo-chart.component.html',
  styleUrls: ['./charist-demo-chart.component.css']
})
export class CharistDemoChartComponent implements OnInit {

  constructor(
    private el: ElementRef,
    private server: GetServerDataServiceService,
    private windowEmitors: WindowEmitorsService
  ) {
    windowEmitors.sideBarToggled$.subscribe((e: number) => {
      this.setDefaultOptions(300);
      setTimeout(() => {
        this.setDefaultOptions(this.el.nativeElement.offsetWidth);
      }, 100);
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setDefaultOptions(this.el.nativeElement.offsetWidth);
    }, 100);
    this.loadData();
  }

  type: ChartType = 'Line';

  Dtype = "TodayvsYesterday";

  chartALable = "Today";
  chartBLable = "Yesterday";

  data: IChartistData = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ],
    series: [
      [0, 0],
      [0, 0]
    ]
  };

  dofw(i: number) {
    return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][i] || '';
  }

  mofy(i: number) {
    return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Agu", "Sep", "Oct", "Nov", "Dec"][i - 1] || '';
  }

  loadData() {
    if (this.Dtype === 'TodayvsYesterday') {
      this.chartALable = "Today";
      this.chartBLable = "Yesterday";
    } else if (this.Dtype === 'ThisWeekvsLastWeek') {
      this.chartALable = "This Week";
      this.chartBLable = "Last Week";
    } else if (this.Dtype === 'ThisMonthvsLastmonth') {
      this.chartALable = "This Month";
      this.chartBLable = "Last Month";
    } else if (this.Dtype === 'ThisYearvsLastYear') {
      this.chartALable = "This Year";
      this.chartBLable = "Last Year";
    }
    this.server.product_sale_main_chart(this.chartALable).subscribe((res: any) => {
      this.server.product_sale_main_chart(this.chartBLable).subscribe((res2: any) => {
        let chartA = [0, 0];
        let chartB = [0, 0];
        let chartAx = [];
        let chartBx = [];

        if (res.length > 0) {
          chartA = res.map((e: any) => e.value);
          if (this.Dtype === 'ThisMonthvsLastmonth') {
            chartAx = res.map((e: any) => Number(e.x.split('-')[2]));
          } else {
            chartAx = res.map((e: any) => e.x);
          }
        }

        if (chartAx.length === 0) {
          chartAx = [0];
        }

        if (chartBx.length === 0) {
          chartBx = [0];
        }

        if (res2.length > 0) {
          chartB = res2.map((e: any) => e.value);
          if (this.Dtype === 'ThisMonthvsLastmonth') {
            chartBx = res2.map((e: any) => Number(e.x.split('-')[2]));
          } else {
            chartBx = res2.map((e: any) => e.x);
          }
        }

        let chartAmin = Math.min(...chartAx), chartAmax = Math.max(...chartAx);
        let chartBmin = Math.min(...chartBx), chartBmax = Math.max(...chartBx);

        let chartRangeMin = Math.min(chartAmin, chartBmin);
        let chartRangeMax = Math.max(chartAmax, chartBmax);

        let lables = new Array(chartRangeMax - chartRangeMin + 1).fill(undefined).map((e: number, i: number) => chartRangeMin + i);

        let chartAFix = lables.map(e => {
          let f_index = 0;

          if (this.Dtype === 'ThisMonthvsLastmonth') {
            f_index = res.findIndex((f: any) => Number(f.x.split('-')[2]) === e);
          } else {
            f_index = res.findIndex((f: any) => f.x === e);
          }

          if (f_index >= 0) {
            return res[f_index].value
          } else {
            return 0;
          }
        });

        let chartBFix = lables.map(e => {
          let f_index = 0;

          if (this.Dtype === 'ThisMonthvsLastmonth') {
            f_index = res2.findIndex((f: any) => Number(f.x.split('-')[2]) === e);
          } else {
            f_index = res2.findIndex((f: any) => f.x === e);
          }

          if (f_index >= 0) {
            return res2[f_index].value
          } else {
            return 0;
          }
        });

        if (this.Dtype === 'ThisWeekvsLastWeek') {
          lables = this.shiftArray(res2.map((e: any) => this.dofw(e.x)));
          chartBFix = this.shiftArray(chartBFix);
          chartAFix = this.shiftArray(chartAFix);
        }

        if (this.Dtype === 'ThisYearvsLastYear') {
          lables = res2.map((e: any) => this.mofy(e.x));
        }

        this.data = {
          labels: lables,
          series: [
            chartBFix,
            chartAFix
          ]
        }
      });
    });

  }

  shiftArray(array: any) {
    const a = array;
    const last = a.shift();
    a.push(last);
    return a;
  }

  options: ILineChartOptions = {
    axisX: {
      showGrid: false
    },
    axisY: {
      labelInterpolationFnc: (value) => {
        return this.transform(value)
      },
    },
    height: 300,
    width: 200,
    showArea: true,
    showPoint: true,
    low: 0,
    fullWidth: true,
    plugins: [
      ChartistTooltip({
        anchorToPoint: true,
        appendToBody: true,
        currency: 'Rs.',
      })
    ]
  };

  setDefaultOptions(width: number) {
    this.options = {
      axisX: {
        showGrid: false
      },
      axisY: {
        labelInterpolationFnc: (value) => {
          return this.transform(value)
        },
      },
      height: 300,
      width: width - 30,
      showArea: true,
      showPoint: true,
      low: 0,
      fullWidth: true
    };
  }

  events: ChartEvent = {
    draw: (data) => {
      if (data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 500 * data.index,
            dur: 500,
            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
            to: data.path.clone().stringify(),
            easing: 'easeOutQuint'
          }
        });
      }
    }
  };

  test(num: number) {
    return 'Rs.' + num;
  }

  transform = (number: number) => {
    if (isNaN(number)) return null; // will only work value is a number
    if (number === null) return null;
    if (number === 0) return null;
    let abs = Math.abs(number);
    const rounder = Math.pow(10, 1);
    const isNegative = number < 0; // will also work for Negetive numbers
    let key = '';

    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 }
    ];

    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    return (isNegative ? '-' : '') + abs + key;
  }

}
