import { Component, OnInit } from '@angular/core';
import { CookieService } from 'src/app/cookie.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'fix-image-btn',
  templateUrl: './fix-image-btn.component.html',
  styleUrls: ['./fix-image-btn.component.css']
})
export class FixImageBtnComponent implements OnInit {

  constructor(
    private cookies: CookieService,
    private toolTip: MatSnackBar
  ) { }

  jwt = this.cookies.getCookie('jwt');
  isFixed = false;

  ngOnInit(): void {
    this.isFixed = this.jwt === localStorage.jwt;
  }

  fixImages(): void {
    if (this.jwt === localStorage.jwt) {
      this.toolTip.open("Already Fixed", "Ok", { duration: 3000 });
    } else {
      this.cookies.setCookie('jwt', localStorage.jwt, 30);
      location.reload();
    }
  }

}
