<div class="chart" fxLayout="column">
  <div fxFlex class="menu_btn" fxLayout="row">
    <div fxFlex></div>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
  <h2 fxFlex [ngStyle]="{'color': color}">{{heading}}</h2>
  <h1>{{number2}}</h1>
  <x-chartist class="chartArea" [type]="type" [data]="data" [options]="options" [events]="events"></x-chartist>
  <mat-icon class="icon" [ngStyle]="{'color': color}" aria-hidden="true">{{icon}}</mat-icon>
  <h3>{{number1}}</h3>
  <h4 [ngStyle]="{'color': color}">{{selected}} {{subHeading}}</h4>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="changeSelected('Today')">
      <span>Today</span>
    </button>
    <button mat-menu-item (click)="changeSelected('Yesterday')">
      <span>Yesterday</span>
    </button>
    <button mat-menu-item (click)="changeSelected('This Week')">
      <span>This Week</span>
    </button>
    <button mat-menu-item (click)="changeSelected('Last Week')">
      <span>Last Week</span>
    </button>
    <button mat-menu-item (click)="changeSelected('This Month')">
      <span>This Month</span>
    </button>
    <button mat-menu-item (click)="changeSelected('Last Month')">
      <span>Last Month</span>
    </button>
  </mat-menu>

</div>