<div class="paper">
	<div class="sticky">
		<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="0.5em" class="borderSpacing">
			<p fxFlex style="text-align: center;"> Print View : {{title}}</p>
			<button mat-raised-button color="primary" (click)="print()" fxFlex>Print</button>
		</div>
	</div>

	<div *ngFor="let table of tables" class="table">

		<div class="hedCols">
			<span *ngFor="let hed of headCols">
				<strong>{{hed}}</strong> : {{table[hed]}}
			</span>
		</div>

		<div fxLayout="row">
			<table mat-table [dataSource]="table.table" *ngIf="table.disply" fxFlex>

				<ng-container [matColumnDef]="col" *ngFor="let col of nestedTableColomns">
					<th mat-header-cell *matHeaderCellDef>{{col}}</th>
					<td mat-cell *matCellDef="let element" [class]="colClass(col)">
						{{format(col, element[col])}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="nestedTableColomns"></tr>
				<tr mat-row *matRowDef="let row; columns:
				nestedTableColomns;"></tr>
			</table>
		</div>

		<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em" class="subFooter">
			<p *ngFor="let i of table.footer | keyvalue"><strong>{{i.value.name}}</strong> :
				{{i.value.value | currency: " "}}</p>
		</div>

	</div>

	<footer fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1em">
		<p *ngFor="let i of footer | keyvalue"><strong *ngIf="i.value !== ''">{{i.key}} : </strong>{{i.value }}</p>
	</footer>
	<div style="height: 5em;" class="print_hide">

	</div>
</div>