<div fxLayout="row">
  <mat-form-field fxFlex>
    <mat-select [(ngModel)]="ExportType" (selectionChange)="setType()" placeholder="Select Format">
      <mat-option value="xls">Excel (*.xls)</mat-option>
      <mat-option value="pdf">PDF (*.pdf)</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div fxLayout="row">
  <mat-form-field fxFlex>
    <mat-select [(ngModel)]="range" (selectionChange)="setLimit()" placeholder="Select Range">
      <mat-option value="all">All</mat-option>
      <mat-option value="selected">Selected</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div fxLayout="row">
  <p style="text-align: justify">Output will consist about <strong>{{data.query.limit}}</strong> results
    <span *ngIf="data.query.search!==''">the search tearm <strong>"{{data.query.search}}".</strong></span></p>
</div>

<div fxLayout="row">
  <button mat-raised-button color="primary" (click)="save()" fxFlex>Save</button>
</div>
