import { Component, OnInit, Inject } from '@angular/core';
import { GetServerDataServiceService } from 'src/app/get-server-data-service.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-brand-list-edit',
  templateUrl: './brand-list-edit.component.html',
  styleUrls: ['./brand-list-edit.component.css']
})
export class BrandListEditComponent implements OnInit {

  constructor(
    private server: GetServerDataServiceService,
    public dialogRef: MatDialogRef<BrandListEditComponent>,
    private toolTip: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  name = this.data.item.name;
  remarks = this.data.item.remarks;
  valid = true;

  ngOnInit() {
    this.CategoryNaameCheck();
  }

  CategoryNaameCheck() {
    this.server.get_restorant_new_category_check(this.name)
      .pipe(debounceTime(1000))
      .subscribe(res => {
        this.valid = res.length === 0;
      });
  }

  update() {

    const sendUpdate = {
      name: this.name,
      remarks: this.remarks,
      id: this.data.item.id
    };

    this.server.brand_update(sendUpdate)
      .subscribe(res => {
        console.log(res);
        this.toolTip.open('Category Created!', 'Ok', { duration: 3000 });
        this.dialogRef.close();
      });
  }

}
