<div mat-dialog-content>
	<div fxLayout="row">
		<table mat-table [dataSource]="tableList" fxFlex>

			<ng-container matColumnDef="Code">
				<th mat-header-cell *matHeaderCellDef>Code</th>
				<td mat-cell *matCellDef="let element"> {{element['Code']}} </td>
			</ng-container>
			<ng-container matColumnDef="Date">
				<th mat-header-cell *matHeaderCellDef>Date</th>
				<td mat-cell *matCellDef="let element"> {{element['Date'] | date}} </td>
			</ng-container>
			<ng-container matColumnDef="Net Amount">
				<th mat-header-cell *matHeaderCellDef>Net Amount</th>
				<td mat-cell *matCellDef="let element"> {{element['Net Amount']}} </td>
			</ng-container>
			<ng-container matColumnDef="Paid Amount">
				<th mat-header-cell *matHeaderCellDef>Paid Amount</th>
				<td mat-cell *matCellDef="let element"> {{element['Paid Amount']}} </td>
			</ng-container>
			<ng-container matColumnDef="Credit Amount">
				<th mat-header-cell *matHeaderCellDef>Credit Amount</th>
				<td mat-cell *matCellDef="let element"> {{element['Credit Amount']}} </td>
			</ng-container>
			<ng-container matColumnDef="Remarks">
				<th mat-header-cell *matHeaderCellDef>Remarks</th>
				<td mat-cell *matCellDef="let element"> {{element['Remarks']}} </td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>
</div>