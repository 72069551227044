import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Subject, Observable } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { WindowEmitorsService } from 'src/app/window-emitors.service';
import { NewCustomerComponent } from 'src/app/customer-management/new-customer/new-customer.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: "app-customer-auto",
  templateUrl: "./customer-auto.component.html",
  styleUrls: ["./customer-auto.component.css"]
})
export class CustomerAutoComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private windowEmitors: WindowEmitorsService,
    private toolTip: MatSnackBar) {
    this.autoUpdate
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
      });
  }

  @Input() options: Observable<any>;
  @Input() placeholder: any;
  @Input() key: string;
  @Input() AutoValue: string;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();
  @Output() finalValue = new EventEmitter();

  valueChanged() {
    this.finalValue.emit(this.AutoValue);
  }

  enter() {
    this.options.subscribe(res => {
      if (res.length === 1) {
        console.log(res[0]);
        this.AutoValue = res[0];
        this.finalValue.emit(res[0]);
      }
    });
  }

  displayFn(id: any) {
    if (!id) {
      return "";
    }
    return id.customer;
  }

  clear() {
    this.AutoValue = "";
  }

  ngOnInit() {

  }

  addNewCustomer(event: any): void {
    event.stopPropagation();
    const selectedRowsByIDdialog = this.dialog.open(NewCustomerComponent, {
      width: "500px",
      height: "90vh",
    });
  }
}
