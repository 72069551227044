<div mat-dialog-content>
	<div fxLayout="row">
		<table mat-table [dataSource]="tableList" fxFlex>

			<ng-container matColumnDef="_id">
				<th mat-header-cell *matHeaderCellDef>#</th>
				<td mat-cell *matCellDef="let element"> {{element['_id']}} </td>
			</ng-container>
			<ng-container matColumnDef="Product Code">
				<th mat-header-cell *matHeaderCellDef>Product Code</th>
				<td mat-cell *matCellDef="let element"> {{element['Product Code']}} </td>
			</ng-container>
			<ng-container matColumnDef="Product Description">
				<th mat-header-cell *matHeaderCellDef>Product Description</th>
				<td mat-cell *matCellDef="let element"> {{element['Product Description']}} </td>
			</ng-container>
			<ng-container matColumnDef="Sold Qty">
				<th mat-header-cell *matHeaderCellDef class="align-right">Sold Qty</th>
				<td mat-cell *matCellDef="let element" class="number"> {{element['Sold Qty']}} </td>
			</ng-container>
			<ng-container matColumnDef="Discount">
				<th mat-header-cell *matHeaderCellDef class="align-right">Discount</th>
				<td mat-cell *matCellDef="let element" class="number"> {{element['Discount']}} </td>
			</ng-container>
			<ng-container matColumnDef="Sale Price">
				<th mat-header-cell *matHeaderCellDef class="align-right">Sale Price</th>
				<td mat-cell *matCellDef="let element" class="number"> {{element['Sale Price']}} </td>
			</ng-container>
			<ng-container matColumnDef="Amount">
				<th mat-header-cell *matHeaderCellDef class="align-right">Amount</th>
				<td mat-cell *matCellDef="let element" class="number"> {{element['Amount'] }} </td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</div>
</div>