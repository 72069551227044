import { Component, OnInit, ViewChild, Input, Output, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-product-auto-return',
  templateUrl: './product-auto-return.component.html',
  styleUrls: ['./product-auto-return.component.css']
})
export class ProductAutoReturnComponent implements OnInit {

  
  constructor() {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
      });
  }

  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutoComplit: MatAutocompleteTrigger;

  @Input() options: any;
  @Input() placeholder: any;
  @Input() key: string;
  @Input() AutoValue: string;
  @Input() disabled: boolean;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();
  @Output() finalValue = new EventEmitter();
  @Output() enterPressed = new EventEmitter();

  @HostBinding('tabindex') tabindex = 0;

  @HostListener('focus')

  focusHandler() {
    const input: any = document.querySelectorAll('.autoProduct');
    setTimeout(() => {
      input[input.length- 1].focus();
    }, 100);
  }

  openPanel(): void {
    this.inputAutoComplit.openPanel();
  }

  valueChanged() {
    this.finalValue.emit(this.AutoValue);
  }

  displayFn(id) {
    if (!id) { return '' }
    return id.product;
  }

  enter(event:any): void {
    this.enterPressed.emit(event.path);
  }


  ngOnInit() {
    // console.log(this.options);
  }

}
