import { GetServerDataServiceService } from './../get-server-data-service.service';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.css']
})
export class ActivateAccountComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private server: GetServerDataServiceService) { }

  loading = false;
  dsblSubmit = false;
  hide = true;

  activateUser = this.fb.group({
    code: [null, Validators.required],
    emailAddress: [null, Validators.compose([Validators.required, Validators.email])]
  });

  ngOnInit() {
    if (localStorage.getItem('jwt') !== null) {
      // this.router.navigate(['/main/dashboard']);
    }
  }

  sendVerificationCode() {
    this.server.send_verification_email()
      .subscribe(res => {
        console.log(res);
      })
  }

  goback() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("uid");
    localStorage.removeItem("username");
    localStorage.removeItem("branch");
    localStorage.removeItem("access");
    localStorage.removeItem('verified');
    this.router.navigate(["/login"]);
  }

  activate() {
    this.server.activate_user(this.activateUser.controls.emailAddress.value, this.activateUser.controls.code.value)
      .subscribe(res => {
        if(Number(res.affectedRows)){
          localStorage.setItem('verified', '1');
          this.router.navigate(['/main/dashboard']);
        }
      });
  }

}

