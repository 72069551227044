<div class="bg">
	<div class="page">
		<div class="content">

			<div class="header" fxLayout="row">
				<div fxFlex>
					<img [src]="base + 'image/logo_sq.jpg' | authImage | async" style="width: 10em;">
				</div>
				<div fxFlex>
					<h2 style="text-align: center;">Dispatch Note</h2>
					<p style="text-align: center;">[CASH ON DELIVERY]</p>
				</div>
				<div fxFlex>

				</div>
			</div>

			<div fxLayout="row">
				<div fxLayout="column" fxLayoutAlign="start start">
					<p><strong>Customer </strong> : {{tables[0].table[0].Customer}}</p>
					<p><strong>Contact No </strong> : {{tables[0].table[0].CONTACT_NO}}</p>
					<p><strong>Deliver to </strong> : {{tables[0].table[0]['Deliver to']}}</p>
					<p><strong>Dispatch No </strong> : {{tables[0].table[0]['Dispatch No']}}</p>
				</div>
				<div style="width: 5em;"></div>
				<div fxFlex fxLayout="column" fxLayoutAlign="start start">
					<p><strong>Invoice No </strong> : {{tables[0].table[0]['Bill No']}}</p>
					<p><strong>Invoice Date </strong> : {{tables[0].table[0]['INVOICE_DATE']}}</p>
					<p><strong>Tracking No </strong> : {{tables[0].table[0]['Tracking No']}}</p>
					<p><strong>Invoice By </strong> : {{tables[0].table[0]['Cashier']}}</p>
				</div>
			</div>

			<br>

			<div *ngFor="let table of tables" class="table">

				<table style="width:100%">
					<tr>
						<th>#</th>
						<th style="text-align: left;">Product Description</th>
						<th class="number">Qty</th>
						<th class="number">Unit Price</th>
						<th class="number">Discount</th>
						<th class="number">Amount</th>
					</tr>
					<tr *ngFor="let col of table.table">
						<td>{{col['#']}}</td>
						<td>{{col['Product Description']}}</td>
						<td class="number">{{col['Qty']}}</td>
						<td class="number">{{col['Unit Price']}}</td>
						<td class="number">{{col['Discount']}}</td>
						<td class="number">{{col['Amount']}}</td>
					</tr>
					<tr class="total_amount">
						<td></td>
						<td colspan="1">
							<p>DELIVERY CHARGE</p>
							<p>NET TOTAL</p>
						</td>
						<td colspan="4" class="number">
							<p style="text-align: right;">{{tables[0].table[0]['Delivery Charge']}}</p>
							<p style="text-align: right;">{{tables[0].table[0]['Bill Amount']}}</p>
						</td>
					</tr>
				</table>

			</div>


		</div>
	</div>

	<button mat-fab class="fabPrint" color="warn" (click)="print()">
		<mat-icon>print</mat-icon>
	</button>

</div>