import { Component, OnInit } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { GetServerDataServiceService } from 'src/app/get-server-data-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmMessageDialog } from 'src/app/messages/confirm-message-dialog';
import { NewCategoryComponent } from 'src/app/product-entey/new-category/new-category.component';
import { UpdateCategoryComponent } from 'src/app/product-entey/update-category/update-category.component';
import { UpdateSubCategoryComponent } from 'src/app/product-entey/update-sub-category/update-sub-category.component';
import { InsetSubCategoryComponent } from 'src/app/product-entey/inset-sub-category/inset-sub-category.component';
import { FileUploadDialogComponent } from 'src/app/file-upload/file-upload-dialog';
import { NewBrandComponent } from 'src/app/product-entey/new-brand/new-brand.component';
import { BrandListEditComponent } from 'src/app/product-entey/brand-list-edit/brand-list-edit.component';

@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.css']
})
export class BrandListComponent implements OnInit {

  constructor(
    private server: GetServerDataServiceService,
    private toolTip: MatSnackBar,
    private dialog: MatDialog) { }

  brandList = [];
  randSeed = Math.random();

  remove(id: string) {

    const confirmActonRef = this.dialog.open(ConfirmMessageDialog,
      {
        data: {
          id,
          message: 'Are you sure that you want to remove this role permanently?'
        },
        width: '300px',
      }
    );

    confirmActonRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.server.remove_role(confirm.id).subscribe(res => {
          this.refresh();
          console.log(res);
          this.toolTip.open('Role with ' + confirm.id + ' id is removed!', 'Ok', { duration: 3000 });
        });
      }
    });
  }

  ngOnInit() {
    this.loadBrand();
  }

  loadBrand(): void {
    this.server.brand_list().subscribe(res => {
      this.brandList = res.map((e: any) => {
        e.brand_img = this.getImageName(this.server.base() + 'image/brands/', e);
        return e;
      });
      console.log(this.brandList);
    })
  }

  updateCatogoryTrends(row: any) {
    this.server.update_brand_trends(row.trends, row.id).subscribe(res => {
      console.log(res);
      this.toolTip.open('Web Show Updated!', 'Ok', { duration: 3000 });
    });
  }

  refresh() {
    this.loadBrand();
    this.setRand();
  }

  addNewbrand(): void {
    const dialogRef = this.dialog.open(NewBrandComponent);
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.loadBrand();
    });
  }

  edit(item: any): void {

    const dialogRef = this.dialog.open(BrandListEditComponent, {
      data: { item }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.refresh();
    });

  }

  setRand() {
    this.randSeed = Math.random()
  }

  delete(item: any): void {

    const confirmActonRef = this.dialog.open(ConfirmMessageDialog,
      {
        data: {
          id: item.id,
          message: 'Are you sure that you want to remove this Item permanently?'
        },
        width: '300px',
      }
    );

    confirmActonRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.server.delete_brand(confirm.id).subscribe(res => {
          this.refresh();
          console.log(res);
          this.toolTip.open('Item with ' + confirm.id + ' id is removed!', 'Ok', { duration: 3000 });
        });
      }
    });

  }

  // uploadImage(name: any, ev: any, path: string): void {
  //   const mainSlide = this.dialog.open(FileUploadDialogComponent,
  //     {
  //       data: {
  //         name: this.subCategory.title.replace(/ /g, '_') + '_' + name.replace(/ /g, '_'),
  //         width: ev.path[0].naturalWidth,
  //         aspectRatio: ev.path[0].naturalWidth / ev.path[0].naturalHeight,
  //         path: '/' + path
  //       },
  //       width: '90vw'
  //     }
  //   );

  //   mainSlide.afterClosed().subscribe(res => {
  //     this.refresh();
  //   });
  // }

  uploadImageFixed(name: any, ev: any, path: string): void {
    const mainSlide = this.dialog.open(FileUploadDialogComponent,
      {
        data: {
          name: name.replace(/ /g, '_'),
          width: ev.path[0].naturalWidth,
          aspectRatio: ev.path[0].naturalWidth / ev.path[0].naturalHeight,
          path: '/' + path
        },
        width: '90vw'
      }
    );

    mainSlide.afterClosed().subscribe(res => {
      this.refresh();
    });
  }

  getImageName(path: string, e: any) {
    if (e['name']) {
      return path + e['name'].replace(/ /g, '_') + '.jpg?' + Math.random();
    } else {
      return null;
    }

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.brandList, event.previousIndex, event.currentIndex);
  }

  uploadImage(name: any, ev: any, path: string): void {
    const mainSlide = this.dialog.open(FileUploadDialogComponent,
      {
        data: {
          name: name.replace(/ /g, '_'),
          width: ev.path[0].naturalWidth,
          aspectRatio: ev.path[0].naturalWidth / ev.path[0].naturalHeight,
          path: '/' + path
        },
        width: '90vw'
      }
    );

    mainSlide.afterClosed().subscribe(res => {
      this.refresh();
    });
  }

  save() {
    this.server.update_brand_pos(this.brandList).subscribe(res => {
      this.toolTip.open('Updated!', 'Ok', { duration: 3000 });
    })
  }

}
