import { Directive, ElementRef, HostListener } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { NgModel } from "@angular/forms";

@Directive({
  selector: "[appInputCurrencyFormatter]",
  providers: [CurrencyPipe, NgModel]
})
export class InputCurrencyFormatterDirective {
  private el: HTMLInputElement;

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CurrencyPipe,
    private ngModel: NgModel
  ) {}

  ngOnInit() {
    // this.el.value = this.currencyPipe.transform(this.el.value, ' ');
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    if (value == "") {
      value = 0;
    }
    this.ngModel.update.emit(value);
  }

  fixValue(value: string) {
    if (Number(value.replace(/,/g, ""))) {
      return value.replace(/,/g, "");
    } else if(value.split('%').length == 2){
      return value.replace(/,/g, "");
    } else{
      return 0;
    }
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    if (value == "") {
      value = 0;
    }
    console.log(value);
    this.ngModel.update.emit(value);
  }
}
