<div class="mat-app-background">
  <mat-sidenav-container class="sidenav-container" (swipeleft)="closeSideBar()">
    <mat-sidenav #drawer class="sidenav" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="getSideNavStatus()"
      [@menuExpand]="expandSideBar ? 'collapse' : 'expand'" (closedStart)="sideBarClosed()"
      (document:keydown.alt.P)="searchBox()" (openedStart)="sideBarOpened()">
      <mat-nav-list>
        <!-- appPrivileage="VIEW DASHBOARD" -->
        <a mat-list-item routerLink="/main/dashboard" routerLinkActive="active">
          <mat-icon aria-label="Side nav toggle icon">dvr</mat-icon>
          <span class="titleText" *ngIf="expandSideBar">Dashboard</span>
        </a>
        <!-- appPrivileage="VIEW WEB_MANAGEMENT" -->
        <mat-expansion-panel [expanded]="loadState('VIEW_WEB_MANAGEMENT_expanded')"
          (closed)="saveState('VIEW_WEB_MANAGEMENT_expanded',false)"
          (opened)="saveState('VIEW_WEB_MANAGEMENT_expanded',true)" [hideToggle]="!expandSideBar" true
          [hideToggle]="!expandSideBar" appPrivileage="VIEW WEB_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">web</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Web Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a mat-list-item routerLink="/main/image-upload/product-upload" routerLinkActive="active">Upload Product
            Images</a>
          <a mat-list-item routerLink="/main/file-upload/section-images" routerLinkActive="active">Section Images</a>
          <a mat-list-item routerLink="/main/file-upload/edit-sections" routerLinkActive="active">Edit
            Sections</a>
          <a mat-list-item routerLink="/main/web-settings/enable-functionalities" routerLinkActive="active">Enable
            Functionalities</a>
          <a mat-list-item routerLink="/main/web-settings/update-information" routerLinkActive="active">Update
            information</a>
          <a mat-list-item routerLink="/main/file-upload/main-slide" routerLinkActive="active">Main Slide</a>
        </mat-expansion-panel>

        <!-- appPrivileage="VIEW PRODUCT_MANAGEMENT" -->
        <mat-expansion-panel [expanded]="loadState('VIEW_PRODUCT_MANAGEMENT_expanded')"
          (closed)="saveState('VIEW_PRODUCT_MANAGEMENT_expanded', false)"
          (opened)="saveState('VIEW_PRODUCT_MANAGEMENT_expanded',true)" [hideToggle]="!expandSideBar"
          appPrivileage="VIEW PRODUCT_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">storage</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Product Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/main/product-entey/new-product" appPrivileage="NEW PRODUCT"
            routerLinkActive="active">New Product</a>

          <a mat-list-item routerLink="/main/product-entey/product-list" appPrivileage="LIST PRODUCT"
            routerLinkActive="active">Product List</a>

          <a mat-list-item routerLink="/main/product-entey/bundle-list" appPrivileage="LIST BUNDLE"
            routerLinkActive="active">Bundle List</a>

          <a mat-list-item routerLink="/main/product-entey/category-list" appPrivileage="LIST CATEGORY"
            routerLinkActive="active">Category List</a>

          <a mat-list-item routerLink="/main/product-entey/brand-list" appPrivileage="LIST BRAND"
            routerLinkActive="active">Brand List</a>

        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="loadState('VIEW_STOCK_MANAGEMENT_expanded')"
          (closed)="saveState('VIEW_STOCK_MANAGEMENT_expanded',false)"
          (opened)="saveState('VIEW_STOCK_MANAGEMENT_expanded',true)" [hideToggle]="!expandSideBar">
          <a mat-list-item routerLink="/main/stock-management/opening-stock" routerLinkActive="active" privileage="10"
            appPrivileage="VIEW OPENING_STOCK">Opening Stock</a>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">assignment_returned</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Stock Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/main/stock-management/purchase-order" routerLinkActive="active" privileage="10"
            appPrivileage="VIEW PO">Purchase Order</a>
          <a mat-list-item routerLink="/main/stock-management/grn-entry" appPrivileage="VIEW GRN"
            routerLinkActive="active" privileage="10">Goods
            Received Note</a>
          <mat-expansion-panel appPrivileage="VIEW STOCK_TRANSFER">
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Stock View</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/stock-management/stock-view" appPrivileage="VIEW STOCK"
              routerLinkActive="active" privileage="10">Current Stock</a>
            <a mat-list-item routerLink="/main/stock-management/stock-histor" appPrivileage="VIEW STOCK"
              routerLinkActive="active" privileage="10">Stock History</a>
          </mat-expansion-panel>
          <mat-expansion-panel appPrivileage="VIEW STOCK_TRANSFER">
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Stock Transfer</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/stock-management/stock-transfer-outlets" routerLinkActive="active"
              privileage="10">Products Transfer</a>
            <a mat-list-item routerLink="/main/stock-management/stock-reduce" routerLinkActive="active"
              privileage="10">Stock Reduce</a>
            <a mat-list-item routerLink="/main/stock-management/transit-transfer" routerLinkActive="active"
              privileage="10">Inter-Stock Transfer</a>
            <a mat-list-item routerLink="/main/stock-management/inter-outlet-transfer" routerLinkActive="active"
              privileage="10">Inter-Outlet Transfer</a>
              <a mat-list-item routerLink="/main/stock-management/inter-location-transfer" routerLinkActive="active"
              privileage="10">Inter-Location Transfer</a>
          </mat-expansion-panel>
          <mat-expansion-panel appPrivileage="VIEW STOCK_RECEIVE">
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Stock Receive</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/stock-management/stock-receive-view/" routerLinkActive="active"
              privileage="10">Products Receive</a>
            <a mat-list-item routerLink="/main/stock-management/stock-receive" routerLinkActive="active"
              privileage="10">Inter-Outlet Receive</a>
          </mat-expansion-panel>
          <mat-expansion-panel appPrivileage="VIEW SUPPLIER">
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Supplier</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/stock-management/new-supplier" routerLinkActive="active"
              privileage="10">New Supplier</a>
            <a mat-list-item routerLink="/main/stock-management/supplier-list" routerLinkActive="active"
              privileage="10">Supplier List</a>
          </mat-expansion-panel>
          <a mat-list-item routerLink="/main/stock-management/barcode-print" appPrivileage="BARCODE STOCK"
            routerLinkActive="active" privileage="10">Barcode Print</a>
          <mat-expansion-panel appPrivileage="VIEW RETURN">
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Return Note</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="no_shaddow">
                <mat-panel-title>Supplier Return</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/stock-management/return-supplier-grn" routerLinkActive="active"
                privileage="10">GRN Wise</a>
              <a mat-list-item routerLink="/main/stock-management/return-supplier-item" routerLinkActive="active"
                privileage="10">Item Wise</a>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="no_shaddow">
                <mat-panel-title>Customer Return</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/stock-management/return-customer-invoice" routerLinkActive="active"
                privileage="10">Invoice Wise</a>
              <a mat-list-item routerLink="/main/stock-management/return-customer-item" routerLinkActive="active"
                privileage="10">Item Wise</a>
            </mat-expansion-panel>
          </mat-expansion-panel>

          <mat-expansion-panel appPrivileage="VIEW STOCK_LOCATION">
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Stock Location</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/stock-management/warehouse" routerLinkActive="active">Warehouse</a>
            <a mat-list-item routerLink="/main/stock-management/bin" routerLinkActive="active">Bin</a>
          </mat-expansion-panel>

          <!-- appPrivileage="VIEW STOCK_LOCATION" -->
          <mat-expansion-panel>
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Price Tag</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/stock-management/new-price-tag" routerLinkActive="active">New Price
              Tag</a>
            <a mat-list-item routerLink="/main/stock-management/price-tag-list" routerLinkActive="active">Price Tag
              List</a>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Stock Take</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/stock-management/new-stock-take" routerLinkActive="active">New Stock
              Take</a>
            <a mat-list-item routerLink="/main/stock-management/stock-take-list" routerLinkActive="active">Stock Take
              List</a>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Stock Adjustment</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/stock-management/sale-price" routerLinkActive="active">Sale Price</a>
          </mat-expansion-panel>

        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="loadState('VIEW_DELIVERY_MANAGEMENT_expanded')"
          (opened)="saveState('VIEW_DELIVERY_MANAGEMENT_expanded', true)"
          (closed)="saveState('VIEW_DELIVERY_MANAGEMENT_expanded',false)" [hideToggle]="!expandSideBar"
          appPrivileage="VIEW DELIVERY_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">local_shipping</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Delivery Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-expansion-panel [expanded]="loadState('VIEW_DARAZ_expanded')"
            (opened)="saveState('VIEW_DARAZ_expanded', true)" (closed)="saveState('VIEW_DARAZ_expanded',false)"
            [hideToggle]="!expandSideBar" appPrivileage="VIEW DARAZ">
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Daraz</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/delivery-management/upload" routerLinkActive="active"
              privileage="10">Upload</a>
            <a mat-list-item routerLink="/main/delivery-management/new-orders" routerLinkActive="active"
              privileage="10">New Orders</a>
            <mat-expansion-panel>
              <mat-expansion-panel-header class="no_shaddow">
                <mat-panel-title>Orders</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/delivery-management/pending-orders" routerLinkActive="active"
                privileage="10">Ready To Pack</a>
              <a mat-list-item routerLink="/main/delivery-management/packed-orders" routerLinkActive="active"
                privileage="10">Packed Orders</a>
              <a mat-list-item routerLink="/main/delivery-management/ready-to-ship" routerLinkActive="active"
                privileage="10">Ready To Ship</a>
              <a mat-list-item routerLink="/main/delivery-management/shipped-orders" routerLinkActive="active"
                privileage="10">Shipped Orders</a>
              <a mat-list-item routerLink="/main/delivery-management/return" routerLinkActive="active"
                privileage="10">Return</a>
            </mat-expansion-panel>
            <a mat-list-item routerLink="/main/delivery-management/reports" routerLinkActive="active"
              privileage="10">Reports</a>
            <a mat-list-item routerLink="/main/delivery-management/product-attachment" routerLinkActive="active"
              privileage="10">Product Attachment</a>
            <a mat-list-item routerLink="/main/delivery-management/invoicing" routerLinkActive="active"
              privileage="10">Invoicing</a>
            <a mat-list-item routerLink="/main/delivery-management/invoiced-list" routerLinkActive="active"
              privileage="10">Invoiced List</a>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Dispatch Note</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/delivery-management/new-dispatch" routerLinkActive="active"
              privileage="10">New Dispatch</a>
          </mat-expansion-panel>

          <mat-expansion-panel appPrivileage="VIEW SUPPLIER">
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Courier</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/delivery-management/new-courier" routerLinkActive="active"
              privileage="10">New Courier</a>
            <a mat-list-item routerLink="/main/delivery-management/courier-list" routerLinkActive="active"
              privileage="10">Courier List</a>
          </mat-expansion-panel>

        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="loadState('VIEW_RENT_MANAGEMENT_expanded')"
          (opened)="saveState('VIEW_RENT_MANAGEMENT_expanded', true)"
          (closed)="saveState('VIEW_RENT_MANAGEMENT_expanded',false)" [hideToggle]="!expandSideBar"
          appPrivileage="VIEW RENT_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">build</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Rent Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/main/rent-management/rent-item-entry" routerLinkActive="active"
            privileage="10">Rent Item Entry</a>
        </mat-expansion-panel>

        <!-- appPrivileage="VIEW PROMOTION_MANAGEMENT" -->
        <mat-expansion-panel [expanded]="loadState('VIEW_PROMOTION_MANAGEMENT_expanded')"
          (opened)="saveState('VIEW_PROMOTION_MANAGEMENT_expanded',true)"
          (closed)="saveState('VIEW_PROMOTION_MANAGEMENT_expanded', false)" [hideToggle]="!expandSideBar"
          appPrivileage="VIEW PROMOTION_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">sms</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Promotion Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-expansion-panel>
            <mat-expansion-panel-header class="no_shaddow">
              <mat-panel-title>Create Promotion</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/promotion-management/create-audience" routerLinkActive="active"
              privileage="10">Create Audience</a>
          </mat-expansion-panel>
          <a mat-list-item routerLink="/main/promotion-management/sms-campaign" routerLinkActive="active"
            privileage="10">SMS Campaign</a>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="loadState('VIEW_SALES_MANAGEMENT_expanded')"
          (opened)="saveState('VIEW_SALES_MANAGEMENT_expanded', true)"
          (closed)="saveState('VIEW_SALES_MANAGEMENT_expanded', false)" true [hideToggle]="!expandSideBar"
          appPrivileage="VIEW SALES_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">attach_money</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Sales Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/main/sales-management/invoice" appPrivileage="VIEW BILL"
            routerLinkActive="active">Invoice</a>
          <a mat-list-item routerLink="/main/report-management/day-end" appPrivileage="VIEW DAY_END"
            routerLinkActive="active">Day End</a>
          <a mat-list-item routerLink="/main/web-settings/web-orders" appPrivileage="VIEW WEB_ORDERS"
            routerLinkActive="active">Web Orders</a>
          <a mat-list-item routerLink="/main/sales-management/cancellations" appPrivileage="VIEW CANCELLATION"
            routerLinkActive="active">Cancellations</a>
          <a mat-list-item routerLink="/main/sales-management/trip-history" appPrivileage="VIEW TRIP_HISTORY"
            routerLinkActive="active">Trip History</a>
          <a mat-list-item routerLink="/main/sales-management/sales-order" appPrivileage="VIEW SALES_ORDER"
            routerLinkActive="active">Sales Order</a>
        </mat-expansion-panel>

        <!-- appPrivileage="VIEW TAYLOR_MANAGEMENT" -->
        <mat-expansion-panel [expanded]="loadState('VIEW_TAYLOR_MANAGEMENT_expanded')"
          (opened)="saveState('VIEW_TAYLOR_MANAGEMENT_expanded', true)"
          (closed)="saveState('VIEW_TAYLOR_MANAGEMENT_expanded', false)" [hideToggle]="!expandSideBar"
          appPrivileage="VIEW TAYLOR_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">straighten</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Tailor Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/main/taylor-management/place-order" routerLinkActive="active">Place Order</a>
          <a mat-list-item routerLink="/main/taylor-management/measurement" routerLinkActive="active">measurement</a>
        </mat-expansion-panel>

        <!-- appPrivileage="VIEW REPORTS_MANAGEMENT" -->
        <mat-expansion-panel [expanded]="loadState('VIEW_REPORTS_MANAGEMENT_expanded')"
          (opened)="saveState('VIEW_REPORTS_MANAGEMENT_expanded',true)"
          (closed)="saveState('VIEW_REPORTS_MANAGEMENT_expanded', false)" [hideToggle]="!expandSideBar"
          appPrivileage="VIEW REPORTS_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">style</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Report Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-expansion-panel appPrivileage="REPORTS BILL">
            <mat-expansion-panel-header>
              <mat-panel-title>Sales Reports</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/sales-reports/bill-wise" routerLinkActive="active">Bill
              Wise</a>
            <a mat-list-item routerLink="/main/sales-reports/item-wise" routerLinkActive="active">Item
              Wise</a>
            <a mat-list-item routerLink="/main/sales-reports/supplier-wise" routerLinkActive="active">Supplier Wise</a>
            <a mat-list-item routerLink="/main/web-settings/sales-person-wise" routerLinkActive="active">Sales Person
              Wise</a>
            <a mat-list-item routerLink="/main/sales-reports/date-wise" routerLinkActive="active">Date
              Wise</a>
            <a mat-list-item routerLink="/main/sales-reports/payment-wise" routerLinkActive="active">Payment Wise</a>
            <a mat-list-item routerLink="/main/sales-reports/customer-wise-report" routerLinkActive="active">Customer
              Wise Report</a>
            <a mat-list-item routerLink="/main/sales-reports/category-wise" routerLinkActive="active">Category Wise</a>
            <a mat-list-item routerLink="/main/sales-reports/sales-orders" routerLinkActive="active">Sales Orders</a>
          </mat-expansion-panel>

          <mat-expansion-panel appPrivileage="REPORTS SERVICE">
            <mat-expansion-panel-header>
              <mat-panel-title>Service Reports</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/sales-reports/bill-wise-service" routerLinkActive="active">Bill
              Wise</a>
          </mat-expansion-panel>

          <mat-expansion-panel appPrivileage="REPORTS STOCK">
            <mat-expansion-panel-header>
              <mat-panel-title>Stock Reports</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Stock Transfer</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/report-management/stock-transfer" routerLinkActive="active">Summary</a>
              <a mat-list-item routerLink="/main/report-management/stock-transfer-details"
                routerLinkActive="active">Details</a>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Stock
                  Receive</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/report-management/stock-receive" routerLinkActive="active">Summary</a>
              <a mat-list-item routerLink="/main/report-management/stock-receive-details"
                routerLinkActive="active">Details</a>
            </mat-expansion-panel>
            <a mat-list-item routerLink="/main/report-management/inter-stock-transfer"
              routerLinkActive="active">Inter-Stock
              Transfer</a>
            <a mat-list-item routerLink="/main/report-management/inter-outlet-transfer-reports"
              routerLinkActive="active">Inter-Outlet
              Transfer</a>
          </mat-expansion-panel>

          <mat-expansion-panel appPrivileage="REPORTS GRN">
            <mat-expansion-panel-header>
              <mat-panel-title>GRN Reports</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>GRN Wise</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/report-management/grn-reports" routerLinkActive="active">Summary</a>
              <a mat-list-item routerLink="/main/report-management/grn-wise-details"
                routerLinkActive="active">Details</a>
            </mat-expansion-panel>
            <a mat-list-item routerLink="/main/report-management/grn-aged-analysis" routerLinkActive="active">Aged
              Analysis</a>
          </mat-expansion-panel>

          <a mat-list-item routerLink="/main/report-management/po-reports" routerLinkActive="active"
            appPrivileage="REPORTS PO">PO Reports</a>

          <mat-expansion-panel appPrivileage="REPORTS CASHIER">
            <mat-expansion-panel-header>
              <mat-panel-title>Cashier Reports</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/report-management/day-end-se" routerLinkActive="active">Day End</a>
          </mat-expansion-panel>

          <mat-expansion-panel appPrivileage="REPORTS DISPATCH">
            <mat-expansion-panel-header>
              <mat-panel-title>Dispatch Reports</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/report-management/bill-wise" routerLinkActive="active">Bill Wise</a>
            <a mat-list-item routerLink="/main/report-management/person-wise" routerLinkActive="active">Sales Person
              Wise</a>
            <a mat-list-item routerLink="/main/report-management/item-wise" routerLinkActive="active">Item Wise</a>
            <a mat-list-item routerLink="/main/report-management/courier-wise" routerLinkActive="active">Courier
              Wise</a>
          </mat-expansion-panel>

          <mat-expansion-panel appPrivileage="REPORTS ACCOUNT_MANAGEMENT">
            <mat-expansion-panel-header>
              <mat-panel-title>Account Reports</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/report-management/petty-cash" routerLinkActive="active">Petty Cash</a>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Payment Voucher</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/report-management/payment-vouche" routerLinkActive="active"
                privileage="10">Supplier</a>
              <a mat-list-item routerLink="/main/report-management/service-creditor" routerLinkActive="active"
                privileage="10">Service Supplier</a>
              <a mat-list-item routerLink="/main/report-management/payment-voucher-bills" routerLinkActive="active"
                privileage="10">Bills</a>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Receipt Voucher</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/report-management/payment-vouche-debtor" routerLinkActive="active"
                privileage="10">Customer</a>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Creditor</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/report-management/payment-reports"
                routerLinkActive="active">Listing</a>
              <a mat-list-item routerLink="/main/report-management/aged-analysis" routerLinkActive="active">Aged
                Analysis</a>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Debtor</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/report-management/payment-reports-customer"
                routerLinkActive="active">Listing</a>
              <a mat-list-item routerLink="/main/report-management/debtor-aged-analysis" routerLinkActive="active">Aged
                Analysis</a>
            </mat-expansion-panel>
          </mat-expansion-panel>

          <mat-expansion-panel appPrivileage="REPORTS RETURN">
            <mat-expansion-panel-header>
              <mat-panel-title>Return Reports</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Customer Return</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/report-management/customer-return-bill-wise"
                routerLinkActive="active">Bill Wise</a>
              <a mat-list-item routerLink="/main/report-management/customer-return-item-wise"
                routerLinkActive="active">Item
                Wise</a>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>Supplier Return</mat-panel-title>
              </mat-expansion-panel-header>
              <a mat-list-item routerLink="/main/report-management/supplier-return-grn-wise"
                routerLinkActive="active">GRN Wise</a>
              <a mat-list-item routerLink="/main/report-management/supplier-return-item-wise"
                routerLinkActive="active">Item Wise</a>
            </mat-expansion-panel>
          </mat-expansion-panel>

          <mat-expansion-panel appPrivileage="REPORTS CANCELLATION">
            <mat-expansion-panel-header>
              <mat-panel-title>Cancellation Reports</mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/report-management/invoice-cancellation" routerLinkActive="active">Invoice
              Cancellation</a>
          </mat-expansion-panel>

        </mat-expansion-panel>

        <!-- appPrivileage="VIEW CUSTOMER_MANAGEMENT" -->
        <mat-expansion-panel [expanded]="loadState('VIEW_CUSTOMER_MANAGEMENT_expanded')"
          (opened)="saveState('VIEW_CUSTOMER_MANAGEMENT_expanded', true)"
          (closed)="saveState('VIEW_CUSTOMER_MANAGEMENT_expanded', false)" [hideToggle]="!expandSideBar"
          appPrivileage="VIEW CUSTOMER_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">contacts</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Customer Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/main/customer-management/new-customer" appPrivileage="NEW CUSTOMER"
            routerLinkActive="active" privileage="10">New Customer</a>
          <a mat-list-item routerLink="/main/customer-management/customer-list" appPrivileage="LIST CUSTOMER"
            routerLinkActive="active" privileage="10">Customer List</a>

          <mat-expansion-panel [expanded]="loadState('VIEW_CUSTOMER_MANAGEMENT_Barcode_Print_expanded')"
            (opened)="saveState('VIEW_CUSTOMER_MANAGEMENT_Barcode_Print_expanded', true)"
            (closed)="saveState('VIEW_CUSTOMER_MANAGEMENT_Barcode_Print_expanded', false)" [hideToggle]="!expandSideBar"
            appPrivileage="BARCODE CUSTOMER">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="titleText" *ngIf="expandSideBar">Barcode Print</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/customer-management/customer-barcode-print"
              routerLinkActive="active">Customer Wise Print</a>
            <a mat-list-item routerLink="/main/customer-management/customer-uuid-print" routerLinkActive="active">UUID
              Print</a>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="false"
            [expanded]="loadState('VIEW_CUSTOMER_MANAGEMENT_Loyalty_Customer_expanded')"
            (opened)="saveState('VIEW_CUSTOMER_MANAGEMENT_Loyalty_Customer_expanded', true)"
            (closed)="saveState('VIEW_CUSTOMER_MANAGEMENT_Loyalty_Customer_expanded', false)"
            [hideToggle]="!expandSideBar">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="titleText" *ngIf="expandSideBar">Loyalty Customer</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/account-management/loyalty-customer-import"
              routerLinkActive="active">Import</a>
          </mat-expansion-panel>


        </mat-expansion-panel>

        <!-- appPrivileage="VIEW MEMEBERSHIP_MANAGEMENT" -->
        <mat-expansion-panel [expanded]="loadState('VIEW_MEMEBERSHIP_MANAGEMENT_expanded')"
          (opened)="saveState('VIEW_MEMEBERSHIP_MANAGEMENT_expanded', true)"
          (closed)="saveState('VIEW_MEMEBERSHIP_MANAGEMENT_expanded', false)" [hideToggle]="!expandSideBar"
          appPrivileage="VIEW MEMEBERSHIP_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">supervisor_account</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Membership Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/main/membership-management/add-member" routerLinkActive="active">Add
            Member</a>
          <a mat-list-item routerLink="/main/membership-management/member-list" routerLinkActive="active">Member
            List</a>
          <a mat-list-item routerLink="/main/membership-management/member-edit" routerLinkActive="active">Edit
            Member</a>
        </mat-expansion-panel>

        <!-- appPrivileage="VIEW ACCOUNT_MANAGEMENT" -->
        <mat-expansion-panel [expanded]="loadState('VIEW_ACCOUNT_MANAGEMENT_expanded')"
          (opened)="saveState('VIEW_ACCOUNT_MANAGEMENT_expanded', true)"
          (closed)="saveState('VIEW_ACCOUNT_MANAGEMENT_expanded', false)" [hideToggle]="!expandSideBar"
          appPrivileage="VIEW ACCOUNT_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">account_balance</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">Account Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-expansion-panel [expanded]="loadState('VIEW_ACCOUNT_MANAGEMENT_Opening_Balance_expanded')"
            (opened)="saveState('VIEW_ACCOUNT_MANAGEMENT_Opening_Balance_expanded', true)"
            (closed)="saveState('VIEW_ACCOUNT_MANAGEMENT_Opening_Balance_expanded', false)"
            [hideToggle]="!expandSideBar" appPrivileage="VIEW OPENING_BALANCE">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="titleText" *ngIf="expandSideBar">Opening Balance</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/account-management/opening-balance"
              routerLinkActive="active">Creditor</a>
            <a mat-list-item routerLink="/main/account-management/opening-balance-debtor"
              routerLinkActive="active">Debtor</a>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="loadState('VIEW_ACCOUNT_MANAGEMENT_pety_cash_expanded')"
            (opened)="saveState('VIEW_ACCOUNT_MANAGEMENT_pety_cash_expanded', true)"
            (closed)="saveState('VIEW_ACCOUNT_MANAGEMENT_pety_cash_expanded', false)" appPrivileage="VIEW PETTY_CASH"
            [hideToggle]="!expandSideBar">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="titleText" *ngIf="expandSideBar">Petty Cash</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/account-management/add-petty-cash" routerLinkActive="active">Voucher</a>
            <a mat-list-item routerLink="/main/account-management/account-list" routerLinkActive="active">Account
              List</a>
            <a mat-list-item routerLink="/main/account-management/reimburse" routerLinkActive="active">Reimburse</a>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="loadState('VIEW_ACCOUNT_MANAGEMENT_cash_book_expanded')"
            (opened)="saveState('VIEW_ACCOUNT_MANAGEMENT_cash_book_expanded', true)"
            (closed)="saveState('VIEW_ACCOUNT_MANAGEMENT_cash_book_expanded', false)" [hideToggle]="!expandSideBar">
            <mat-expansion-panel-header appPrivileage="VIEW CASH_BOOK">
              <mat-panel-title>
                <span class="titleText" *ngIf="expandSideBar">Cash Book</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/account-management/cash-book-statement"
              routerLinkActive="active">Statement</a>
            <a mat-list-item routerLink="/main/account-management/cash-book-account-list"
              routerLinkActive="active">Account
              List</a>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="loadState('VIEW_ACCOUNT_MANAGEMENT_cheque_details_expanded')"
            (opened)="saveState('VIEW_ACCOUNT_MANAGEMENT_cheque_details_expanded', true)"
            (closed)="saveState('VIEW_ACCOUNT_MANAGEMENT_cheque_details_expanded', false)" [hideToggle]="!expandSideBar"
            appPrivileage="VIEW CHEQUE_DETAILS">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="titleText" *ngIf="expandSideBar">Cheque Details</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/account-management/cheque-list" routerLinkActive="active">Cheque List</a>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="loadState('VIEW_ACCOUNT_MANAGEMENT_Bills_expanded')"
            (opened)="saveState('VIEW_ACCOUNT_MANAGEMENT_Bills_expanded', true)"
            (closed)="saveState('VIEW_ACCOUNT_MANAGEMENT_Bills_expanded', false)" [hideToggle]="!expandSideBar"
            appPrivileage="VIEW BILL_PAYABLE">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="titleText" *ngIf="expandSideBar">Bill Payable</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/account-management/bills-list" routerLinkActive="active">Bills List</a>
            <a mat-list-item routerLink="/main/account-management/service-supplier-list"
              routerLinkActive="active">Service Supplier List</a>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="loadState('VIEW_ACCOUNT_MANAGEMENT_payments_expanded')"
            (opened)="saveState('VIEW_ACCOUNT_MANAGEMENT_payments_expanded', true)"
            (closed)="saveState('VIEW_ACCOUNT_MANAGEMENT_payments_expanded', false)" [hideToggle]="!expandSideBar"
            appPrivileage="VIEW PAYMENT">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="titleText" *ngIf="expandSideBar">Payments</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/account-management/supplier" routerLinkActive="active">Supplier</a>
            <a mat-list-item routerLink="/main/account-management/payments-bill" routerLinkActive="active">Bills</a>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="loadState('VIEW_ACCOUNT_MANAGEMENT_Receipts_expanded')"
            (opened)="saveState('VIEW_ACCOUNT_MANAGEMENT_Receipts_expanded', true)"
            (closed)="saveState('VIEW_ACCOUNT_MANAGEMENT_Receipts_expanded', false)" [hideToggle]="!expandSideBar"
            appPrivileage="VIEW RECEIPTS">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="titleText" *ngIf="expandSideBar">Receipts</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/main/account-management/receipts-customer"
              routerLinkActive="active">Customer</a>
          </mat-expansion-panel>

        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="loadState('USER_MANAGEMENT_MANAGEMENT_expanded')"
          (opened)="saveState('USER_MANAGEMENT_MANAGEMENT_expanded', true)"
          (closed)="saveState('USER_MANAGEMENT_MANAGEMENT_expanded', false)" [hideToggle]="!expandSideBar"
          appPrivileage="VIEW USER_MANAGEMENT">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon aria-label="Side nav toggle icon">contacts</mat-icon>
              <span class="titleText" *ngIf="expandSideBar">User Management</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a mat-list-item routerLink="/main/user-management/all-users" appPrivileage="VIEW USER"
            routerLinkActive="active" privileage="10">All
            Users</a>
          <a mat-list-item routerLink="/main/user-management/new-user" appPrivileage="VIEW USER"
            routerLinkActive="active" privileage="7">New
            User</a>
          <a mat-list-item routerLink="/main/user-management/all-roles" appPrivileage="VIEW PERMISSION_ROLES"
            routerLinkActive="active" privileage="6">All
            Roles</a>
          <a mat-list-item routerLink="/main/user-management/new-role" appPrivileage="VIEW PERMISSION_ROLES"
            routerLinkActive="active" privileage="3">New
            Role</a>
          <a mat-list-item routerLink="/main/user-management/role-capabilities" appPrivileage="VIEW ROLE_CAPABILITIES"
            routerLinkActive="active" privileage="12">Roles Capabilities</a>
          <a mat-list-item routerLink="/main/user-management/user-settings" appPrivileage="VIEW USER_SETTINGS"
            routerLinkActive="active">User
            Settings</a>
        </mat-expansion-panel>
        <p style="height:0.5em" class="version">2020-10-25-1</p>
        <button type="button" aria-label="Toggle Sidebar Size" class="toggleNav" *ngIf="false"
          (click)="toggleSideBarWidth()" mat-stroked-button>
          <mat-icon aria-label="Side nav toggle icon" [@flip]="expandSideBar ? 'flipNeg' : 'flipPos'">double_arrow
          </mat-icon>
        </button>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar color="primary" *ngIf="showToolbar" [@fadeInUp]>
        <button mat-icon-button (click)="toggleSideBar()" aria-label="Sidebar Toggle">
          <mat-icon>menu</mat-icon>
        </button>
        <span fxHide.xs>Green Cloud POS System</span>
        <div fxFlex></div>
        <div class="branch">
          <span class="titleText" *ngIf="expandSideBar">{{getCompanyName()}} - {{ branch }}</span>
          <span style="font-size: 0.6em;padding-top: 5px;" *ngIf="syncTime !== ''">last Synced {{ syncTime }}</span>
        </div>
        <div fxFlex></div>
        <button fxHide.xs mat-raised-button (click)="logout()">Logout</button>
        <button fxHide.gt-xs type="button" aria-label="Logout" mat-icon-button (click)="logout()">
          <mat-icon aria-label="Side nav toggle icon">exit_to_app</mat-icon>
        </button>
      </mat-toolbar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>