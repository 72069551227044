
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { GetServerDataServiceService } from '../get-server-data-service.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HttpEventType } from '@angular/common/http';

@Component({
  templateUrl: 'image-upload-dialog.html',
  styles: [
    '.mat-card { box-shadow: none; }'
  ]
})

export class ImageUploadDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ImageUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private server: GetServerDataServiceService,
    private toolTip: MatSnackBar) { }


  poNoFilter: Observable<string[]>;
  inserting = false;
  defaultImage: any = '';
  progressMode = 'Indeterminate';
  uploadValue = 0;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  aspectRatio: any = '';
  resizeToWidth: any = '';

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.defaultImage = this.server.base() + 'image/full/' + this.data.row.id + '_v.webp?' + Math.random();
    this.aspectRatio = this.data.aspectRatio;
    this.resizeToWidth = this.data.width;
  }

  update(): void {
    this.inserting = true;
  }

  fileChangeEvent(event: any): void {
    // console.log(event);
    this.defaultImage = '';
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  uploadImage() {

    this.progressMode = 'Indeterminate';
    this.uploadValue = 0;

    const fd = new FormData();

    fd.append('image', this.croppedImage);
    fd.append('id', this.data.row.id);
    fd.append('code', this.data.row.pcode);
    fd.append('master', this.data.row.master);

    this.inserting = true;
    this.progressMode = 'Determinate';

    this.server.upload_image(fd)
      .subscribe(events => {
        console.log(events);
        if (events.type === HttpEventType.UploadProgress) {
          this.uploadValue = events.loaded / events.total * 100;

          if (this.uploadValue === 100) {
            this.toolTip.open('Image ' + this.data.row.pcode + ' is Uploaded Waiting for Server Responce', 'Ok', { duration: 3000 });
          }

        } else if (events.type === HttpEventType.Response) {
          this.inserting = false;
          this.progressMode = 'Indeterminate';
          this.toolTip.open('Image ' + this.data.row.pcode + ' is Added!', 'Ok', { duration: 3000 });
          this.dialogRef.close();
        }
      });

  }

  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    this.toolTip.open('Image Format Not supported!', 'Ok', { duration: 3000 });
  }

}
