import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-management-select-customer',
  templateUrl: './sales-management-select-customer.component.html',
  styleUrls: ['./sales-management-select-customer.component.css']
})
export class SalesManagementSelectCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
