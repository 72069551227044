import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GetServerDataServiceService } from 'src/app/get-server-data-service.service';
import { WindowEmitorsService } from 'src/app/window-emitors.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-sales-person',
  templateUrl: './new-sales-person.component.html',
  styleUrls: ['./new-sales-person.component.css']
})
export class NewSalesPersonComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private server: GetServerDataServiceService,
    private windowEmitors: WindowEmitorsService,
    private toolTip: MatSnackBar,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  newSalesPersons = this.fb.group({
    Code: [{ value: null, disabled: true }],
    Designation: [null],
    Name: ['-'],
    Address: [null],
    City: [null],
    primary_Contact_No: [null],
    secondary_Contact_No: [null],
  });

  DesignationList = [];
  countrys = this.server.getCountries();
  inserting = false;
  citys = [];
  city: any;

  loadDesignation() {
    this.server.get_sales_person_description().subscribe(res => {
      this.DesignationList = res;
      if (this.DesignationList.length > 0) {
        this.newSalesPersons.get("Designation").setValue(this.DesignationList[0]);
      }
    })
  }

  loadCity(){
    this.server.city_auto_list('', 'Sri Lanka').subscribe(res => {
      this.citys = res;
    });
  }

  cityValueChange(value: any) {
    if (typeof value === 'string') {
      this.newSalesPersons.get('City').setValue('');
    }
  }

  cityFinalValue(value: any) {
    this.newSalesPersons.get('City').setValue(value);
  }

  add(): void {
    if (!this.newSalesPersons.get("Address").value) {
      this.newSalesPersons.get("Address").setValue("-");
    }
    if (!this.newSalesPersons.get("secondary_Contact_No").value) {
      this.newSalesPersons.get("secondary_Contact_No").setValue(0);
    }

    this.server.add_sales_person(this.newSalesPersons.getRawValue()).subscribe(res => {
      this.toolTip.open("Sales Person Created!", "Ok", { duration: 3000 });
      this.newSalesPersons.reset();
      this.city = {};
      this.genCode();
      this.loadDesignation();
    })

  }

  genCode(): void {
    this.server.get_sales_person().subscribe(res => {
      this.newSalesPersons.get("Code").setValue(res[0][0].Code);
    });
  }

  ngOnInit() {
    this.genCode();
    this.loadDesignation();
    this.loadCity();
  }

}
