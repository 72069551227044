<div class="invoice" fxLayout="column" fxLayoutGap="0.5em">

  <mat-form-field fxFlex>
    <mat-label>Search Contact List</mat-label>
    <input matInput [(ngModel)]="search" placeholder="Search..." (ngModelChange)="this.autoUpdate.next($event)">
  </mat-form-field>

  <div fxLayout="column" fxLayoutGap="0.5em" class="contactList">
    <mat-card *ngFor="let c of contacts">
      <mat-card-header>
        <mat-card-title>{{c.code}} - {{c.name}}</mat-card-title>
        <mat-card-subtitle>{{c.contact_no}}</mat-card-subtitle>
        <mat-card-subtitle>{{c.points}} Points</mat-card-subtitle>
        <mat-card-subtitle>{{c.customer_type}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions>
        <div fxFlex></div>
        <p></p>
        <button color="primary" [disabled]="c.customer_type === ct.customer_type"
          [ngStyle]="{'background': c.customer_type === ct.customer_type ? 'lightgray':ct.color, 'color':'white'}"
          mat-raised-button *ngFor="let ct of CustomerTypeList | async"
          (click)="update(ct.id,c.id)">{{ct.customer_type}}</button>
      </mat-card-actions>
    </mat-card>
  </div>

  <div fxLayout="row">
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="getPaginatorData($event)" fxFlex></mat-paginator>
  </div>

  <div class="loading" *ngIf="loading">
    <mat-spinner class="spinner"></mat-spinner>
  </div>

</div>