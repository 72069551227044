import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, } from '@angular/core';

@Component({
  selector: 'app-editable-data-table',
  templateUrl: './editable-data-table.component.html',
  styleUrls: ['./editable-data-table.component.css']
})
export class EditableDataTableComponent implements OnInit {

  constructor(private renderer: Renderer2) { }

  @Input() createRow: any;
  @Input() width: string;

  data = [];
  colomns = [];
  colomTypes = {};
  emptyRow = {};

  ngOnInit() {
    this.createRow.forEach(ele => {
      this.colomns.push(ele.name);
      this.emptyRow[ele.name] = ele.default;
      this.colomTypes[ele.name] = ele.type;
    });
  }

  add(): void {
    const tempEmptyRow = {};
    const tempEmptyRowNull = {};
    for (const key in this.emptyRow) {
      if (this.emptyRow.hasOwnProperty(key)) {
        if(key === '#') {
          this.emptyRow[key]++;
        }
        tempEmptyRow[key] = this.emptyRow[key];
        tempEmptyRowNull[key] = this.emptyRow[key];
      }
    }
    this.data.push(tempEmptyRow);
    this.emptyRow = tempEmptyRowNull;
    this.renderer.selectRootElement('.inputElement').focus();
  }

  delete(row: any): void {
    console.log(row);
    const index = this.data.indexOf(row);
    if (index > -1) {
      this.data.splice(index, 1);
    }
  }

  keytab(event: any) {

    const element = event.srcElement.offsetParent.nextElementSibling.children[0];

    console.log(element);

    element.focus();

  }

  enable(event: any): void {
    console.log(event);
    event.srcElement.disabled = false;
    event.srcElement.focus();
  }

  edit(event: any) {
    event.srcElement.disabled = true;
  }

}
