import { Directive } from '@angular/core';

@Directive({
  selector: '[enterOnBlur]',
  host: { '(keypress)': 'onkeydown($event)' }
})
export class EnterOnBlurDirective {

  constructor() {

  }

  onkeydown(event: any) {

    console.log(event);

    if (event.key == "Enter") {

      event.target.blur();

    }

  }

}
