<mat-form-field fxFlex>
	<input type="text" [placeholder]="placeholder" aria-label="Number" matInput [matAutocomplete]="auto"
		[(ngModel)]="AutoValue" (ngModelChange)="this.autoUpdate.next($event)">
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="valueChanged()" [displayWith]="displayFn">
		<mat-option *ngFor="let option of options | async" [value]="option">
			{{option.employee}}
		</mat-option>
	</mat-autocomplete>

	<mat-icon color="primary" matSuffix (click)="addNewSalesPorson($event)" class="btn">add_circle</mat-icon>

</mat-form-field>