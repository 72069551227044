<div class="bg">
	<div class="page">
		<div class="content">

			<div class="header" fxLayout="row">
				<div fxFlex>
					<img [src]="base + 'image/logo.jpg' | authImage | async" style="width: 10em;">
				</div>
				<div fxFlex>
					<h2 style="text-align: center;">Commercial Invoice</h2>
					<p style="text-align: center;">[CASH ON DELIVERY]</p>
				</div>
				<div fxFlex>
					<ngx-barcode style="float: right;" [bc-value]="tables[0].table[0]['Bill No']" [bc-display-value]="false">
					</ngx-barcode>
				</div>
			</div>

			<div fxLayout="row">
				<div>
					<p style="line-height: 1;"><strong>Customer : </strong><span class="leftfix">{{tables[0].table[0].Customer}}</span></p>
					<p style="line-height: 1;"><strong>Contact No : </strong><span class="leftfix">{{tables[0].table[0].CONTACT_NO}}</span></p>
					<p style="line-height: 1;"><strong>Deliver to : </strong> <span class="leftfix">{{tables[0].table[0]['Deliver to']}}</span></p>
				</div>
				<div fxFlex></div>
				<div>
					<p><strong>Invoice No : </strong><span class="leftfix">{{tables[0].table[0]['Bill No']}}</span></p>
					<p><strong>Invoice Date : </strong><span class="leftfix">{{tables[0].table[0]['INVOICE_DATE']}}</span></p>
					<p><strong>Invoice Time : </strong><span class="leftfix">{{tables[0].table[0]['INVOICE_TIME']}}</span></p>
					<p><strong>Invoice By : </strong><span class="leftfix">{{tables[0].table[0]['Cashier']}}</span></p>
				</div>
			</div>

			<br>

			<div *ngFor="let table of tables" class="table">

				<table style="width:100%">
					<tr>
						<th>#</th>
						<th style="text-align: left;">Product Description</th>
						<th class="number">Qty</th>
						<th class="number">Unit Price</th>
						<th class="number">Discount</th>
						<th class="number">Amount</th>
					</tr>
					<tr *ngFor="let col of table.table">
						<td>{{col['#']}}</td>
						<td>{{col['Product Description']}}</td>
						<td class="number">{{col['Qty']}}</td>
						<td class="number">{{col['Unit Price']}}</td>
						<td class="number">{{col['Discount']}}</td>
						<td class="number">{{col['Amount']}}</td>
					</tr>

					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Gross Amount(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Gross Amount(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Gross Amount(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Total Discount(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Total Discount(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Total Discount(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Delivery Fee(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Delivery Fee(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Delivery Fee(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Return Amount(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Return Amount(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Return Amount(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br">
						<td></td>
						<td colspan="2">NET TOTAL</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Bill Amount']}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Cash Paid(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Cash Paid(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Cash Paid(Value)'] || 0}}</td>
					</tr>

					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Card Paid(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Card Paid(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Card Paid(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Voucher Paid(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Voucher Paid(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Voucher Paid(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Cheque Paid(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Cheque Paid(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Cheque Paid(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Online Paid(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Online Paid(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Online Paid(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Deposit Paid(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Deposit Paid(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Deposit Paid(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Points Paid(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Points Paid(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Points Paid(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['PAID AMOUNT(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['PAID AMOUNT(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['PAID AMOUNT(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount no_top_bottom_br" *ngIf="tables[0].table[0]['Balance(Label)']">
						<td></td>
						<td colspan="2">{{tables[0].table[0]['Balance(Label)']}}</td>
						<td colspan="3" class="number">{{tables[0].table[0]['Balance(Value)'] || 0}}</td>
					</tr>
					<tr class="total_amount">
						<td colspan="6" style="border-bottom: none;border-left: none;border-right: none;"></td>
					</tr>
				</table>

			</div>

			<div fxLayout="row" style="padding-top: 2em;">
				<div fxFlex fxLayout="column">
					<p>………………………………………………………………….......</p>
					<p style="text-align: center;padding-top: 1em;font-weight: 500;">Customer Signature</p>
				</div>
				<div fxFlex></div>
				<div fxFlex fxLayout="column">
					<p> ………………………………………………………………….......</p>
					<p style="text-align: center;padding-top: 1em;font-weight: 500;">Sales Person</p>
				</div>
			</div>


		</div>
	</div>

	<button mat-fab class="fabPrint" color="warn" (click)="print()">
		<mat-icon>print</mat-icon>
	</button>

</div>