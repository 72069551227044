<div class="page">
  <form [formGroup]="newSalesPersons" autocomplete="off" fxFlex="60" fxFlex.xs="100" autocomplete="off" fxFlex>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Create a new Sales Person</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="newSalesPersons" fxFlex>
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <input matInput formControlName="Code" placeholder="Code">
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="COUNTRY CUSTOMER">
            <mat-form-field fxFlex>
              <mat-label>Designation</mat-label>
              <mat-select formControlName="Designation">
                <mat-option *ngFor="let d of DesignationList" [value]="d">
                  {{d.designation}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <input matInput formControlName="Name" placeholder="Name" />
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <mat-form-field fxFlex>
              <input matInput formControlName="Address" placeholder="Address" />
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="CITY CUSTOMER">
            <app-auto-city fxFlex key="city" [options]="citys" placeholder="Select City"
              (finalValue)="cityFinalValue($event)">
            </app-auto-city>
          </div>
          <div fxLayout="row" appPrivileage="CONTACT1 CUSTOMER">
            <mat-form-field fxFlex>
              <input matInput type="number" formControlName="primary_Contact_No" placeholder="Primary Contact No" />
            </mat-form-field>
          </div>
          <div fxLayout="row" appPrivileage="CONTACT2 CUSTOMER">
            <mat-form-field fxFlex>
              <input matInput type="number" formControlName="secondary_Contact_No" placeholder="Secondary Contact No" />
            </mat-form-field>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button color="warn" [disabled]="!newSalesPersons.valid || inserting" (click)="add()"
          fxFlex>ADD</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>