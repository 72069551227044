<div fxLayoutAlign="center center" class="loginContainer">
  <form [formGroup]="userForm" autocomplete="on" fxFlex="20" fxFlex.sm="50" fxFlex.xs="80">
    <mat-card class="loginCard">
      <div class="logo">
        <img mat-card-image src="../../assets/icons/Green_Coding.png" alt="Green Coding Inc.">
      </div>
      <mat-card-content>
        <div fxLayout="row" class="gap">
          <mat-form-field fxFlex>
            <input matInput placeholder="Organization ID" formControlName="company" required>
            <mat-error *ngIf="userForm.controls.company.hasError('required')">
              Organization ID is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" class="gap">
          <mat-form-field fxFlex>
            <input matInput placeholder="User Name" formControlName="username" required>
            <mat-error *ngIf="userForm.controls.username.hasError('required')">
              User Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row" class="gap">
          <mat-form-field fxFlex>
            <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password"
              required>
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="userForm.controls.password.hasError('required')">
              Password is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" [disabled]="!userForm.valid || dsblSubmit" (click)="login()"
          fxFlex>LOG IN</button>
      </mat-card-actions>
    </mat-card>
    <p class="copy">&copy; Green Coding {{getYear()}}</p>
  </form>
</div>