import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-product-attachment-auto',
  templateUrl: './product-attachment-auto.component.html',
  styleUrls: ['./product-attachment-auto.component.css']
})
export class ProductAttachmentAutoComponent implements OnInit {

  constructor() {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
      });
  }

  @Input() options: any;
  @Input() placeholder: any;
  @Input() key: string;
  @Input() AutoValue: string;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();
  @Output() finalValue = new EventEmitter();

  valueChanged() {
    this.finalValue.emit(this.AutoValue);
  }

  displayFn(id) {
    if (!id) { return ''}
    return id.name;
  }


  ngOnInit() {

  }

}
