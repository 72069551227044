import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  template: `
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    <div fxLayout="row">
      <mat-icon aria-label="Error Message icon" fxFlex="30" fxHide fxShow.gt-sm>{{data.icon}}</mat-icon>
      <p>{{data.message}}</p>
    </div>
  </div>
  <div mat-dialog-actions>
    <div fxFlex></div>
    <button mat-button (click)="onNoClick()">Ok</button>
  </div>
  `,
  styles: [
    'mat-icon { font-size:100px; color:red;text-align: center;}',
    'p {text-align: justify;min-height:100px;line-height: 1;}',
    'h1 {text-align: center;margin:0}'
]
})
export class ErrorMessageDialog {
  constructor(
    public dialogRef: MatDialogRef<ErrorMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
