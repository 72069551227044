<div class="bg">
  <div class="page">
    <div class="content">

      <div class="header" fxLayout="row">
        <div fxFlex>
          <p style="font-size: 2em;">{{company}}</p>
          <p>{{company_address}}</p>
          <p>{{company_tel}}</p>
        </div>
        <div fxFlex></div>
        <div *ngIf="false">
          <img [src]="base + 'image/logo.jpg' | authImage | async" style="width: 10em;">
        </div>
      </div>

      <h1>SUPPLIER RETURN NOTE</h1>

      <div fxLayout="row">
        <div fxLayout="column" fxLayoutAlign="start start">
          <p><strong>Return Code : </strong>{{row['Return Code']}}</p>
          <p><strong>Supplier : </strong>{{row['Supplier']}}</p>
          <p><strong>Reason : </strong> {{row['Reason']}}</p>
        </div>
        <div fxFlex></div>
        <div style="width: 25em;" fxLayout="column" fxLayoutAlign="start start">
          <p><strong>Return Date : </strong>{{row['Return Date']}}</p>
          <p><strong>Prepared By : </strong>{{row['Prepared By']}}</p>
        </div>
      </div>

      <br>

      <div *ngFor="let table of tables" class="table">

        <table style="width:100%">
          <tr>
            <th>#</th>
            <th style="text-align: left;">PRODUCT DESCRIPTION</th>
            <th class="number">COST PRICE</th>
            <th class="number">QTY</th>
            <th class="number">FREE ISSUES</th>
            <th class="number">COST AMOUNT</th>
          </tr>
          <tr *ngFor="let col of table.table">
            <td>{{col['#']}}</td>
            <td>{{col['Product Description']}}</td>
            <td class="number">{{col['Cost Price'] | currency : ' '}}</td>
            <td class="number">{{col['Return Qty']}}</td>
            <td class="number">{{col['Discount']}}</td>
            <td class="number">{{col['Cost Amount'] | currency : ' '}}</td>
          </tr>
        </table>

      </div>

      <br>

      <p style="text-align: center;"><strong>TOTAL QTY {{reduceArray(tables[0].table, 'Return Qty')}}</strong></p>

      <br>

      <p style="padding-right: 1em;"><strong>TOTAL COST AMOUNT
          {{reduceArray(tables[0].table, 'Cost Amount') | currency : ' '}}</strong></p>

      <div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 2em;">
        <div>
          <p>………………………………………………………………….......</p>
          <p style="text-align: center;padding-top: 1em;"><strong>CHECKED BY</strong></p>
        </div>
        <div fxFlex></div>
        <div>
          <p> ………………………………………………………………….......</p>
          <p style="text-align: center;padding-top: 1em;"><strong>AUTHORIZED BY</strong></p>
        </div>
      </div>


    </div>
  </div>

  <button mat-fab class="fabPrint" color="warn" (click)="print()">
    <mat-icon>print</mat-icon>
  </button>

</div>