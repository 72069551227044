<mat-form-field [appearance]="appearance" fxFlex>
  <input [disabled]="autoDisable" type="text" [placeholder]="placeholder" aria-label="Number" matInput [matAutocomplete]="auto"
    [(ngModel)]="AutoValue" (ngModelChange)="this.autoUpdate.next($event)">
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="valueChanged()" [displayWith]="displayFn">
    <mat-option *ngFor="let option of options | async" [value]="option">
      <div fxLayout="row" style="height: 3em;">
        <p style="margin: 0;">{{option.main_title}}</p>
        <div fxFlex></div>
        <p style="margin: 0;">{{option.sub_title}}</p>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>