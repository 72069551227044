import { SelectOutletComponent } from './../select-outlet/select-outlet.component';
import { GetGeoLocationService } from './../get-geo-location.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private locationService: GetGeoLocationService,
    public dialog: MatDialog) { }

  loading = false;
  dsblSubmit = false;
  hide = true;

  userForm = this.fb.group({
    company: [null, Validators.required],
    username: [null, Validators.required],
    password: [null, Validators.required]
  });

  ngOnInit() {
    if (localStorage.getItem('jwt') !== null) {
      if (Number(localStorage.getItem('verified')) === 0) {
        this.router.navigate(['activate']);
      } else {
        if (document.cookie.length !== 0) {
          this.router.navigate(['main/dashboard']);
        }
      }
    } else {
      this.loadCompany();
      this.router.navigate(['login']);
    }
  }

  loadCompany(): void {
    this.userForm.get('company').setValue(localStorage.company);
  }

  openOutlet(position: any): void {
    const dialogRef = this.dialog.open(SelectOutletComponent, {
      width: '250px',
      data: {
        company: this.userForm.get('company').value,
        username: this.userForm.get('username').value,
        password: this.userForm.get('password').value,
        location: position.lng + ',' + position.lat
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  selectOutlet(): void {
    this.locationService.getPosition().then(pos => {
      console.log(pos);
      this.openOutlet(pos);
    }).catch(e => {
      console.log(e);
      this.openOutlet({ lng: 0, lat: 0 });
    });
  }

  login() {
    localStorage.company = this.userForm.get('company').value;
    this.selectOutlet();
  }

  getYear() {
    const d = new Date();
    return d.getFullYear();
  }

}
