export enum Level {
	LOGIN = 0,
	LOGOUT = 1,
	VIEW = 2,
	LIST = 3,
	REPORTS = 4,
	NEW = 5,
	NEW_PRODUCT = 6,
	OPEN = 7,
	DELETE = 8,
	MODIFY = 9,
	SEARCH = 10,
	PRINT = 11,
	BACK = 12,
	SINHALA = 13,
	DISCOUNT = 14,
	AVGCOST = 15,
	MFD = 16,
	EXD = 17,
	PRICE = 18,
	NO_SCAN_ENT = 19,
	INTERNAL = 20,
	EXTERNAL = 21,
	LOCATION = 22,
	DISC_PRICE = 23,
	PRICE_EDIT = 24,
	NOTE_PANE = 25,
	PRINT_INVOICE = 26,
	TYPE_EDIT = 27,
	THREE_DECIMAL = 28,
	QTY_WINDOW = 29,
	HOLD_BILL = 30,
	OPEN_CODE = 31,
	EMAIL = 32,
	DECIMAL_POINTS = 33,
	IND_SALES_PERSON = 34,
	DATE = 35,
	MINUS = 36,
	WSP = 37,
	TAMIL = 38,
	VNo = 39,
	CARD_PERCENTAGE = 40,
	CASHFLOAT = 41,
	DK = 42,
	SPLIT_BNO = 43,
	AVOID_MINUS = 44,
	WARRANTY = 45,
	RENT = 46,
	REPORTS_TOTAL = 47,
	DELIVERY = 48,
	WEIGHT = 49,
	CODE = 50,
	BARCODE = 51,
	TYPE = 52,
	DESCRIPTION = 53,
	MULTILINGUAL = 54,
	ROL = 55,
	SALES_PRICE = 56,
	STOCK_QTY = 57,
	NOTIFY = 58,
	LOYALTY = 59,
	BRAND = 60,
	GENERIC = 61,
	PART_NO = 62,
	CONDITION = 63,
	MODEL = 64,
	MODEL_CODE = 65,
	MAKE = 66,
	YEAR = 67,
	AUTO_DISCOUNT = 68,
	BATCH = 69,
	AMOUNT_EDIT = 70,
	MANDATORY_NIC = 71,
	CATEGORY = 72,
	COLOR = 73,
	SIZE = 74,
	VARIATION = 75,
	COURIER = 76,
	PRINT_STICKER = 77,
	MASTER = 78,
	CODE_EDIT = 79,
	BARCODEA4 = 80,
	PARENT = 81,
	UTILITY = 82,
	SKU_MODE1 = 83,
	SKU_MODE2 = 84,
	COST = 85,
	ADVANCE_SEARCH = 86,
	PRINT_DELIVERY_NOTE = 87,
	EDIT_POPUP = 88,
	DUPLICATE = 89,
	MANDATORY_PHONE = 90,
	VAT = 91,
	ALLOW_ZERO_SP = 92,
	STOCK_ADD = 93,
	DEALER_PRICE = 94,
	SEARCH_BY_MAKE = 95,
	SEARCH_BY_GENERIC = 96,
	SERIAL = 97,
	NIC = 99,
	DOB = 100,
	ADDRESS = 101,
	CITY = 102,
	COUNTRY = 103,
	OCCUPATION = 104,
	CONTACT1 = 105,
	CONTACT2 = 106,
	OWNERSNAME = 107,
	RETURN_AMOUNT = 108,
	DELIVERY_CHARGE = 109,
	CARD_CHARGE = 110,
	FREE_ISSUE = 111,
	ADVANCE_PAYMENT = 112,
	SMS = 113,
	INDIVIDUAL = 114,
	MULTI_OUTLET = 115,
	COST_MARGIN = 116,
	PROFIT_MARGIN = 117,
	WAREHOUSE = 118,
}