import { ErrorMessageDialog } from './messages/error-message-dialog';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerServiceService } from './spinner-service.service';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements HttpInterceptor {

  constructor(private loaderService: SpinnerServiceService, private dialog: MatDialog) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const newRequest = req.clone({
      headers: req.headers.set('Authorization', localStorage.getItem('jwt') + ''),
    });
    this.showLoader();
    return next.handle(newRequest).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.onEnd();

        var re = new RegExp("jwt=([^;]+)");
        var value = re.exec(document.cookie);

        // if (value === null || value[1] === 'null') {
        //   document.cookie = 'jwt=' + localStorage.getItem('jwt');
        // } else if (value[1] !== localStorage.getItem('jwt')) {
        //    location.reload();
        // }

        // console.log(event.statusText);
      }
    },
      (err: any) => {
        this.onEnd();

        if (err.status === 400) {
          this.dialog.open(ErrorMessageDialog,
            {
              data:
              {
                title: err.statusText,
                content: err,
                message: 'Something Happened! Please notify to developers on Green Coding Inc.',
                color: 'red',
                icon: 'report_problem'
              },
              width: '500px'
            }
          );
        } else if (err.status === 401) {
          this.dialog.open(ErrorMessageDialog,
            {
              data:
              {
                title: err.statusText,
                content: err,
                message: 'Wrong Username or password. Please Check your user name and password or contact your administrator.',
                color: 'red',
                icon: 'account_box'
              },
              width: '500px'
            }
          );
        } else if (err.status === 409) {
          this.dialog.open(ErrorMessageDialog,
            {
              data:
              {
                title: err.statusText,
                content: err,
                message: 'User Already Logged On',
                color: 'red',
                icon: 'account_box'
              },
              width: '500px'
            }
          );
        } else if (err.status === 403) {
          this.dialog.open(ErrorMessageDialog,
            {
              data:
              {
                title: err.statusText,
                content: err,
                message: 'You do not have appropiate privileges to do required request. Please contact your administrator to attend it.',
                color: 'red',
                icon: 'block'
              },
              width: '500px'
            }
          );
        } else if (err.status === 498) {
          this.dialog.open(ErrorMessageDialog,
            {
              data:
              {
                title: err.error.title,
                content: err,
                message: err.error.message,
                color: 'red',
                icon: 'block'
              },
              width: '500px'
            }
          );
        }
      }));
  }

  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}

