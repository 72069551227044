<div class="bg">
  <div class="page pg_br">
    <div class="content">
      <div class="box" *ngFor="let item of barcodes; index as i;">
        <div class="product">
          <p>{{item['Order ID']}}</p>
        </div>
        <ngx-barcode class="barcode" bc-width="1" bc-height="40" [bc-value]="item['Order No']"
          [bc-display-value]="false">
        </ngx-barcode>

        <div class="curruncy">
          <p>{{item['Order No']}}</p>
        </div>
      </div>
      <div style="width: 2mm;"></div>
    </div>

  </div>

  <button mat-fab class="fabPrint" color="warn" (click)="print()">
    <mat-icon>print</mat-icon>
  </button>