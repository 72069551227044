import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NewCustomerComponent } from 'src/app/customer-management/new-customer/new-customer.component';
import { NewSalesPersonComponent } from '../new-sales-person/new-sales-person.component';

@Component({
  selector: 'app-sales-person-auto',
  templateUrl: './sales-person-auto.component.html',
  styleUrls: ['./sales-person-auto.component.css'],
})
export class SalesPersonAutoComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
  ) {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
      });
  }

  @Input() options: any;
  @Input() placeholder: any;
  @Input() key: string;
  @Input() AutoValue: string;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();
  @Output() finalValue = new EventEmitter();

  valueChanged() {
    this.finalValue.emit(this.AutoValue);
  }

  displayFn(id) {
    if (!id) { return '' }
    return id.employee;
  }

  clear() {
    this.AutoValue = '';
  }

  ngOnInit() {
  
  }

  addNewSalesPorson(event:any): void {
    event.stopPropagation();
    const selectedRowsByIDdialog = this.dialog.open(NewSalesPersonComponent, {
      width: "500px",
      height: "90vh",
    });

    selectedRowsByIDdialog.afterClosed().subscribe(res => {
      console.log(res);
    });
  }
}
