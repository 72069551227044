<mat-dialog-content class="mat-typography">
	<mat-card class="shipping-card">
		<mat-card-content>
			<div fxLayout="row">
				<input type="file" [hidden]="true" (change)="fileChangeEvent($event)" #fileUpload />

				<button mat-button fxFlex class="picButton" (click)="fileUpload.click()">
					<span>Select Image</span>
					<mat-icon aria-label="upload picture 1">photo_camera</mat-icon>
				</button>
			</div>
			<div fxLayout="row" *ngIf="defaultImage != ''">
				<div (click)="fileUpload.click()">
					<img [src]="defaultImage | authImage | async" style="width:100%">
				</div>
			</div>
			<div fxLayout="row">
				<image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
					[resizeToWidth]="resizeToWidth" format="jpeg" (imageCropped)="imageCropped($event)"
					(imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" fxFlex>
				</image-cropper>

				<div fxFlex>
					<img [src]="croppedImage | authImage | async" style="width:100%" />
				</div>
			</div>

		</mat-card-content>
	</mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-raised-button color="primary" (click)="uploadImage()" [disabled]="defaultImage != ''"
		fxFlex>Upload</button>
	<button mat-button mat-dialog-close>Cancel</button>
	<mat-progress-bar [mode]="progressMode" [value]="uploadValue" *ngIf="inserting"></mat-progress-bar>
</mat-dialog-actions>