import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GetServerDataServiceService } from 'src/app/get-server-data-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-brand',
  templateUrl: './new-brand.component.html',
  styleUrls: ['./new-brand.component.css']
})
export class NewBrandComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private server: GetServerDataServiceService,
    private toolTip: MatSnackBar,
    public dialogRef: MatDialogRef<NewBrandComponent>,) { }

  //[name, remarks, trends, order_by, user_id, brand_id]
  createBrand = this.fb.group({
    name: [null, Validators.required],
    remarks: null,
  });

  submit(): void {

    this.server.brand_insert(this.createBrand.value)
      .subscribe(res => {
        console.log(res);
        this.toolTip.open('Brand Created!', 'Ok', { duration: 3000 });
        this.dialogRef.close();
      }
      );
  }

  ngOnInit() {

  }

}
