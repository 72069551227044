import { Component, OnInit, ViewChild, Renderer2, HostListener } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Router } from "@angular/router";
import { GetServerDataServiceService } from "../get-server-data-service.service";
import { WindowEmitorsService } from "../window-emitors.service";
import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { MatDrawer } from "@angular/material/sidenav";
import { Socket } from 'ngx-socket-io';
import { UpdateService } from '../update.service';
import { CookieService } from '../cookie.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorMessageDialog } from '../messages/error-message-dialog';
import { SearchMenuComponent } from '../shared/search-menu/search-menu.component';

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.css"],
  animations: [
    trigger("fadeInUp", [
      state(
        "void",
        style({
          opacity: 0,
          transform: "translateY(-60px)"
        })
      ),
      transition("void <=> *", animate(130))
    ]),
    trigger("menuExpand", [
      state(
        "expand",
        style({
          'width': "4em",
        })
      ),
      state(
        "collapse",
        style({
          width: "auto"
        })
      ),
      transition('expand => collapse', [
        animate('0.2s')
      ]),
      transition('collapse => expand', [
        animate('0.2s')
      ]),
    ]),
    trigger("menuExpandLeft", [
      state(
        "expandLeft",
        style({
          'margin-left': "4em",
        })
      ),
      state(
        "collapseLeft",
        style({
          'margin-left': "0em"
        })
      ),
      transition('expandLeft => collapseLeft', [
        animate('0.2s')
      ]),
      transition('collapseLeft => expandLeft', [
        animate('0.2s')
      ]),
    ]),
    trigger("flip", [
      state(
        "flipNeg",
        style({
          transform: "scaleX(-1)"
        })
      ),
      state(
        "flipPos",
        style({
          transform: "scaleX(1)"
        })
      ),
      transition('flipNeg => flipPos', [
        animate('0.2s')
      ]),
      transition('flipPos => flipNeg', [
        animate('0.2s')
      ]),
    ]),

  ]
})
export class MainLayoutComponent implements OnInit {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private server: GetServerDataServiceService,
    private windowEmitors: WindowEmitorsService,
    private cookies: CookieService,
    private socket: Socket,
    private update: UpdateService,
    private renderer: Renderer2,
    private dialog: MatDialog
  ) {
    windowEmitors.toggleTopMenu$.subscribe((e: any) => {
      this.toggleToolbar(e);
    });
    windowEmitors.toggleSideMenu$.subscribe((e: any) => {
      this.toggleMenuBar(e);
    });
    this.userInactive.subscribe(() => {

      const dialogRef = this.dialog.open(ErrorMessageDialog,
        {
          data:
          {
            title: 'Auto Logout',
            content: 'err',
            message: 'No User Activity for 30 minutes. System will logout now',
            color: 'yellow',
            icon: 'report'
          },
          width: '500px'
        }
      );

      this.userInactiveShowed = true;

      dialogRef.afterClosed().subscribe(result => {
        this.logout();
      });

    });
  }

  userActivity;
  userInactive: Subject<any> = new Subject();
  userInactiveShowed = false;
  searchBoxOpened = false;

  @ViewChild("drawer") drawer: MatDrawer;

  showToolbar = true;
  expandSideBar = true;

  // @HostListener('window:mousemove') refreshUserState() {
  //   clearTimeout(this.userActivity);
  //   if((this.userInactiveShowed === false) && (window.location.pathname !== '/login')) {
  //     this.setTimeout();
  //   }
  // }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 30 * 60 * 1000);
  }

  toggleToolbar(e: any) {
    if (e == "close") {
      this.showToolbar = false;
    } else {
      this.showToolbar = true;
    }
  }

  toggleSideBarWidth(): void {
    this.expandSideBar = !this.expandSideBar;
  }

  toggleMenuBar(e: any) {
    if (e == "close") {
      this.drawer.close();
    } else {
      this.drawer.open();
    }
  }

  sideBarClosed() {
    localStorage.sidebarStatus = "Closed";
  }

  sideBarOpened() {
    localStorage.sidebarStatus = "Opened";
  }

  getSideNavStatus() {
    if (localStorage.sidebarStatus) {

      if (localStorage.sidebarStatus === "Opened") {
        return true;
      } else {
        return false;
      }

    } else {
      return false;
    }
  }

  toggleSideBar() {
    this.drawer.toggle().then(e => {
      this.windowEmitors.sideBarToggled('');
    })
  }

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  branch = "";
  syncTime = "";

  ngOnInit() {
    this.branch = localStorage.branch;
    this.getSyncTime();
    // if (this.cookies.getCookie('jwt').length === 0) {
    //   this.router.navigate(["/login"]);
    // }
    this.sendDataToSocket({ jwt: localStorage.jwt });
  }

  logout() {
    this.socket.emit("logout", { jwt: localStorage.jwt });
    localStorage.removeItem("jwt");
    localStorage.removeItem("uid");
    localStorage.removeItem("username");
    localStorage.removeItem("branch");
    localStorage.removeItem("access");
    this.cookies.eraseCookie('jwt');
    this.router.navigate(["/login"]);
  }

  closeSideBar() {
    this.drawer.close();
  }

  openSideBar() {
    this.drawer.open();
  }

  getSyncTime() {
    this.server.get_sync_time().subscribe(res => {
      this.syncTime = res.moment;
    });
  }

  getCompanyName() {
    return localStorage.company;
  }

  sendDataToSocket(data: any) {
    this.socket.emit(this.server.base() + "dashboard", data);
  }

  loadState(name: string) {
    if (localStorage[name]) {
      return JSON.parse(localStorage[name]) && this.expandSideBar === true;
    } else {
      localStorage[name] = 'false'
    }
  }

  saveState(name: string, value: boolean = false) {
    localStorage[name] = JSON.stringify(value);
  }

  searchBox() {

    if (!this.searchBoxOpened) {
      this.searchBoxOpened = true;
      const dialogRef = this.dialog.open(SearchMenuComponent, {
        position: {
          top: "1em"
        },
        width: "100%"
      });

      dialogRef.afterClosed().subscribe(result => {
        this.searchBoxOpened = false;
      });
    }

  }

}
