import { Level } from './Level';
import { Module } from './Module';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appPrivileage]'
})
export class PrivileageDirective implements OnInit{

  @Input() appPrivileage: string;

  constructor(private el: ElementRef) {}

  ngOnInit(){
    this.hideElement();
  }

  hideElement() {
    const access = JSON.parse(localStorage.access);
    let hide = true;

    const userPermissions = access.map(a => {
      return Level[a.l] + ' ' + Module[a.m];
    });

    if (userPermissions.find(el => el === this.appPrivileage)) {
      hide = false;
    }

    if (hide) {
      if(this.el.nativeElement.style){
        this.el.nativeElement.style.display = 'none';
      }
    }
  }

}
