import { PrintViewComponent } from './print-view/print-view.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { FullCalanderViewComponent } from './full-calander-view/full-calander-view.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrintViewDayEndComponent } from './print-view-day-end/print-view-day-end.component';
import { PrintViewInvoiceComponent } from './print-view-invoice/print-view-invoice.component';
import { PrintViewMembersComponent } from './print-view-members/print-view-members.component';
import { PrintViewPaymentVoucherPettyCashComponent } from './print-view-payment-voucher-petty-cash/print-view-payment-voucher-petty-cash.component';
import { PrintViewPaymentVoucherComponent } from './print-view-payment-voucher/print-view-payment-voucher.component';
import { PrintViewReceiptComponent } from './print-view-receipt/print-view-receipt.component';
import { PrintViewServiceCreditorComponent } from './print-view-service-creditor/print-view-service-creditor.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrintViewInvoiceSmallComponent } from './print-view-invoice-small/print-view-invoice-small.component';
import { PrintViewPaymentVoucherBillComponent } from './print-view-payment-voucher-bill/print-view-payment-voucher-bill.component';
import { PrintViewDispatchPrintComponent } from './print-view-dispatch-print/print-view-dispatch-print.component';
import { PrintViewDispatchPrint100x150S2Component } from './print-view-dispatch-print100x150-s2/print-view-dispatch-print100x150-s2.component';
import { PrintViewSupplierReturnItemWiseDialogComponent } from './print-view-supplier-return-item-wise-dialog/print-view-supplier-return-item-wise-dialog.component';
import { PrintViewSalesOrderComponent } from './print-view-sales-order/print-view-sales-order.component';
import { PrintViewBarcodePrintComponent } from './print-view-barcode-print/print-view-barcode-print.component';
import { PrintViewShippedOrdersBarcodeComponent } from './print-view-shipped-orders-barcode/print-view-shipped-orders-barcode.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'printView',
    component: PrintViewComponent,
  },
  {
    path: 'printViewDayEnd',
    component: PrintViewDayEndComponent
  },
  {
    path: 'printViewInvoice',
    component: PrintViewInvoiceComponent
  },
  {
    path: 'printViewInvoiceSmall',
    component: PrintViewInvoiceSmallComponent
  },
  {
    path: 'PrintViewMembers',
    component: PrintViewMembersComponent
  },
  {
    path: 'printViewPaymentVoucherPettyCash',
    component: PrintViewPaymentVoucherPettyCashComponent
  },
  {
    path: 'printViewPaymentVoucher',
    component: PrintViewPaymentVoucherComponent
  },
  {
    path: 'printViewReceipt',
    component: PrintViewReceiptComponent
  },
  {
    path: 'printViewServiceCreditor',
    component: PrintViewServiceCreditorComponent
  },
  {
    path: 'printViewPaymentVoucherBill',
    component: PrintViewPaymentVoucherBillComponent
  },
  {
    path: 'printViewDispatchPrint',
    component: PrintViewDispatchPrintComponent
  },
  {
    path: 'printViewDispatchPrint100x150S2',
    component: PrintViewDispatchPrint100x150S2Component
  },
  {
    path: 'printViewSupplierReturnItemWiseDialog',
    component: PrintViewSupplierReturnItemWiseDialogComponent
  },
  {
    path: 'printViewSalesOrder',
    component: PrintViewSalesOrderComponent
  },
  {
    path: 'printViewBarcodePrintComponent',
    component: PrintViewBarcodePrintComponent
  },
  {
    path: 'PrintViewShippedOrdersBarcodeComponent',
    component: PrintViewShippedOrdersBarcodeComponent
  },
  {
    path: 'activate',
    component: ActivateAccountComponent
  },
  {
    path: 'main',
    component: MainLayoutComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'printView', component: PrintViewComponent },
      { path: 'full-calander-view', component: FullCalanderViewComponent },
      {
        path: 'user-management',
        loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'image-upload',
        loadChildren: () => import('./image-upload/image-upload.module').then(m => m.ImageUploadModule)
      },
      {
        path: 'file-upload',
        loadChildren: () => import('./file-upload/file-upload.module').then(m => m.FileUploadModule)
      },
      {
        path: 'web-settings',
        loadChildren: () => import('./web-settings/web-settings.module').then(m => m.WebSettingsModule)
      },
      {
        path: 'product-entey',
        loadChildren: () => import('./product-entey/product-entey.module').then(m => m.ProductEnteyModule)
      },
      {
        path: 'delivery-management',
        loadChildren: () => import('./delivery-management/delivery-management.module').then(m => m.DeliveryManagementModule)
      },
      {
        path: 'stock-management',
        loadChildren: () => import('./stock-management/stock-management.module').then(m => m.StockManagementModule)
      },
      {
        path: 'rent-management',
        loadChildren: () => import('./rent-management/rent-management.module').then(m => m.RentManagementModule)
      },
      {
        path: 'report-management',
        loadChildren: () => import('./report-management/report-management.module').then(m => m.ReportManagementModule)
      },
      {
        path: 'sales-reports',
        loadChildren: () => import('./sales-reports/sales-reports.module').then(m => m.SalesReportsModule)
      },
      {
        path: 'sales-management',
        loadChildren: () => import('./sales-management/sales-management.module').then(m => m.SalesManagementModule)
      },
      {
        path: 'promotion-management',
        loadChildren: () => import('./promotion-management/promotion-management.module').then(m => m.PromotionManagementModule)
      },
      {
        path: 'customer-management',
        loadChildren: () => import('./customer-management/customer-management.module').then(m => m.CustomerManagementModule)
      },
      {
        path: 'taylor-management',
        loadChildren: () => import('./taylor-management/taylor-management.module').then(m => m.TaylorManagementModule)
      },
      {
        path: 'membership-management',
        loadChildren: () => import('./membership-management/membership-management.module').then(m => m.MembershipManagementModule)
      },
      {
        path: 'account-management',
        loadChildren: () => import('./account-management/account-management.module').then(m => m.AccountManagementModule)
      }
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
