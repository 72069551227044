
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetServerDataServiceService } from '../get-server-data-service.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  templateUrl: 'html-text-editor.html',
  styles: [
    '.mat-card { box-shadow: none; }',
    ':host ::ng-deep .ck-editor__editable_inline {min-height: 300px;}'
  ]
})

export class HtmlTextEditorComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<HtmlTextEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private server: GetServerDataServiceService,
    private toolTip: MatSnackBar) { }

  public Editor = ClassicEditor;

  inserting = false;
  progressMode = 'Indeterminate';

  ngOnInit() {

  }

  EditHtml() {

    this.server.update_product_html(this.data.row).subscribe(res => {
      console.log(res);
      this.dialogRef.close();
    });
  }

}
