<mat-card>
  <mat-card-content class="card">
    <div class="icon"><mat-icon [ngStyle]="{'color':iconColor}">{{icon}}</mat-icon></div>
    <div class="heading">
      <p>{{heading}}</p>
      <h2>{{amount}}</h2>
    </div>
    <div class="subHeading" *ngIf="false">
      <div class="subicon"><mat-icon>{{icon}}</mat-icon></div>
      <p>{{subHeading}}</p>
    </div>
  </mat-card-content>
</mat-card>