import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { GetServerDataServiceService } from "src/app/get-server-data-service.service";
import { WindowEmitorsService } from "src/app/window-emitors.service";

@Component({
  selector: "app-select-customer",
  templateUrl: "./select-customer.component.html",
  styleUrls: ["./select-customer.component.css"]
})
export class SelectCustomerComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SelectCustomerComponent>,
    private server: GetServerDataServiceService,
    private windowEmitors: WindowEmitorsService,
    private toolTip: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  select(contact: any): void {
    this.windowEmitors.reloadInvoiceData(contact);
    this.dialogRef.close();
  }

  ngOnInit() {}
}
