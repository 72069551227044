<mat-card [ngStyle]="{'background-image': bgImage}" #card>
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row">
        <h2 *ngIf="amount !== 0 && !showAmount" (click)="toggleAmount()">Rs. {{amount | shortNumberPipe}}</h2>
        <h2 *ngIf="amount !== 0 && showAmount" (click)="toggleAmount()">Rs. {{amount | currency : ' '}}</h2>
        <h2 *ngIf="amount === 0">Rs. 0.00</h2>
        <div fxFlex></div>
        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu_btn">
          <mat-icon style="color: white">more_vert</mat-icon>
        </button>
      </div>
      <div class="heading">
        <p>{{selected}} {{heading}}</p>
      </div>

      <ngx-charts-line-chart class="min_chart" [scheme]="colorScheme" [legend]="legend"
        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis"
        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [timeline]="timeline" [results]="multi" [animations]="false"
        [view]="view" [rangeFillOpacity]="'0.5'">
      </ngx-charts-line-chart>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="changeSelected('Today')">
          <span>Today</span>
        </button>
        <button mat-menu-item (click)="changeSelected('Yesterday')">
          <span>Yesterday</span>
        </button>
        <button mat-menu-item (click)="changeSelected('This Week')">
          <span>This Week</span>
        </button>
        <button mat-menu-item (click)="changeSelected('Last Week')">
          <span>Last Week</span>
        </button>
        <button mat-menu-item (click)="changeSelected('This Month')">
          <span>This Month</span>
        </button>
        <button mat-menu-item (click)="changeSelected('Last Month')">
          <span>Last Month</span>
        </button>
      </mat-menu>

    </div>
  </mat-card-content>
</mat-card>