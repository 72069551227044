<div class="page">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center stretch">
        <p style="text-align: center;">From {{fromDate | date}} To {{toDate | date}} [{{this_barnch}}]</p>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center stretch"
        fxLayoutGap="0.5em">
        <div fxLayout="column" fxFlex class="text-center">
            <div fxLayout="row">
                <p class="darck_bg">opening balance</p>
                <p class="value" fxFlex>{{day_end_opening_balance | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg">exchanges</p>
                <p class="value" fxFlex>{{day_end_exchanges | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg">cancellations</p>
                <p class="value" fxFlex>{{day_end_cancellations | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg">expense</p>
                <p class="value" fxFlex>{{day_end_expense | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg">discounts</p>
                <p class="value" fxFlex>{{day_end_discounts | currency : " "}}</p>
            </div>
        </div>
        <div fxLayout="column" fxFlex class="text-center">
            <div fxLayout="row">
                <p class="darck_bg">discount crn</p>
                <p class="value" fxFlex>{{day_end_discount_crn | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg">discount drn</p>
                <p class="value" fxFlex>{{day_end_discount_drn | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg">return crn</p>
                <p class="value" fxFlex>{{day_end_return_crn | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg">return drn</p>
                <p class="value" fxFlex>{{day_end_return_drn | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg">direct sales</p>
                <p class="value" fxFlex>{{day_end_direct_sales | currency : " "}}</p>
            </div>
        </div>
        <div fxLayout="column" fxFlex class="text-center">
            <div fxLayout="row">
                <p class="darck_bg">delivery sales</p>
                <p class="value" fxFlex>{{day_end_delivery_sales | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg">delivery charges</p>
                <p class="value" fxFlex>{{day_end_delivery_charges | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg">repairs</p>
                <p class="value" fxFlex>{{day_end_repairs | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg"></p>
                <p class="value"></p>
            </div>
            <div fxLayout="row">
                <p class="darck_bg"></p>
                <p class="value"></p>
            </div>
        </div>
    </div>

    <div class="space"></div>

    <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center stretch"
        fxLayoutGap="0.5em">
        <div fxLayout="column" style="width: 100%" fxFlex class="text-center">
            <div fxLayout="row">
                <p class="darck_hr" fxFlex>Type</p>
                <p class="darck_hr" fxFlex style="text-align: right">Sales</p>
                <p class="darck_hr" fxFlex style="text-align: right">Payment</p>
                <p class="darck_hr" fxFlex style="text-align: right">Delivery</p>
                <p class="darck_hr" fxFlex style="text-align: right">Return</p>
                <p class="darck_hr" fxFlex style="text-align: right">Total</p>
                <p class="darck_hr" fxFlex style="text-align: right">Variance</p>
            </div>
            <div fxLayout="row">

                <p class="value_tr" fxFlex>cash</p>
                <p class="value_tr number" fxFlex>{{day_end_cash_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_cash_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_cash_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_cash_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_cash_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_cash_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>amex</p>
                <p class="value_tr number" fxFlex>{{day_end_amex_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_amex_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_amex_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_amex_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_amex_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_amex_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>visa/master</p>
                <p class="value_tr number" fxFlex>{{day_end_visa_master_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_visa_master_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_visa_master_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_visa_master_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_visa_master_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_visa_master_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>frimi</p>
                <p class="value_tr number" fxFlex>{{day_end_frimi_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_frimi_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_frimi_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_frimi_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_frimi_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_frimi_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>genie</p>
                <p class="value_tr number" fxFlex>{{day_end_genie_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_genie_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_genie_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_genie_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_genie_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_genie_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>webxpay</p>
                <p class="value_tr number" fxFlex>{{day_end_webxpay_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_webxpay_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_webxpay_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_webxpay_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_webxpay_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_webxpay_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>{{day_end_accounts[0].account}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac01_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac01_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac01_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac01_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac01_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac01_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>{{day_end_accounts[1].account}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac02_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac02_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac02_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac02_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac02_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac02_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>{{day_end_accounts[2].account}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac03_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac03_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac03_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac03_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac03_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac03_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>{{day_end_accounts[3].account}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac04_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac04_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac04_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac04_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac04_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_ac04_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>credit</p>
                <p class="value_tr number" fxFlex>{{day_end_credit_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_credit_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_credit_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_credit_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_credit_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_credit_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr" fxFlex>cheque</p>
                <p class="value_tr number" fxFlex>{{day_end_cheque_sale | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_cheque_payment | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_cheque_delivery | currency : " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_cheque_return | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_cheque_total | currency: " "}}</p>
                <p class="value_tr number" fxFlex>{{day_end_cheque_variance | currency: " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="darck_hr" fxFlex>Total</p>
                <p class="darck_hr" fxFlex style="text-align: right">{{total_sales | currency: " "}}</p>
                <p class="darck_hr" fxFlex style="text-align: right">{{total_payment | currency: " "}}</p>
                <p class="darck_hr" fxFlex style="text-align: right">{{total_delivery | currency: " "}}</p>
                <p class="darck_hr" fxFlex style="text-align: right">{{total_return | currency: " "}}</p>
                <p class="darck_hr" fxFlex style="text-align: right">{{total_total | currency: " "}}</p>
                <p class="darck_hr" fxFlex style="text-align: right" fxFlex>{{total_variance | currency: " "}}</p>
            </div>
        </div>
    </div>

    <div class="space"></div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="0.5em">
        <div fxLayout="column" style="width: 100%" fxFlex class="text-center">
            <div fxLayout="row">
                <p class="darck_hr" fxFlex style="text-align: center;">cashier balance</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon></mat-icon>
                </p>
                <p class="value_tr" style="font-weight: bold;" fxFlex>Notes</p>
                <p class="value_tr number inputContainer_hr" style="font-weight: bold;" fxFlex>NOS</p>
                <p class="value_tr number" style="font-weight: bold;" fxFlex>Amount</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>5000</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="day_end_5000_nos"></p>
                <p class="value_tr number" fxFlex>{{day_end_5000_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>1000</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="day_end_1000_nos"></p>
                <p class="value_tr number" fxFlex>{{day_end_1000_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>500</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="day_end_500_nos"></p>
                <p class="value_tr number" fxFlex>{{day_end_500_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>100</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="day_end_100_nos"></p>
                <p class="value_tr number" fxFlex>{{day_end_100_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>50</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="day_end_50_nos"></p>
                <p class="value_tr number" fxFlex>{{day_end_50_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>20</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="day_end_20_nos"></p>
                <p class="value_tr number" fxFlex>{{day_end_20_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>10</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="day_end_10_nos"></p>
                <p class="value_tr number" fxFlex>{{day_end_10_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>coins</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="day_end_coins_nos"></p>
                <p class="value_tr number" fxFlex>{{day_end_coins_amount | currency : " "}}</p>
            </div>

            <div fxLayout="row">
                <p class="darck_hr icon_tr">
                    <mat-icon></mat-icon>
                </p>
                <p class="darck_hr" fxFlex>Total</p>
                <p class="darck_hr number inputContainer_hr" fxFlex>{{day_end_coins_nos_total | currency : " "}}</p>
                <p class="darck_hr number" fxFlex>{{day_end_coins_amount_total | currency : " "}}</p>
            </div>

        </div>

        <div fxLayout="column" style="width: 100%" fxFlex class="text-center">
            <div fxLayout="row">
                <p class="darck_hr" fxFlex style="text-align: center;">drawer balance</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon></mat-icon>
                </p>
                <p class="value_tr" style="font-weight: bold;" fxFlex>Notes</p>
                <p class="value_tr number inputContainer_hr" style="font-weight: bold;" fxFlex>NOS</p>
                <p class="value_tr number" style="font-weight: bold;" fxFlex>Amount</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>5000</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="db_day_end_5000_nos"></p>
                <p class="value_tr number" fxFlex>{{db_day_end_5000_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>1000</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="db_day_end_1000_nos"></p>
                <p class="value_tr number" fxFlex>{{db_day_end_1000_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>500</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="db_day_end_500_nos"></p>
                <p class="value_tr number" fxFlex>{{db_day_end_500_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>100</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="db_day_end_100_nos"></p>
                <p class="value_tr number" fxFlex>{{db_day_end_100_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>50</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="db_day_end_50_nos"></p>
                <p class="value_tr number" fxFlex>{{db_day_end_50_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>20</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="db_day_end_20_nos"></p>
                <p class="value_tr number" fxFlex>{{db_day_end_20_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>10</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="db_day_end_10_nos"></p>
                <p class="value_tr number" fxFlex>{{db_day_end_10_amount | currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon>money</mat-icon>
                </p>
                <p class="value_tr" fxFlex>coins</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="db_day_end_coins_nos"></p>
                <p class="value_tr number" fxFlex>{{db_day_end_coins_amount | currency : " "}}</p>
            </div>

            <div fxLayout="row">
                <p class="darck_hr icon_tr">
                    <mat-icon></mat-icon>
                </p>
                <p class="darck_hr" fxFlex>Total</p>
                <p class="darck_hr number inputContainer_hr" fxFlex>{{db_day_end_coins_nos_total | currency : " "}}</p>
                <p class="darck_hr number" fxFlex>{{db_day_end_coins_amount_total | currency : " "}}</p>
            </div>

        </div>

        <div fxLayout="column" fxFlex class="text-center">
            <div fxLayout="row">
                <p class="darck_hr" fxFlex style="text-align: center;">accounts balance</p>
            </div>
            <div fxLayout="row">
                <p class="value_tr icon_tr">
                    <mat-icon></mat-icon>
                </p>
                <p class="value_tr" style="font-weight: bold;text-align: center;" fxFlex>account no</p>
                <p class="value_tr number inputContainer_hr" style="font-weight: bold;text-align: center !important;"
                    fxFlex>balance</p>
            </div>
            <div fxLayout="row" *ngFor="let item of day_end_accounts">
                <p class="value_tr" fxFlex>{{item.account}}</p>
                <p class="value_tr number inputContainer" fxFlex><input type="number" (input)="update()"
                        [(ngModel)]="item.value"></p>
            </div>

            <div fxLayout="row">
                <p class="darck_hr" fxFlex>Total</p>
                <p class="darck_hr number inputContainer_hr" fxFlex>{{day_end_accounts_total.value | currency : " "}}
                </p>
            </div>

            <div class="space"></div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center stretch" style="height: 100%;">
                <div fxLayout="column" style="width: 100%" fxFlex class="text-center">
                    <div fxLayout="row">
                        <p class="darck_hr" fxFlex style="text-align: center;">Note 1</p>
                    </div>
                    <div fxLayout="row">
                        <p class="value_tr number inputContainer" fxFlex>
                            <textarea name="" id="" cols="30" rows="4" [(ngModel)]="day_end_note_1"></textarea>
                        </p>
                    </div>
                </div>

                <div fxLayout="column" style="width: 100%" fxFlex class="text-center">
                    <div fxLayout="row">
                        <p class="darck_hr" fxFlex style="text-align: center;">Note 2</p>
                    </div>
                    <div fxLayout="row">
                        <p class="value_tr number inputContainer" fxFlex>
                            <textarea name="" id="" cols="30" rows="4" [(ngModel)]="day_end_note_2"></textarea>
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="space"></div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center stretch"
        fxLayoutGap="0.5em">
        <div fxLayout="column" fxFlex class="text-center">
            <div fxLayout="row">
                <p class="darck_bg" style="text-align: center !important;" fxFlex>TOTAL CASH DEPOSITED TO CASH ACCOUNT HEAD OFFICE</p>
                <p class="value" style="text-align: center !important;" fxFlex>{{ day_end_total_cash| currency : " "}}</p>
            </div>
            <div fxLayout="row">
                <p class="value" style="text-align: center !important;" fxFlex>{{today | date}} [{{this_username}}]</p>
                <p class="value" style="text-align: center !important;" fxFlex>{{ day_end_total_cost| currency : " "}}</p>
            </div>
        </div>
    </div>

    <div class="loading" *ngIf="loading"></div>
</div>