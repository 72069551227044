import { Component, OnInit } from '@angular/core';
import { GetServerDataServiceService } from '../get-server-data-service.service';

@Component({
  selector: 'app-print-view-barcode-print',
  templateUrl: './print-view-barcode-print.component.html',
  styleUrls: ['./print-view-barcode-print.component.css']
})
export class PrintViewBarcodePrintComponent implements OnInit {

  constructor(
    private server: GetServerDataServiceService
  ) { }

  barcodes = [];
  printStyle = "40x20 2Up"
  currency = "LKR";
  showCost = false;
  keys = 'G-1,O-2,L-3,D-4,K-5,E-6,Y-7,P-8,I-9,N-0';

  ngOnInit(): void {

    if (localStorage.printViewBarcodePrintComponent) {
      const data = JSON.parse(localStorage.printViewBarcodePrintComponent);
      this.barcodes = data.sendRows;
      this.printStyle = data.settings.printStyle;
    }
    this.server.getSetting('product-barcode-print-currency').subscribe(res => {
      if (res.length !== 0) {
        this.currency = res[0].value;
      }
    });
    this.server.getSetting('barcode-print-cost-show').subscribe(res => {
      if (res.length !== 0) {
        this.showCost = res[0].value;
      }
    });

    this.server.getSetting('product-barcode-print-cipher-cost').subscribe(res => {

      if (res.length !== 0) {
        this.keys = res[0].value;
      }

      const cpKey = new Array(10);
      this.keys.split(',').map(e => {
        cpKey[e.split('-')[1]] = e.split('-')[0];
      });

      this.barcodes = this.barcodes.map((e: any) => {
        e.cpCost = this.encript(e.Cost_Price, cpKey);
        return e;
      });
    });

  }

  encript(number: string, cpKey: any) {
    return number.toString().split('').map(e => cpKey[e]).join('');
  }

  print(): void {
    window.print();
  }

  ngAfterViewInit(): void {
    // window.print();
    // window.onafterprint = () => {
    //   window.close();
    // }
  }

}
