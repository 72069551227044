<div class="bg">
  <div class="page">
    <div class="content">
      <h1>Receipt</h1>

      <div fxLayout="row">
        <img [src]="base + 'image/logo.jpg' | authImage | async" [alt]="company_name" width="150" height="45">
        <div fxFlex>
        </div>

        <div fxLayout="column">
          <p><strong>Voucher No :</strong> {{VoucherNo}}</p>
          <p><strong>Date :</strong> {{date}}</p>
        </div>
      </div>
      <div fxLayout="row">
        <div class="borderBox" style="width: 10em;">
          <p><strong>Customer Name</strong></p>
        </div>
        <div fxFlex class="borderBox">
          <p>{{Customer}}</p>
        </div>
      </div>
      <div fxLayout="row">
        <div class="borderBox" style="width: 10em;">
          <p><strong>Payment Mode</strong></p>
        </div>
        <div fxFlex class="borderBox">
          <p>{{paidMode}}</p>
        </div>
      </div>
      <br>
      <div fxLayout="row">
        <div fxFlex="75" class="borderBox" style="height: 80px;">
          <p><strong>Amount in words:</strong></p>
          <p>{{amountInWords}}</p>
        </div>
        <div fxFlex="25" class="borderBox" style="height: 80px;">
          <p><strong>Amount (LKR):</strong></p>
          <p>{{amount|currency: ' '}}</p>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex class="borderBox">
          <p><strong>Being Payment for </strong>{{grnNos}}</p>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex class="borderBox">
          <p><strong>Remarks</strong></p>
        </div>
      </div>
      <div fxLayout="row">
        <div fxFlex="33.33" class="borderBox" style="height: 80px;">
          <p><strong>Prepared:</strong> {{username}}</p>
          <p>………..…/............../………..…</p>
        </div>
        <div fxFlex="33.33" class="borderBox" style="height: 80px;">
          <p><strong>Checked:</strong></p>
          <p>………..…/............../………..…</p>
        </div>
        <div fxFlex="33.33" class="borderBox" style="height: 80px;">
          <p><strong>Authorized:</strong></p>
          <p>………..…/............../………..…</p>
        </div>
      </div>
      <br>
      <div fxLayout="row">
        <div fxFlex="75" class="borderBox" style="height: 190px;">
          <p><strong>Receipt:</strong></p>
          <p>Received the above sum with thanks, from {{company_name}}</p>
          <div fxLayout="row">
            <div fxFlex>
              <p>……………………………………….. </p>
              <p><strong>Signature</strong></p>
              <p><strong>NIC No:</strong></p>
            </div>
            <div FxFlex>
              <p><strong>Name:</strong> ………………………………………………………………….......</p>
              <p><strong>Date:</strong> {{date}}</p>
            </div>
          </div>
        </div>
        <div fxFlex="25" class="borderBox" style="height: 190px;">

        </div>
      </div>

    </div>
  </div>

  <button mat-fab class="fabPrint" color="warn" (click)="print()">
    <mat-icon>print</mat-icon>
  </button>

</div>