<mat-form-field fxFlex class="description">
	<input type="text" [placeholder]="placeholder" aria-label="Number" matInput [matAutocomplete]="auto"
		[(ngModel)]="AutoValue" (ngModelChange)="this.autoUpdate.next($event)" #autoInput
		(keyup.enter)="chooseFirstOption()">
		<mat-icon style="color: red;" (click)="clear()" matSuffix>close</mat-icon>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="valueChanged()" [displayWith]="displayFn" #auto>
		<mat-option *ngFor="let option of options | async" [value]="option">
			<p>{{option.description}} ({{option.code}})</p>
			<div fxLayout="row" style="height: 1em;">
				<p style="margin: 0;" fxFlex class="blue"><small>Marked Price: {{option.sp | currency : ' '}}</small>
				</p>
				<p style="margin: 0;" fxFlex class="green" fxShow fxHide.xs><small>Retail Price : {{option.sp | currency : ' '}}</small>
				</p>
				<p style="margin: 0;" fxFlex class="ash"><small>Available Quantity: {{option.sqty}}</small></p>
			</div>
		</mat-option>
	</mat-autocomplete>
</mat-form-field>