<div class="chart">
  <h3>Product Sales</h3>
  <div class="legend" fxLayout="row">
    <p><span class="green"></span>{{chartALable}}</p>
    <p><span class="blue"></span>{{chartBLable}}</p>
    <div fxFlex></div>
    <div fxFlex></div>
    <mat-form-field fxFlex class="select">
      <mat-select [(ngModel)]="Dtype" (selectionChange)="loadData()">
        <mat-option value="TodayvsYesterday">Today vs Yesterday</mat-option>
        <mat-option value="ThisWeekvsLastWeek">This Week vs Last Week</mat-option>
        <mat-option value="ThisMonthvsLastmonth">This Month vs Last month</mat-option>
        <mat-option value="ThisYearvsLastYear">This Year vs Last Year</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <x-chartist class="chartArea" [type]="type" [data]="data" [options]="options" [events]="events"></x-chartist>
  <svg class="hide" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="greenGradient" x1="0" y1="1" x2="1" y2="0">
        <stop offset="0" stop-color="rgba(0, 201, 255, 1)"></stop>
        <stop offset="1" stop-color="rgba(146, 254, 157, 1)"></stop>
      </linearGradient>
    </defs>
  </svg>

  <svg class="hide" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="blueGradient" x1="0" y1="1" x2="1" y2="0">
        <stop offset="0" stop-color="rgba(132, 60, 247, 1)"></stop>
        <stop offset="1" stop-color="rgba(56, 184, 242, 1)"></stop>
      </linearGradient>
    </defs>
  </svg>
</div>