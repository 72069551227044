import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { GetServerDataServiceService } from '../get-server-data-service.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-print-view-day-end',
  templateUrl: './print-view-day-end.component.html',
  styleUrls: ['./print-view-day-end.component.css']
})
export class PrintViewDayEndComponent implements OnInit {

  constructor(
    private server: GetServerDataServiceService,
    private dialog: MatDialog,
    private toolTip: MatSnackBar,
    private router: Router,
    @Inject(LOCALE_ID) private locale: string
  ) {
  }

  displayedColumns: string[] = [
    '_id',
    'Items',
    'Bill No',
    'Customer',
    'Sales Person',
    'Invoice Type',
    'Reference',
    'Net Amount',
    'Date'
  ];

  loading = false;

  fromDate = new Date();
  toDate = new Date();
  day_end_opening_balance = 0;
  day_end_exchanges = 0;
  day_end_cancellations = 0;
  day_end_expense = 0;
  day_end_discounts = 0;
  day_end_discount_crn = 0;
  day_end_discount_drn = 0;
  day_end_return_crn = 0;
  day_end_return_drn = 0;
  day_end_direct_sales = 0;
  day_end_delivery_sales = 0;
  day_end_delivery_charges = 0;
  day_end_repairs = 0;

  day_end_cash_sale = 0;
  day_end_amex_sale = 0;
  day_end_visa_master_sale = 0;
  day_end_frimi_sale = 0;
  day_end_genie_sale = 0;
  day_end_webxpay_sale = 0;
  day_end_ac01_sale = 0;
  day_end_ac02_sale = 0;
  day_end_ac03_sale = 0;
  day_end_ac04_sale = 0;
  day_end_credit_sale = 0;
  day_end_cheque_sale = 0;

  day_end_cash_payment = 0;
  day_end_amex_payment = 0;
  day_end_visa_master_payment = 0;
  day_end_frimi_payment = 0;
  day_end_genie_payment = 0;
  day_end_webxpay_payment = 0;
  day_end_ac01_payment = 0;
  day_end_ac02_payment = 0;
  day_end_ac03_payment = 0;
  day_end_ac04_payment = 0;
  day_end_credit_payment = 0;
  day_end_cheque_payment = 0;

  day_end_cash_delivery = 0;
  day_end_amex_delivery = 0;
  day_end_visa_master_delivery = 0;
  day_end_frimi_delivery = 0;
  day_end_genie_delivery = 0;
  day_end_webxpay_delivery = 0;
  day_end_ac01_delivery = 0;
  day_end_ac02_delivery = 0;
  day_end_ac03_delivery = 0;
  day_end_ac04_delivery = 0;
  day_end_credit_delivery = 0;
  day_end_cheque_delivery = 0;

  day_end_cash_return = 0;
  day_end_amex_return = 0;
  day_end_visa_master_return = 0;
  day_end_frimi_return = 0;
  day_end_genie_return = 0;
  day_end_webxpay_return = 0;
  day_end_ac01_return = 0;
  day_end_ac02_return = 0;
  day_end_ac03_return = 0;
  day_end_ac04_return = 0;
  day_end_credit_return = 0;
  day_end_cheque_return = 0;

  day_end_cash_total = 0;
  day_end_amex_total = 0;
  day_end_visa_master_total = 0;
  day_end_frimi_total = 0;
  day_end_genie_total = 0;
  day_end_webxpay_total = 0;
  day_end_ac01_total = 0;
  day_end_ac02_total = 0;
  day_end_ac03_total = 0;
  day_end_ac04_total = 0;
  day_end_credit_total = 0;
  day_end_cheque_total = 0;

  day_end_cash_actual = 0;
  day_end_amex_actual = 0;
  day_end_visa_master_actual = 0;
  day_end_frimi_actual = 0;
  day_end_genie_actual = 0;
  day_end_webxpay_actual = 0;
  day_end_ac01_actual = 0;
  day_end_ac02_actual = 0;
  day_end_ac03_actual = 0;
  day_end_ac04_actual = 0;
  day_end_credit_actual = 0;
  day_end_cheque_actual = 0;

  day_end_cash_variance = 0;
  day_end_amex_variance = 0;
  day_end_visa_master_variance = 0;
  day_end_frimi_variance = 0;
  day_end_genie_variance = 0;
  day_end_webxpay_variance = 0;
  day_end_ac01_variance = 0;
  day_end_ac02_variance = 0;
  day_end_ac03_variance = 0;
  day_end_ac04_variance = 0;
  day_end_credit_variance = 0;
  day_end_cheque_variance = 0;

  total_sales = 0;
  total_payment = 0;
  total_delivery = 0;
  total_return = 0;
  total_total = 0;
  total_actual = 0;
  total_variance = 0;

  day_end_5000 = 0;
  day_end_1000 = 0;
  day_end_500 = 0;
  day_end_100 = 0;
  day_end_50 = 0;
  day_end_20 = 0;
  day_end_10 = 0;
  day_end_coins = 0;
  day_end_total = 0;

  day_end_5000_nos = 0;
  day_end_1000_nos = 0;
  day_end_500_nos = 0;
  day_end_100_nos = 0;
  day_end_50_nos = 0;
  day_end_20_nos = 0;
  day_end_10_nos = 0;
  day_end_coins_nos = 0;
  day_end_coins_nos_total = 0;

  day_end_5000_amount = 0;
  day_end_1000_amount = 0;
  day_end_500_amount = 0;
  day_end_100_amount = 0;
  day_end_50_amount = 0;
  day_end_20_amount = 0;
  day_end_10_amount = 0;
  day_end_coins_amount = 0;
  day_end_coins_amount_total = 0;

  day_end_5000_variance = 0;
  day_end_1000_variance = 0;
  day_end_500_variance = 0;
  day_end_100_variance = 0;
  day_end_50_variance = 0;
  day_end_20_variance = 0;
  day_end_10_variance = 0;
  day_end_coins_variance = 0;
  day_end_coins_variance_total = 0;

  db_day_end_5000_nos = 0;
  db_day_end_1000_nos = 0;
  db_day_end_500_nos = 0;
  db_day_end_100_nos = 0;
  db_day_end_50_nos = 0;
  db_day_end_20_nos = 0;
  db_day_end_10_nos = 0;
  db_day_end_coins_nos = 0;
  db_day_end_coins_nos_total = 0;

  db_day_end_5000_amount = 0;
  db_day_end_1000_amount = 0;
  db_day_end_500_amount = 0;
  db_day_end_100_amount = 0;
  db_day_end_50_amount = 0;
  db_day_end_20_amount = 0;
  db_day_end_10_amount = 0;
  db_day_end_coins_amount = 0;
  db_day_end_coins_amount_total = 0;

  this_barnch = localStorage.branch;
  this_username = localStorage.username;
  today = new Date();
  day_end_total_cost = 0;
  day_end_total_cash = 0;

  day_end_accounts = [];
  day_end_accounts_total = { account: '', value: 0 };

  day_end_note_1 = "";
  day_end_note_2 = "";

  load() {
    this.day_end_accounts = [];
    this.loading = true;

    const printViewDayEnd = JSON.parse(localStorage.printViewDayEnd);

    this.fromDate = printViewDayEnd.fromDate;
    this.toDate = printViewDayEnd.toDate;

    
    let from = formatDate(this.fromDate, 'yyyy-MM-dd', this.locale);
    let to = formatDate(this.toDate, 'yyyy-MM-dd', this.locale);

    this.day_end_accounts = printViewDayEnd.day_end_accounts;

    this.day_end_note_1 = printViewDayEnd.day_end_note_1;
    this.day_end_note_2 = printViewDayEnd.day_end_note_2;

    this.db_day_end_5000_nos = printViewDayEnd.db_day_end_5000_nos;
    this.db_day_end_1000_nos = printViewDayEnd.db_day_end_1000_nos;
    this.db_day_end_500_nos = printViewDayEnd.db_day_end_500_nos;
    this.db_day_end_100_nos = printViewDayEnd.db_day_end_100_nos;
    this.db_day_end_50_nos = printViewDayEnd.db_day_end_50_nos;
    this.db_day_end_20_nos = printViewDayEnd.db_day_end_20_nos;
    this.db_day_end_10_nos = printViewDayEnd.db_day_end_10_nos;
    this.db_day_end_coins_nos = printViewDayEnd.db_day_end_coins_nos;

    this.day_end_5000_nos = printViewDayEnd.day_end_5000_nos;
    this.day_end_1000_nos = printViewDayEnd.day_end_1000_nos;
    this.day_end_500_nos = printViewDayEnd.day_end_500_nos;
    this.day_end_100_nos = printViewDayEnd.day_end_100_nos;
    this.day_end_50_nos = printViewDayEnd.day_end_50_nos;
    this.day_end_20_nos = printViewDayEnd.day_end_20_nos;
    this.day_end_10_nos = printViewDayEnd.day_end_10_nos;
    this.day_end_coins_nos = printViewDayEnd.day_end_coins_nos;

    this.day_end_cash_actual = printViewDayEnd.day_end_cash_actual;
    this.day_end_amex_actual = printViewDayEnd.day_end_amex_actual;
    this.day_end_visa_master_actual = printViewDayEnd.day_end_visa_master_actual;
    this.day_end_frimi_actual = printViewDayEnd.day_end_frimi_actual;
    this.day_end_genie_actual = printViewDayEnd.day_end_genie_actual;
    this.day_end_webxpay_actual = printViewDayEnd.day_end_webxpay_actual;
    this.day_end_ac01_actual = printViewDayEnd.day_end_ac01_actual;
    this.day_end_ac02_actual = printViewDayEnd.day_end_ac02_actual;
    this.day_end_ac03_actual = printViewDayEnd.day_end_ac03_actual;
    this.day_end_ac04_actual = printViewDayEnd.day_end_ac04_actual;
    this.day_end_credit_actual = printViewDayEnd.day_end_credit_actual;
    this.day_end_cheque_actual = printViewDayEnd.day_end_cheque_actual;

    this.server.day_end_opening_balance(from, to).subscribe(res => { this.day_end_opening_balance = res[0].amount; });
    this.server.day_end_exchanges(from, to).subscribe(res => { this.day_end_exchanges = res[0].amount; });
    this.server.day_end_cancellations(from, to).subscribe(res => { this.day_end_cancellations = res[0].amount; });
    this.server.day_end_expense(from, to).subscribe(res => { this.day_end_expense = res[0].amount; });
    this.server.day_end_discounts(from, to).subscribe(res => { this.day_end_discounts = res[0].amount; });
    this.server.day_end_discount_crn(from, to).subscribe(res => { this.day_end_discount_crn = res[0].amount; });
    this.server.day_end_discount_drn(from, to).subscribe(res => { this.day_end_discount_drn = res[0].amount; });
    this.server.day_end_return_crn(from, to).subscribe(res => { this.day_end_return_crn = res[0].amount; });
    this.server.day_end_return_drn(from, to).subscribe(res => { this.day_end_return_drn = res[0].amount; });
    this.server.day_end_direct_sales(from, to).subscribe(res => { this.day_end_direct_sales = res[0].amount; });
    this.server.day_end_delivery_sales(from, to).subscribe(res => { this.day_end_delivery_sales = res[0].amount; });
    this.server.day_end_delivery_charges(from, to).subscribe(res => { this.day_end_delivery_charges = res[0].amount; });
    this.server.day_end_repairs(from, to).subscribe(res => { this.day_end_repairs = res[0].amount; });

    this.server.day_end_cash_sale(from, to).subscribe(res => { this.day_end_cash_sale = res[0].amount; });
    this.server.day_end_amex_sale(from, to).subscribe(res => { this.day_end_amex_sale = res[0].amount; });
    this.server.day_end_visa_master_sale(from, to).subscribe(res => { this.day_end_visa_master_sale = res[0].amount; });
    this.server.day_end_frimi_sale(from, to).subscribe(res => { this.day_end_frimi_sale = res[0].amount; });
    this.server.day_end_genie_sale(from, to).subscribe(res => { this.day_end_genie_sale = res[0].amount; });
    this.server.day_end_webxpay_sale(from, to).subscribe(res => { this.day_end_webxpay_sale = res[0].amount; });
    this.server.day_end_ac01_sale(from, to).subscribe(res => { this.day_end_ac01_sale = res[0].amount; });
    this.server.day_end_ac02_sale(from, to).subscribe(res => { this.day_end_ac02_sale = res[0].amount; });
    this.server.day_end_ac03_sale(from, to).subscribe(res => { this.day_end_ac03_sale = res[0].amount; });
    this.server.day_end_ac04_sale(from, to).subscribe(res => { this.day_end_ac04_sale = res[0].amount; });
    this.server.day_end_credit_sale(from, to).subscribe(res => { this.day_end_credit_sale = res[0].amount; });
    this.server.day_end_cheque_sale(from, to).subscribe(res => { this.day_end_cheque_sale = res[0].amount; });

    this.server.day_end_cash_payment(from, to).subscribe(res => { this.day_end_cash_payment = res[0].amount; });
    this.server.day_end_amex_payment(from, to).subscribe(res => { this.day_end_amex_payment = res[0].amount; });
    this.server.day_end_visa_master_payment(from, to).subscribe(res => { this.day_end_visa_master_payment = res[0].amount; });
    this.server.day_end_frimi_payment(from, to).subscribe(res => { this.day_end_frimi_payment = res[0].amount; });
    this.server.day_end_genie_payment(from, to).subscribe(res => { this.day_end_genie_payment = res[0].amount; });
    this.server.day_end_webxpay_payment(from, to).subscribe(res => { this.day_end_webxpay_payment = res[0].amount; });
    this.server.day_end_ac01_payment(from, to).subscribe(res => { this.day_end_ac01_payment = res[0].amount; });
    this.server.day_end_ac02_payment(from, to).subscribe(res => { this.day_end_ac02_payment = res[0].amount; });
    this.server.day_end_ac03_payment(from, to).subscribe(res => { this.day_end_ac03_payment = res[0].amount; });
    this.server.day_end_ac04_payment(from, to).subscribe(res => { this.day_end_ac04_payment = res[0].amount; });
    this.server.day_end_credit_payment(from, to).subscribe(res => { this.day_end_credit_payment = res[0].amount; });
    this.server.day_end_cheque_payment(from, to).subscribe(res => { this.day_end_cheque_payment = res[0].amount; });

    this.server.day_end_cash_delivery(from, to).subscribe(res => { this.day_end_cash_delivery = res[0].amount; });
    this.server.day_end_amex_delivery(from, to).subscribe(res => { this.day_end_amex_delivery = res[0].amount; });
    this.server.day_end_visa_master_delivery(from, to).subscribe(res => { this.day_end_visa_master_delivery = res[0].amount; });
    this.server.day_end_frimi_delivery(from, to).subscribe(res => { this.day_end_frimi_delivery = res[0].amount; });
    this.server.day_end_genie_delivery(from, to).subscribe(res => { this.day_end_genie_delivery = res[0].amount; });
    this.server.day_end_webxpay_delivery(from, to).subscribe(res => { this.day_end_webxpay_delivery = res[0].amount; });
    this.server.day_end_ac01_delivery(from, to).subscribe(res => { this.day_end_ac01_delivery = res[0].amount; });
    this.server.day_end_ac02_delivery(from, to).subscribe(res => { this.day_end_ac02_delivery = res[0].amount; });
    this.server.day_end_ac03_delivery(from, to).subscribe(res => { this.day_end_ac03_delivery = res[0].amount; });
    this.server.day_end_ac04_delivery(from, to).subscribe(res => { this.day_end_ac04_delivery = res[0].amount; });
    this.server.day_end_credit_delivery(from, to).subscribe(res => { this.day_end_credit_delivery = res[0].amount; });
    this.server.day_end_cheque_delivery(from, to).subscribe(res => { this.day_end_cheque_delivery = res[0].amount; });

    this.server.day_end_cash_return(from, to).subscribe(res => { this.day_end_cash_return = res[0].amount; });
    this.server.day_end_amex_return(from, to).subscribe(res => { this.day_end_amex_return = res[0].amount; });
    this.server.day_end_visa_master_return(from, to).subscribe(res => { this.day_end_visa_master_return = res[0].amount; });
    this.server.day_end_frimi_return(from, to).subscribe(res => { this.day_end_frimi_return = res[0].amount; });
    this.server.day_end_genie_return(from, to).subscribe(res => { this.day_end_genie_return = res[0].amount; });
    this.server.day_end_webxpay_return(from, to).subscribe(res => { this.day_end_webxpay_return = res[0].amount; });
    this.server.day_end_ac01_return(from, to).subscribe(res => { this.day_end_ac01_return = res[0].amount; });
    this.server.day_end_ac02_return(from, to).subscribe(res => { this.day_end_ac02_return = res[0].amount; });
    this.server.day_end_ac03_return(from, to).subscribe(res => { this.day_end_ac03_return = res[0].amount; });
    this.server.day_end_ac04_return(from, to).subscribe(res => { this.day_end_ac04_return = res[0].amount; });
    this.server.day_end_credit_return(from, to).subscribe(res => { this.day_end_credit_return = res[0].amount; });
    this.server.day_end_cheque_return(from, to).subscribe(res => { this.day_end_cheque_return = res[0].amount; });

    this.server.day_end_5000(from, to).subscribe(res => { this.day_end_5000 = res[0].amount; });
    this.server.day_end_1000(from, to).subscribe(res => { this.day_end_1000 = res[0].amount; });
    this.server.day_end_500(from, to).subscribe(res => { this.day_end_500 = res[0].amount; });
    this.server.day_end_100(from, to).subscribe(res => { this.day_end_100 = res[0].amount; });
    this.server.day_end_50(from, to).subscribe(res => { this.day_end_50 = res[0].amount; });
    this.server.day_end_20(from, to).subscribe(res => { this.day_end_20 = res[0].amount; });
    this.server.day_end_10(from, to).subscribe(res => { this.day_end_10 = res[0].amount; });

    this.server.day_end_total_cost(from,to).subscribe(res => { this.day_end_total_cost = res[0].amount; console.log(res)});

    this.server.day_end_coins(from, to).subscribe(res => {
      this.day_end_coins = res[0].amount; this.loading = false;
      setTimeout(() => {
        this.update();
        window.print();
      }, 1000);
    });

  }

  update(): void {

    this.day_end_cash_total = this.day_end_cash_sale + this.day_end_cash_payment + this.day_end_cash_delivery - this.day_end_cash_return;
    this.day_end_amex_total = this.day_end_amex_sale + this.day_end_amex_payment + this.day_end_amex_delivery - this.day_end_amex_return;
    this.day_end_visa_master_total = this.day_end_visa_master_sale + this.day_end_visa_master_payment + this.day_end_visa_master_delivery - this.day_end_visa_master_return;
    this.day_end_frimi_total = this.day_end_frimi_sale + this.day_end_frimi_payment + this.day_end_frimi_delivery - this.day_end_frimi_return;
    this.day_end_genie_total = this.day_end_genie_sale + this.day_end_genie_payment + this.day_end_genie_delivery - this.day_end_genie_return;
    this.day_end_webxpay_total = this.day_end_webxpay_sale + this.day_end_webxpay_payment + this.day_end_webxpay_delivery - this.day_end_webxpay_return;
    this.day_end_ac01_total = this.day_end_ac01_sale + this.day_end_ac01_payment + this.day_end_ac01_delivery - this.day_end_ac01_return;
    this.day_end_ac02_total = this.day_end_ac02_sale + this.day_end_ac02_payment + this.day_end_ac02_delivery - this.day_end_ac02_return;
    this.day_end_ac03_total = this.day_end_ac03_sale + this.day_end_ac03_payment + this.day_end_ac03_delivery - this.day_end_ac03_return;
    this.day_end_ac04_total = this.day_end_ac04_sale + this.day_end_ac04_payment + this.day_end_ac04_delivery - this.day_end_ac04_return;
    this.day_end_credit_total = this.day_end_credit_sale + this.day_end_credit_payment + this.day_end_credit_delivery - this.day_end_credit_return;
    this.day_end_cheque_total = this.day_end_cheque_sale + this.day_end_cheque_payment + this.day_end_cheque_delivery - this.day_end_cheque_return;

    this.day_end_cash_variance = this.day_end_cash_total - this.day_end_cash_actual;
    this.day_end_amex_variance = this.day_end_amex_total - this.day_end_amex_actual;
    this.day_end_visa_master_variance = this.day_end_visa_master_total - this.day_end_visa_master_actual;
    this.day_end_frimi_variance = this.day_end_frimi_total - this.day_end_frimi_actual;
    this.day_end_genie_variance = this.day_end_genie_total - this.day_end_genie_actual;
    this.day_end_webxpay_variance = this.day_end_webxpay_total - this.day_end_webxpay_actual;
    this.day_end_ac01_variance = this.day_end_ac01_total - this.day_end_ac01_actual;
    this.day_end_ac02_variance = this.day_end_ac02_total - this.day_end_ac02_actual;
    this.day_end_ac03_variance = this.day_end_ac03_total - this.day_end_ac03_actual;
    this.day_end_ac04_variance = this.day_end_ac04_total - this.day_end_ac04_actual;
    this.day_end_credit_variance = this.day_end_credit_total - this.day_end_credit_actual;
    this.day_end_cheque_variance = this.day_end_cheque_total - this.day_end_cheque_actual;

    this.total_sales =
      this.day_end_cash_sale +
      this.day_end_amex_sale +
      this.day_end_ac03_sale +
      this.day_end_ac04_sale +
      this.day_end_credit_sale +
      this.day_end_cheque_sale;

    this.total_payment =
      this.day_end_cash_payment +
      this.day_end_amex_payment +
      this.day_end_visa_master_payment +
      this.day_end_frimi_payment +
      this.day_end_genie_payment +
      this.day_end_webxpay_payment +
      this.day_end_ac01_payment +
      this.day_end_ac02_payment +
      this.day_end_ac03_payment +
      this.day_end_ac04_payment +
      this.day_end_credit_payment +
      this.day_end_cheque_payment;

    this.total_delivery =
      this.day_end_cash_delivery +
      this.day_end_amex_delivery +
      this.day_end_visa_master_delivery +
      this.day_end_frimi_delivery +
      this.day_end_genie_delivery +
      this.day_end_webxpay_delivery +
      this.day_end_ac01_delivery +
      this.day_end_ac02_delivery +
      this.day_end_ac03_delivery +
      this.day_end_ac04_delivery +
      this.day_end_credit_delivery +
      this.day_end_cheque_delivery;
    1
    this.day_end_credit_return +
      this.day_end_cheque_return;

    this.total_total =
      this.day_end_cash_total +
      this.day_end_amex_total +
      this.day_end_visa_master_total +
      this.day_end_frimi_total +
      this.day_end_genie_total +
      this.day_end_webxpay_total +
      this.day_end_ac01_total +
      this.day_end_ac02_total +
      this.day_end_ac03_total +
      this.day_end_ac04_total +
      this.day_end_credit_total +
      this.day_end_cheque_total;

    this.total_actual =
      this.day_end_cash_actual +
      this.day_end_amex_actual +
      this.day_end_visa_master_actual +
      this.day_end_frimi_actual +
      this.day_end_genie_actual +
      this.day_end_webxpay_actual +
      this.day_end_ac01_actual +
      this.day_end_ac02_actual +
      this.day_end_ac03_actual +
      this.day_end_ac04_actual +
      this.day_end_credit_actual +
      this.day_end_cheque_actual;

    this.total_variance =
      this.day_end_cash_variance +
      this.day_end_amex_variance +
      this.day_end_visa_master_variance +
      this.day_end_frimi_variance +
      this.day_end_genie_variance +
      this.day_end_webxpay_variance +
      this.day_end_ac01_variance +
      this.day_end_ac02_variance +
      this.day_end_ac03_variance +
      this.day_end_ac04_variance +
      this.day_end_credit_variance +
      this.day_end_cheque_variance;

    this.day_end_5000_amount = this.day_end_5000_nos * 5000
    this.day_end_1000_amount = this.day_end_1000_nos * 1000
    this.day_end_500_amount = this.day_end_500_nos * 500
    this.day_end_100_amount = this.day_end_100_nos * 100
    this.day_end_50_amount = this.day_end_50_nos * 50
    this.day_end_20_amount = this.day_end_20_nos * 20
    this.day_end_10_amount = this.day_end_10_nos * 10
    this.day_end_coins_amount = this.day_end_coins_nos
    this.day_end_coins_amount_total =
      + this.day_end_5000_amount
      + this.day_end_1000_amount
      + this.day_end_500_amount
      + this.day_end_100_amount
      + this.day_end_50_amount
      + this.day_end_20_amount
      + this.day_end_10_amount
      + this.day_end_coins_amount;

    this.day_end_total = + this.day_end_5000 + this.day_end_1000 + this.day_end_500 + this.day_end_100
      + this.day_end_50 + this.day_end_20 + this.day_end_10 + this.day_end_coins;

    this.db_day_end_coins_nos_total = +this.db_day_end_5000_nos + this.db_day_end_1000_nos + this.db_day_end_500_nos
      + this.db_day_end_100_nos + this.db_day_end_50_nos + this.db_day_end_20_nos + this.db_day_end_10_nos + this.db_day_end_coins_nos

    this.day_end_coins_nos_total = +this.day_end_5000_nos + this.day_end_1000_nos + this.day_end_500_nos
      + this.day_end_100_nos + this.day_end_50_nos + this.day_end_20_nos + this.day_end_10_nos + this.day_end_coins_nos

    this.day_end_5000_variance = this.day_end_5000_amount - this.day_end_5000;
    this.day_end_1000_variance = this.day_end_1000_amount - this.day_end_1000;
    this.day_end_500_variance = this.day_end_500_amount - this.day_end_500;
    this.day_end_100_variance = this.day_end_100_amount - this.day_end_100;
    this.day_end_50_variance = this.day_end_50_amount - this.day_end_50;
    this.day_end_20_variance = this.day_end_20_amount - this.day_end_20;
    this.day_end_10_variance = this.day_end_10_amount - this.day_end_10;
    this.day_end_coins_variance = this.day_end_coins_amount - this.day_end_coins;
    this.day_end_coins_variance_total =
      +this.day_end_5000_variance
      + this.day_end_1000_variance
      + this.day_end_500_variance
      + this.day_end_100_variance
      + this.day_end_50_variance
      + this.day_end_20_variance
      + this.day_end_10_variance
      + this.day_end_coins_variance;

    this.db_day_end_5000_amount = this.db_day_end_5000_nos * 5000
    this.db_day_end_1000_amount = this.db_day_end_1000_nos * 1000
    this.db_day_end_500_amount = this.db_day_end_500_nos * 500
    this.db_day_end_100_amount = this.db_day_end_100_nos * 100
    this.db_day_end_50_amount = this.db_day_end_50_nos * 50
    this.db_day_end_20_amount = this.db_day_end_20_nos * 20
    this.db_day_end_10_amount = this.db_day_end_10_nos * 10
    this.db_day_end_coins_amount = this.db_day_end_coins_nos
    this.db_day_end_coins_amount_total =
      + this.db_day_end_5000_amount
      + this.db_day_end_1000_amount
      + this.db_day_end_500_amount
      + this.db_day_end_100_amount
      + this.db_day_end_50_amount
      + this.db_day_end_20_amount
      + this.db_day_end_10_amount
      + this.db_day_end_coins_amount;


      this.day_end_total_cash = this.day_end_coins_amount_total - this.day_end_opening_balance;

    if (this.day_end_accounts.length > 0) {
      this.day_end_accounts_total = this.day_end_accounts.reduce((a, b) => {
        return { value: Number(a.value) + Number(b.value) }
      });
    }
  }

  ngOnInit() {
    this.load();
  }

}
