import { Component, OnInit } from '@angular/core';
import { GetServerDataServiceService } from 'src/app/get-server-data-service.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-loyalty-customer-import',
  templateUrl: './loyalty-customer-import.component.html',
  styleUrls: ['./loyalty-customer-import.component.css']
})
export class LoyaltyCustomerImportComponent implements OnInit {

  constructor(
    private server: GetServerDataServiceService,
    private toolTip: MatSnackBar,
  ) {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.server.get_non_loyalty_customer_list(value, this.page, this.limit)
          .subscribe(res => {
            this.contacts = res.table;
          })
      });
  }

  ngOnInit(): void {
    this.loadContacts();
  }

  autoUpdate = new Subject<string>();

  search = "";
  loading = false;
  length = 100;
  pageSize = 10;
  page = 1;
  limit = 10;
  pageSizeOptions: number[] = [10, 50, 100, 200, 500, 1000, 1500];
  contacts = [];
  contactsSelected = [];
  CustomerTypeList = this.server.customer_type_list();

  getPaginatorData(event: PageEvent) {

    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;

    this.loadContacts();
  }

  update(ct: string, c: string) {
    console.log(ct, c);
    this.server.customer_type_update(ct,c).subscribe(res => {
      this.toolTip.open('user updated!', 'Ok', { duration: 3000 });
      this.loadContacts();
    });
  }



  loadContacts(): void {
    this.loading = true;
    this.server.get_non_loyalty_customer_list(this.search, this.page, this.limit)
      .subscribe(res => {
        console.log(res);
        this.contacts = res.table;
        this.length = res.count[0].count;
        this.loading = false;
      })
  }

}
