import { Component, OnInit, Input, Output, EventEmitter, HostBinding, HostListener, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, timeout } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'invoice-no-auto',
  templateUrl: './invoice-no-auto.component.html',
  styleUrls: ['./invoice-no-auto.component.css']
})
export class InvoiceNoAutoComponent implements OnInit {
  
  constructor() {
    this.autoUpdate.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        this.valueChange.emit(this.AutoValue);
      });
  }

  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplit: MatAutocompleteTrigger;

  @Input() options: any;
  @Input() placeholder: any;
  @Input() key: string;
  @Input() AutoValue: string;
  @Input() disabled: boolean;

  autoUpdate = new Subject<string>();

  @Output() valueChange = new EventEmitter();
  @Output() finalValue = new EventEmitter();
  @Output() enterPressed = new EventEmitter();

  openPanel(): void {
    this.inputAutoComplit.openPanel();
  }

  valueChanged() {
    this.finalValue.emit(this.AutoValue);
  }

  displayFn(id: any) {
    if (!id) { return '' }
    return id['Invoice No'];
  }

  enter(event: any): void {
    this.enterPressed.emit(this.AutoValue);
  }


  ngOnInit() {
    // console.log(this.options);
  }


}
