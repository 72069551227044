import { Component, OnInit, Inject, LOCALE_ID } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { GetServerDataServiceService } from "src/app/get-server-data-service.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SelectCustomerComponent } from "src/app/sales-management/select-customer/select-customer.component";
import { WindowEmitorsService } from "src/app/window-emitors.service";
import { formatDate } from "@angular/common";
import { SelectLocationComponent } from 'src/app/shared/select-location/select-location.component';
import { FileUploadDialogComponent } from 'src/app/file-upload/file-upload-dialog';

@Component({
  selector: "app-new-customer",
  templateUrl: "./new-customer.component.html",
  styleUrls: ["./new-customer.component.css"]
})
export class NewCustomerComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private server: GetServerDataServiceService,
    private windowEmitors: WindowEmitorsService,
    private toolTip: MatSnackBar,
    private dialog: MatDialog,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  newCustomer = this.fb.group({
    Code: [{ value: null, disabled: true }],
    Name: [null],
    NIC: ['-'],
    Address: [null],
    City: [null],
    Location: ["0,0"],
    OwnersName: [null],
    Country: ["Sri Lanka"],
    primary_Contact_No: [null],
    secondary_Contact_No: [null],
    Email: [null, Validators.compose([Validators.email])],
    dateofBirth: [null],
    occupation: ['-'],
    LoyaltyCardNo: [null]
  });

  citys = [];
  countrys = this.server.getCountries();
  inserting = false;

  loadCity() {
    this.server.city_auto_list('', this.newCustomer.get('Country').value).subscribe(res => {
      this.citys = res;
    })
  }

  cityValueChange(value: any) {
    if (typeof value === 'string') {
      this.newCustomer.get('City').setValue('');
    }
  }

  cityFinalValue(value: any) {
    this.newCustomer.get('City').setValue(value);
  }

  add(): void {
    if (!this.newCustomer.get("NIC").value) {
      this.newCustomer.get("NIC").setValue("-");
    }
    if (!this.newCustomer.get("Address").value) {
      this.newCustomer.get("Address").setValue("-");
    }
    if (!this.newCustomer.get("secondary_Contact_No").value) {
      this.newCustomer.get("secondary_Contact_No").setValue(0);
    }
    if (!this.newCustomer.get("Email").value) {
      this.newCustomer.get("Email").setValue("-");
    }
    if (!this.newCustomer.get("dateofBirth").value) {
      this.newCustomer.get("dateofBirth").setValue(new Date());
    }
    if (!this.newCustomer.get("occupation").value) {
      this.newCustomer.get("occupation").setValue("-");
    }
    if (!this.newCustomer.get("LoyaltyCardNo").value) {
      this.newCustomer.get("LoyaltyCardNo").setValue("0");
    }

    var fixDate = formatDate(
      this.newCustomer.get("dateofBirth").value,
      "yyyy-MM-dd",
      this.locale
    );
    this.newCustomer.get("dateofBirth").setValue(fixDate);
    this.server
      .check_customer_phone_2(
        this.newCustomer.getRawValue().primary_Contact_No,
        this.newCustomer.getRawValue().secondary_Contact_No
      )
      .subscribe(res => {
        if (res.length === 0) {
          this.inserting = true;
          this.server
            .add_customer(this.newCustomer.getRawValue())
            .subscribe(insertRes => {
              this.inserting = false;
              console.log(insertRes);
              this.newCustomer.reset();
              this.genCode();
            });
        } else if (res.length === 1) {
          this.toolTip.open('Contact Number already Exists!', 'Ok', { duration: 3000 });
          this.genCode();
        } else {
          const selectedRowsByIDdialog = this.dialog.open(
            SelectCustomerComponent,
            {
              data: {
                res
              }
            }
          );
        }
      });
  }

  genCode(): void {
    this.server.get_customer_code().subscribe(res => {
      this.newCustomer.get("Code").setValue(res[0][0].Code);
    });
  }

  setValidations() {
    this.server.settings_customer_mandatory().subscribe(res => {
      let reqs = [];
      if (res.length !== 0) {
        reqs = JSON.parse(res[0].value);
        reqs.map(name => {
          this.newCustomer.get(name).setValidators(Validators.required);
          this.newCustomer.get(name).updateValueAndValidity();
        });
      }
    });
  }

  selectLocation() {

    const location = this.dialog.open(
      SelectLocationComponent,
      {
        data: {
          location: this.newCustomer.get('Location').value
        },
        width: "90vw"
      },
    );

    location.afterClosed().subscribe(loc => {
      console.log(loc);
      this.newCustomer.get('Location').setValue(loc);
    });

  }

  uploadImage() {
    const mainSlide = this.dialog.open(FileUploadDialogComponent,
      {
        data: {
          name: this.newCustomer.get("Code").value.replace('#', ''),
          width: 640,
          aspectRatio: 4 / 3,
          path: '/customer'
        },
        width: '90vw'
      }
    );

    mainSlide.afterClosed().subscribe(res => {
      this.toolTip.open(this.newCustomer.get("Code").value + " Image Created!", 'Ok', { duration: 3000 });
    });
  }

  ngOnInit() {
    this.genCode();
    this.setValidations();
    this.loadCity();
  }

}
